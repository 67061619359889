import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { useCreateSlotRoomRack, useUpdateSlotRoomRack } from '@api/api';
import { useToggle } from '@utils/hooks/useToggle';

import { ServerStateKey } from '@typings/enums';
import { AddSlotFormRequest, PropertyType, UpdateSlotFormRequest } from '@typings/types';

interface Props {
  property: Pick<PropertyType, 'id' | 'timeZone'>;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
  dateRange: {
    start: Date;
    end: Date;
  };
}

export default function useCreateOrUpdateSlotRoomRack({ property, dateRange, onSuccess, onError }: Props) {
  const [isSubmitting, setSubmitting] = useToggle(false);
  const queryClient = useQueryClient();

  const propertyId = property.id;

  const createSlotRequest = useCreateSlotRoomRack(propertyId, {
    onSettled: () => {
      setSubmitting(false);
    },
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [ServerStateKey.ROOM_RACK, propertyId, dateRange.start.toISOString(), dateRange.end.toISOString()],
      });
    },
    onError: (error) => {
      onError?.(error as Error);
    },
  });

  const updateSlotRequest = useUpdateSlotRoomRack(propertyId, {
    onSettled: () => {
      setSubmitting(false);
    },
    onSuccess: () => {
      onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [ServerStateKey.ROOM_RACK, propertyId, dateRange.start.toISOString(), dateRange.end.toISOString()],
      });
    },
    onError: (error) => {
      onError?.(error as Error);
    },
  });

  const submitCreateSlotForm = useCallback(
    async (formValues: Omit<AddSlotFormRequest, 'unitId'> & { unitIds: string[] }) => {
      if (isSubmitting) return;
      setSubmitting(true);

      try {
        const { unitIds, ...requestValues } = formValues;
        await Promise.all(
          unitIds.map((unit) => createSlotRequest.mutateAsync({ data: { ...requestValues, unitId: unit } })),
        );
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [isSubmitting],
  );

  const submitUpdateSlotForm = useCallback(
    async (slotId: string, formValues: UpdateSlotFormRequest) => {
      if (isSubmitting) return;
      setSubmitting(true);

      try {
        updateSlotRequest.mutate({ id: slotId, data: formValues });
      } catch (error) {
        console.error(error);
      } finally {
        setSubmitting(false);
      }
    },
    [isSubmitting],
  );

  return {
    isSubmitting,
    submitCreateSlotForm,
    submitUpdateSlotForm,
  };
}
