import React, { MouseEventHandler, useCallback } from 'react';
import Checkbox from '@molecules/Checkbox';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  countSelected: number;
  onSelect: MouseEventHandler<HTMLDivElement>;
  checked?: boolean;
};

function SelectAll({ id, countSelected, onSelect, checked = false }: Props) {
  const { t } = useTranslation();
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onSelect(e);
    },
    [onSelect],
  );
  return (
    <div className={'text-th-gray-300 flex flex-row items-center'} onClick={onClick}>
      {t('selectAll')} {countSelected}:
      <Checkbox id={id} name={id} onClick={onClick} checked={checked} className={'pb-2 text-th-brown'} />
    </div>
  );
}

export default SelectAll;
