import { useSearchParams } from 'react-router-dom';
import { RoomRackQueryParams } from '@typings/enums';
import { startOfWeek } from 'date-fns';
import { SlotDialogueRoomRackParamValues, SlotValue } from './types';
import { useEffect, useMemo, useState } from 'react';

export const DEFAULT_INITIAL_DATE = startOfWeek(new Date(), { weekStartsOn: 1 });

export function useRoomRackQueryParams() {
  const [queryErrors, setQueryErrors] = useState<{ [key in RoomRackQueryParams]?: string } | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const getParam = (key: RoomRackQueryParams) => searchParams.get(key);

  const updateParam = (key: RoomRackQueryParams, value: string) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set(key, value);
      return newParams;
    });
  };

  const removeParams = (params: RoomRackQueryParams[]) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      params.forEach((param) => newParams.delete(param));
      return newParams;
    });
  };

  const updateAndRemoveParams = (updates: {
    set?: Partial<Record<RoomRackQueryParams, string>>;
    remove?: RoomRackQueryParams[];
  }) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);

      if (updates.set) {
        Object.entries(updates.set).forEach(([key, value]) => {
          newParams.set(key as RoomRackQueryParams, value);
        });
      }

      if (updates.remove) {
        updates.remove.forEach((param) => {
          newParams.delete(param);
        });
      }

      return newParams;
    });
  };

  const slotStatus = getParam(RoomRackQueryParams.SLOT_PANEL) as SlotDialogueRoomRackParamValues | null;
  const isSlotOpen = slotStatus === SlotDialogueRoomRackParamValues.OPEN;

  const startDateParam = getParam(RoomRackQueryParams.START);
  const startDate = useMemo(() => {
    let result = DEFAULT_INITIAL_DATE;
    if (startDateParam) {
      result = startOfWeek(new Date(startDateParam), { weekStartsOn: 1 });
      if (isNaN(result.getTime())) {
        return DEFAULT_INITIAL_DATE;
      }
    }
    return result;
  }, [startDateParam]);

  useEffect(() => {
    if (startDateParam) {
      try {
        const parsedDate = startOfWeek(new Date(startDateParam), { weekStartsOn: 1 });
        if (isNaN(parsedDate.getTime())) {
          throw new Error('Invalid date');
        }
      } catch (error) {
        setQueryErrors((prev) => ({
          ...prev,
          [RoomRackQueryParams.START]: 'Invalid date',
        }));
      }
    }
  }, [startDateParam]);

  const roomIdParam = getParam(RoomRackQueryParams.ROOM_ID);
  const slotIdParam = getParam(RoomRackQueryParams.SLOT_ID);

  const slotStatusParam = getParam(RoomRackQueryParams.SLOT_TYPE);
  const slotStatuses = slotStatusParam ? (slotStatusParam.split(',') as SlotValue[]) : [];

  return {
    searchParams,
    setSearchParams,
    getParam,
    updateParam,
    removeParams,
    updateAndRemoveParams,
    isSlotOpen,
    startDate,
    roomIdParam,
    slotIdParam,
    slotStatuses,
    queryErrors,
    setQueryErrors,
  };
}
