import { QueryClient } from 'react-query';

import { PropertyTable } from './PropertyTable';

import { WeeklyViewPropertyData } from '@typings/types';

interface PropertySectionProps {
  propertyData: WeeklyViewPropertyData;
  propertyId: string;
  dates: Date[];
  memberBenefitsEnabled: boolean;
  paidEcisEnabled: boolean;
  queryClient: QueryClient;
  from: string;
  propertyIds: string[];
}

export const PropertySection = ({
  propertyData,
  propertyId,
  dates,
  memberBenefitsEnabled,
  paidEcisEnabled,
  queryClient,
  from,
  propertyIds,
}: PropertySectionProps) => {
  return (
    <div className="mb-6 pr-4 sm:pr-0 min-w-fit w-auto">
      <div className="w-full bg-th-primary text-gray-50 p-3">
        <span className="text-base font-sans font-semibold">{propertyData.name}</span>
      </div>
      <div className="flex flex-1 w-full">
        <PropertyTable
          propertyData={propertyData}
          propertyId={propertyId}
          dates={dates}
          memberBenefitsEnabled={memberBenefitsEnabled}
          paidEcisEnabled={paidEcisEnabled}
          queryClient={queryClient}
          from={from}
          propertyIds={propertyIds}
        />
      </div>
    </div>
  );
};
