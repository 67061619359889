import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { format, fromUnixTime } from 'date-fns';
import { Popover, Portal } from '@headlessui/react';
import { maintenanceReasonsSlot, reservationStatusSlot } from '@organisms/RoomRack/utils';

import { ItemRendererProps } from '../types';
import { SlotType } from '@organisms/RoomRack/types';

export const Slot = ({ item, itemContext, getItemProps }: ItemRendererProps) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const [popupRef, setPopupRef] = useState<HTMLDivElement | null>(null);

  const checkInRawDate = fromUnixTime(item.start_time / 1000);
  const checkoutRawDate = fromUnixTime(item.end_time / 1000);
  const checkInDate = format(checkInRawDate, 'dd/MM HH:mm');
  const checkoutDate = format(checkoutRawDate, 'dd/MM HH:mm');
  const customStyle = {
    style: {
      ...item.itemProps.style,
      zIndex: isHovered ? 50 : 30,
    },
  };

  const { styles, attributes } = usePopper(buttonRef, popupRef, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [4, 6] } }],
  });

  const SlotIcon = useMemo(() => {
    if (item.type === SlotType.RESERVATION && item.status) {
      return reservationStatusSlot.find((status) => status.value === item.status)?.icon;
    } else if (item.reason) {
      return maintenanceReasonsSlot.find((reason) => reason.value === item.reason)?.icon;
    }
    return null;
  }, [item]);

  return (
    <div {...getItemProps(customStyle)} key={`${item.id}-${item.type}`}>
      <Popover className={`relative w-full`}>
        <Popover.Button
          ref={setButtonRef}
          style={{ width: '100%' }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div
            className={`flex overflow-hidden overflow-ellipsis whitespace-nowrap w-full font-sans`}
            style={{ height: itemContext.dimensions.height }}
          >
            {item.isEarlyCheckIn && <div className="w-4 h-full bg-black text-white text-center">E</div>}
            <div className="ml-2 flex justify-center items-center">
              {SlotIcon && (
                <div className="mr-1">
                  <SlotIcon width={16} height={16} />
                </div>
              )}
              <span>{item.title}</span>
            </div>
          </div>
        </Popover.Button>
        {isHovered && (
          <Portal>
            <Popover.Panel
              static
              ref={setPopupRef}
              style={styles.popper}
              {...attributes.popper}
              className="z-50 bg-white rounded shadow-sm px-4 py-2 flex flex-col justify-start 
                items-start gap-1 whitespace-nowrap text-xs font-normal leading-4 font-sans"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div>{item.title}</div>
              <div className="whitespace-nowrap">
                <span className="text-th-brown-100 capitalize">{t('from')}</span> ⋅ {checkInDate}
              </div>
              <div className="whitespace-nowrap">
                <span className="text-th-brown-100 capitalize">{t('until')}</span> ⋅ {checkoutDate}
              </div>
            </Popover.Panel>
          </Portal>
        )}
      </Popover>
    </div>
  );
};
