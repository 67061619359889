import { ExternalLinkIcon } from '@heroicons/react/outline';
import { AssignedUnitDetailed, OutOfOrderSlot } from '@typings/types';
import { formatDate, DATE_ISO_STRING } from '@utils/dateUtils';

export const popoverPlainRooms = (rooms: string[] | undefined) => {
  return rooms?.map((item) => {
    return (
      <div key={item} className="w-auto">
        {item}
      </div>
    );
  });
};

export const popoverRedirectRooms = (rooms: AssignedUnitDetailed[] | undefined, propertyId: string) => {
  return rooms?.map((item) => {
    const { unitId, unitNumber } = item;
    return (
      <a
        key={`${propertyId}-${unitId}`}
        target="_blank"
        rel="noopener noreferrer"
        href={`${window.location.origin}/units/${unitId}?propertyId=${propertyId}`}
        className="w-auto text-left text-th-dark-blue flex justify-start items-center gap-x-[2px]"
      >
        <span className="underline">{unitNumber}</span>
        <ExternalLinkIcon className="h-[14px] w-[14px] inline-block" />
      </a>
    );
  });
};

export const popoverRedirectRoomRack = (rooms: OutOfOrderSlot[] | undefined, propertyId: string) => {
  return rooms?.map((item) => {
    const { from, slotId, unitId, unitNumber } = item;
    const startDate = formatDate(new Date(from), DATE_ISO_STRING);
    return (
      <a
        key={`${propertyId}-${unitId}-${slotId}`}
        target="_blank"
        rel="noopener noreferrer"
        href={`${window.location.origin}/room-rack?propertyId=${propertyId}&start=${startDate}&slotId=${slotId}`}
        className="w-auto text-left text-th-dark-blue flex justify-start items-center gap-x-[2px]"
      >
        <span className="underline">{unitNumber}</span>
        <ExternalLinkIcon className="h-[14px] w-[14px] inline-block" />
      </a>
    );
  });
};
