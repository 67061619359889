import { DateHeader, IntervalContext, IntervalRenderer } from 'react-calendar-timeline';
import { format, getDate, isToday, isWeekend } from 'date-fns';

import { getDateFnsCurrentLocale } from '@utils/getDateFnsCurrentLocale';

interface Data {}

export const ColumnHeader = () => {
  return (
    <DateHeader
      unit="day"
      height={44}
      className="flex items-center"
      intervalRenderer={({
        getIntervalProps,
        intervalContext,
      }: {
        getIntervalProps: IntervalRenderer<Data>['getIntervalProps'];
        intervalContext: IntervalContext;
      }) => {
        const date = intervalContext.interval.startTime.toDate();
        const isDayWeekend = isWeekend(date);
        const isDayToday = isToday(date);
        const getDayDate = getDate(date);
        const getDayName = format(date, 'E', { locale: getDateFnsCurrentLocale() });

        const intervalProps = getIntervalProps();
        const props = { style: intervalProps.style };
        return (
          <div
            key={date.toISOString()}
            className={`flex flex-col justify-center items-center text-sm leading-[18px] font-sans h-full border border-th-gray-100 ${
              isDayToday ? 'bg-[#18333D] text-white' : isDayWeekend && 'bg-[#F3F9F7]'
            }`}
            {...props}
          >
            <div>{getDayDate}</div>
            <div className="capitalize">{getDayName}</div>
          </div>
        );
      }}
    />
  );
};
