import React, { MouseEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Team } from '@typings/enums';
import SelectionDialog, { SelectOption } from '@molecules/SelectionDialog';
import { teamOptions } from './utils';

interface Props {
  value?: Team;
  enableGxTeam?: boolean;
  onSelect: (item: Team) => void;
  actionButton: (onClick: MouseEventHandler<HTMLElement>) => React.ReactNode;
}

function SelectTeam({ value, enableGxTeam, onSelect, actionButton }: Props) {
  const { t } = useTranslation();
  const teamOptionsMap = teamOptions(enableGxTeam);
  const options = useMemo(
    () =>
      Object.keys(teamOptionsMap).reduce(
        (acc, curr) => {
          acc[curr] = { ...teamOptionsMap[curr], label: t(teamOptionsMap[curr].label) };
          return acc;
        },
        {} as { [key: string]: SelectOption },
      ),
    [t],
  );
  const selectedItem = value && options[value];
  const teams = Object.values(options);
  return (
    <SelectionDialog
      includeSearch={false}
      title={t('team')}
      value={selectedItem}
      onSelect={(item) => onSelect(item?.value)}
      actionButton={actionButton}
      options={teams}
    />
  );
}

export default SelectTeam;
