import React, { MouseEventHandler, useCallback } from 'react';
import { IconType } from '@typings/types';
import debounce from 'lodash.debounce';
import Spinner from '@atoms/Spinner';

export enum ButtonType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  OUTLINED = 'OUTLINED',
  UNSTYLED = 'UNSTYLED',
}

export const buttonStyles = {
  [ButtonType.PRIMARY]: {
    styles: 'px-4 text-sm text-white bg-th-secondary py-3',
    textColor: 'text-white',
  },
  [ButtonType.SECONDARY]: {
    styles: 'text-md text-gray-500 font-serif',
    textColor: 'text-gray-500',
  },
  [ButtonType.OUTLINED]: {
    styles: 'px-4 text-sm text-red-500 font-bold bg-transparent border border-red-500 py-2',
    textColor: 'text-red-500',
  },
  [ButtonType.UNSTYLED]: {
    styles: 'text-md text-green-400 font-bold font-serif',
    textColor: 'text-green-400',
  },
};

interface Props {
  isLoading?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  Icon?: IconType;
  type?: ButtonType;
  withDebounce?: boolean;
}

export default function Button(props: Props) {
  const {
    isLoading = false,
    children,
    className,
    onClick,
    disabled,
    Icon,
    type = ButtonType.PRIMARY,
    withDebounce = false,
  } = props;

  const onButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    withDebounce
      ? debounce((event: React.MouseEvent<HTMLButtonElement>) => onClick?.(event), 500)
      : (event: React.MouseEvent<HTMLButtonElement>) => onClick?.(event),
    [onClick],
  );

  return (
    <button
      className={`
         rounded-md hover:opacity-75 flex flex-row justify-center items-center
         ${buttonStyles[type].styles} 
         ${disabled ? 'opacity-50 cursor-not-allowed' : ''} 
         ${className}
      `}
      onClick={onButtonClick}
      type="button"
      disabled={disabled || isLoading}
    >
      {isLoading && <Spinner className={`w-3 h-3 ${buttonStyles[type].textColor}`} />}
      {Icon && !isLoading && <Icon className={`w-4 h-4 mr-1 ${buttonStyles[type].textColor}`} />}
      {children}
    </button>
  );
}
