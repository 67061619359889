import { Popover as HeadlessPopover } from '@headlessui/react';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import useBreakpoint from '@utils/hooks/useBreakpoint';
import Sheet from 'react-modal-sheet';
import { ChevronDownIcon } from '@heroicons/react/solid';
import useScreenHeight from '@utils/hooks/useScreenHeight';
import { HeadlessPopoverPanel } from './HeadlessPopoverPanel';
import { PopoverProps } from './types';

const MOBILE_SHEET_HEADER_HEIGHT = 40;
// bottom offset for sheet height calculated based on the content
const MOBILE_SHEET_HEADER_OFFSET = 30;

export default function Popover({ showArrow = false, side = 'left', ...props }: PopoverProps): JSX.Element {
  const {
    target,
    content,
    contentFn,
    onOpen,
    targetClasses,
    sheetHeight,
    showBorderWhenOpened,
    className,
    desktopCustomPosition,
  } = props;

  const breakpoint = useBreakpoint();
  const screenHeight = useScreenHeight();

  const [isOpen, setIsOpen] = useState(false);

  const contentRef = useRef<HTMLDivElement>();
  const [mobileSheetHeight, setMobileSheetHeight] = useState(sheetHeight);

  const isSmallScreen = breakpoint === 'xs' || breakpoint === 'sm';

  const targetClassesWhenOpened = showBorderWhenOpened ? 'ring-th-secondary ring-1' : '';

  const closeMobileSheet = useCallback(() => setIsOpen(false), []);

  useLayoutEffect(() => {
    if (isOpen && !sheetHeight) {
      const contentHeight = contentRef?.current?.children[0].clientHeight;
      if (contentHeight) {
        const height = contentHeight + MOBILE_SHEET_HEADER_HEIGHT + MOBILE_SHEET_HEADER_OFFSET;
        setMobileSheetHeight(height < screenHeight ? height : undefined);
      }
    }
  }, [isOpen, content, screenHeight]);

  if (isSmallScreen) {
    return (
      <div className={className}>
        <div
          onClick={() => setIsOpen(true)}
          className={`
                ${isOpen ? targetClassesWhenOpened : 'text-opacity-90'}
                ${targetClasses}
                group rounded inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none`}
        >
          {target}
          {showArrow && (
            <ChevronDownIcon className={`h-5 w-5 text-th-secondary ${isOpen ? 'transform rotate-180' : ''}`} />
          )}
        </div>
        <Sheet
          isOpen={isOpen}
          onOpenStart={onOpen}
          onClose={closeMobileSheet}
          snapPoints={mobileSheetHeight ? [mobileSheetHeight] : undefined}
          style={{ zIndex: 10 }}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content ref={contentRef}>{content || contentFn?.(closeMobileSheet)}</Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop onTap={closeMobileSheet} />
        </Sheet>
      </div>
    );
  }

  return (
    <div className={`${className || ''} relative`}>
      <HeadlessPopover className={'h-full'}>
        {({ open, close }) => (
          <>
            <HeadlessPopover.Button
              className={`
                ${open ? targetClassesWhenOpened : 'text-opacity-90'}
                ${targetClasses}
                group rounded inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none`}
            >
              {target}
              {showArrow && (
                <ChevronDownIcon className={`h-5 w-5 text-th-secondary ${open ? 'transform rotate-180' : ''}`} />
              )}
            </HeadlessPopover.Button>

            <HeadlessPopoverPanel
              close={close}
              desktopCustomPosition={desktopCustomPosition}
              side={side}
              open={open}
              onOpen={onOpen}
              contentFn={contentFn}
              content={content}
            />
          </>
        )}
      </HeadlessPopover>
    </div>
  );
}
