import { InformationCircleIcon } from '@heroicons/react/outline';

export const Info = ({ message, className = '' }: { message: string; className: string }) => {
  return (
    <div className={`flex justify-start items-start rounded-lg px-4 py-2 font-sans gap-x-2 ${className}`}>
      <InformationCircleIcon width={16} height={16} className="text-black flex-shrink-0" />
      <div className={'text-xs font-normal'}>{message}</div>
    </div>
  );
};
