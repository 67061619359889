import { ApiInfo } from '@api/types';

const unitsApi: ApiInfo = {
  getDailyViewUnits: ({ params }) => {
    const { propertyId } = params!;
    return {
      url: `/${propertyId}/units/daily`,
    };
  },
  changeUnitStatus: ({ params, body }) => {
    const { propertyId, id } = params!;
    return {
      url: `/${propertyId}/units/${id}/change-status`,
      method: 'post',
      body,
    };
  },
  noShowReport: ({ params, body }) => {
    const { propertyId, id } = params!;
    return {
      url: `/${propertyId}/units/${id}/no-show-report`,
      method: 'post',
      body,
    };
  },
  getDetails: ({ params }) => {
    const { propertyId, id } = params!;
    return {
      url: `/${propertyId}/units/${id}`,
    };
  },
  getNextCheckoutDate: ({ params }) => {
    const { propertyId, id } = params!;
    return {
      url: `/${propertyId}/units/${id}/next-checkout`,
    };
  },
  getTasks: ({ params }) => {
    const { propertyId, id } = params!;
    return {
      url: `/${propertyId}/units/${id}/tasks`,
    };
  },
  startCleaning: ({ params }) => {
    const { propertyId, id } = params!;
    return {
      url: `/${propertyId}/units/${id}/cleaning/start`,
      method: 'post',
    };
  },
  endCleaning: ({ params, body }) => {
    const { propertyId, id } = params!;
    return {
      url: `/${propertyId}/units/${id}/cleaning/end`,
      method: 'post',
      body,
    };
  },
  countUnitsCleanedToday: ({ params }) => {
    const { propertyId } = params!;
    return {
      url: `/${propertyId}/units/count-cleaned-today`,
    };
  },
  dailyCleaningReport: ({ params }) => {
    const { propertyId } = params!;
    return {
      url: `/${propertyId}/units/daily-cleaning-report`,
    };
  },
  arrivalsOnDay: ({ params, query }) => {
    const { propertyId } = params!;
    const { date, membersOnly, includeUnitDetails } = query!;
    return {
      url: `/${propertyId}/units/arrivals?date=${date}${membersOnly ? `&membersOnly=${membersOnly}` : ''}${
        includeUnitDetails ? `&includeUnitDetails=${includeUnitDetails}` : ''
      }`,
    };
  },
  departuresOnDay: ({ params, query }) => {
    const { propertyId } = params!;
    const { date, includeUnitDetails } = query!;
    return {
      url: `/${propertyId}/units/departures?date=${date}${
        includeUnitDetails ? `&includeUnitDetails=${includeUnitDetails}` : ''
      }`,
    };
  },
  midstaysOnDay: ({ params, query }) => {
    const { propertyId } = params!;
    const { date, includeUnitDetails } = query!;
    return {
      url: `/${propertyId}/units/midstays?date=${date}${
        includeUnitDetails ? `&includeUnitDetails=${includeUnitDetails}` : ''
      }`,
    };
  },
  hskDelay: ({ params, body }) => {
    const { propertyId, id } = params!;
    return {
      url: `/${propertyId}/units/${id}/hsk-delay`,
      method: 'post',
      body,
    };
  },
  hskDelaysOnDay: ({ params, query }) => {
    const { propertyId } = params!;
    const { date, includeUnitDetails } = query!;
    return {
      url: `/${propertyId}/units/hsk-delays?date=${date}${
        includeUnitDetails ? `&includeUnitDetails=${includeUnitDetails}` : ''
      }`,
    };
  },
};

export default unitsApi;
