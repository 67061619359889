import Markdown from 'react-markdown';

interface MarkdownRendererProps {
  markdown: string;
  className?: string;
}

export default function MarkdownRenderer({ className = '', markdown }: MarkdownRendererProps) {
  const components = {
    a: ({ ...props }) => <a {...props} className="underline" target="_blank" rel="noopener noreferrer" />,
  };

  return (
    <Markdown className={className} components={components}>
      {markdown}
    </Markdown>
  );
}
