import { useCallback, useContext, useMemo, useState } from 'react';
import { TaskType } from '@typings/enums';
import useDeleteTask from '@utils/hooks/useDeleteTask';
import { TaskInfoType } from '@typings/types';
import TaskInfo from '@organisms/TaskInfo';
import { ALL_TEAMS, groupTasksByTeam } from '@utils/taskUtils';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import { isToday } from 'date-fns';
import { DATE_FORMAT_LONG, formatDate, MIDCLEAN_DATE_FORMAT, REQUEST_DATE_FORMAT } from '@utils/dateUtils';
import { SkeletonTasks } from './utils';
import { useTranslation } from 'react-i18next';
import SearchBar from '@molecules/SearchBar';
import FloatingAddButton from '@organisms/FloatingAddButton';
import useGetTaskData from '@utils/hooks/useGetTaskData';
import CollapsibleSection from '@molecules/CollapsibleSection';
import usePropertiesState from '@context/propertiesContext';
import { TaskDetailsDialog } from '@organisms/TaskDetails';
import useSearchParam from '@utils/hooks/useSearchParam';
import { Filters } from '@molecules/Filters';
import {
  FILTER_ACTIONS,
  FILTER_DATE_RANGE,
  FILTER_EXCLUDE_ACTIONS,
  FILTER_PRIORITY,
  FILTER_ROOMS,
  FILTER_RECURRENCE,
  FILTER_TEAMS,
  TasksContext,
} from '@context/filtersTasksContext';
import { getUnitsAndAreas } from '@molecules/Filters/utils';

export default function Tasks() {
  const { isOperationsRole, isCleanerOnlyRole } = useRoleBasedUI();
  const { filterParams } = useContext(TasksContext);

  const { selectedProperty } = usePropertiesState();
  const { id: propertyId, timeZone } = selectedProperty;
  const showGroupedByTeams = isOperationsRole(propertyId);
  const [editTask, setEditTask] = useState<TaskInfoType>();
  const setTaskId = useSearchParam({ key: 'taskId' })[1];
  const setTaskType = useSearchParam({ key: 'taskType' })[1];

  const { t } = useTranslation();
  const type = TaskType.STANDARD;
  const dateRange = filterParams[FILTER_DATE_RANGE].value;
  const teams = filterParams[FILTER_TEAMS].value;
  const priority = filterParams[FILTER_PRIORITY].value;
  const actions = filterParams[FILTER_ACTIONS].value;
  const excludeActions = filterParams[FILTER_EXCLUDE_ACTIONS].value;
  const recurrence = filterParams[FILTER_RECURRENCE].value;
  const unitsAndAreas = getUnitsAndAreas(filterParams[FILTER_ROOMS].value);

  const fromDate = dateRange ? formatDate(dateRange[0], REQUEST_DATE_FORMAT) : undefined;
  const toDate = dateRange ? formatDate(dateRange[1], REQUEST_DATE_FORMAT) : undefined;

  const data = useGetTaskData({
    propertyId,
    timeZone,
    type,
    fromDate,
    toDate,
    teams: teams ? teams : undefined,
    priority: priority ? priority : undefined,
    actions: actions ? actions : undefined,
    excludeActions: excludeActions ? excludeActions : undefined,
    recurrence: recurrence ? recurrence : undefined,
    rooms: unitsAndAreas?.units ? unitsAndAreas?.units : undefined,
    areas: unitsAndAreas?.areas ? unitsAndAreas?.areas : undefined,
    enabled: !!fromDate || !!toDate || !!teams,
  });

  const onClick = (task: TaskInfoType) => {
    setTaskId(task.id);
    setTaskType(task.type);
  };

  const {
    isFetchingTasks,
    isFetchingOverdue,
    isFetchingUnconfirmed,
    error,
    tasks,
    overdueTasks,
    unconfirmedTasks,
    search,
    debouncedSearch,
  } = data;

  const { setTaskForDeletion, renderDeleteConfirmationDialog } = useDeleteTask({});

  const isCleanerOnly = isCleanerOnlyRole(propertyId);

  const renderTasks = useCallback(
    (data: TaskInfoType[] | null, title: string) => {
      if (!data) return null;
      if (data.length === 0) return null;

      const groupedTasks = groupTasksByTeam(data);
      return (
        <CollapsibleSection
          defaultOpen
          headerTitle={
            <>
              {title}
              <span className={'text-sm'}> ({data.length})</span>
            </>
          }
        >
          <div className={'flex flex-col'}>
            {ALL_TEAMS.map(
              (team) =>
                !!groupedTasks[team] && (
                  <div key={team}>
                    <div className={'text-th-primary font-serif font-bold uppercase px-4 py-3'}>{t(team)}</div>
                    <div className={'flex flex-col'}>
                      {groupedTasks[team].map((task) => (
                        <TaskInfo
                          key={task.id}
                          task={task}
                          onClick={onClick}
                          onClickEdit={setEditTask}
                          onClickDelete={setTaskForDeletion}
                        />
                      ))}
                    </div>
                  </div>
                ),
            )}
          </div>
        </CollapsibleSection>
      );
    },
    [showGroupedByTeams, !!search],
  );

  if (error) {
    return <div>{t('anErrorHasOccurred')}</div>;
  }

  type taskedGroupedByDateType = { [key: string]: TaskInfoType[] };

  const tasksGroupedByDate = useMemo(() => {
    if (!tasks) return {};
    return tasks.reduce((acc, task) => {
      if (!task.dueAt) return acc;
      const taskDate = task.dueAt;
      const taskDateFormatted = formatDate(taskDate, MIDCLEAN_DATE_FORMAT);

      const taskDateKey = acc[taskDateFormatted] || [];
      return { ...acc, [taskDateFormatted]: [...taskDateKey, task] };
    }, {} as taskedGroupedByDateType);
  }, [tasks]);

  const sortedTasksGroupedByDateKeys = useMemo(() => {
    return Object.keys(tasksGroupedByDate).sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });
  }, [tasksGroupedByDate]);

  const AllTaskSections = useMemo(() => {
    const isFetching = isFetchingTasks || isFetchingOverdue || isFetchingUnconfirmed;
    const isDataFetched = !!tasks || !!overdueTasks || !!unconfirmedTasks;

    if (isFetching && !isDataFetched) {
      return <SkeletonTasks />;
    }

    return (
      <>
        {renderTasks(unconfirmedTasks, t('confirmationNeeded'))}
        {renderTasks(overdueTasks, t('overdue'))}

        {sortedTasksGroupedByDateKeys.map((date) => {
          const taskByDate = tasksGroupedByDate[date];
          if (taskByDate.length === 0) return null;
          const taskDate = formatDate(date, DATE_FORMAT_LONG);
          const taskDateFormatted = isToday(new Date(date)) ? `${t('today')}, ${taskDate}` : taskDate;

          return <div key={date}>{renderTasks(taskByDate, taskDateFormatted)}</div>;
        })}
      </>
    );
  }, [
    tasks,
    overdueTasks,
    unconfirmedTasks,
    isFetchingTasks,
    isFetchingOverdue,
    isFetchingUnconfirmed,
    tasksGroupedByDate,
    sortedTasksGroupedByDateKeys,
  ]);

  return (
    <>
      <div className={'flex flex-col space-y-4'}>
        <SearchBar onChange={(v) => debouncedSearch(v?.toLowerCase())} />
        <Filters />
        {AllTaskSections}
      </div>

      <div className={'mobile-bottom-space md:hidden'} />
      {renderDeleteConfirmationDialog()}
      <FloatingAddButton
        type={TaskType.STANDARD}
        editTask={editTask}
        isCleanerOnly={isCleanerOnly}
        onClose={() => setEditTask(undefined)}
      />
      <TaskDetailsDialog
        propertyId={propertyId}
        isCleanerOnly={isCleanerOnly}
        onClickEdit={(item) => {
          setEditTask(item);
        }}
        onClickDelete={(item) => {
          setTaskForDeletion(item);
        }}
      />
    </>
  );
}
