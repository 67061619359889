import Popover from '@molecules/Popover';
import { TableCell } from './TableCell';
import { useTranslation } from 'react-i18next';
import Spinner from '@atoms/Spinner';

interface PopoverCellProps {
  count: number;
  itemsLength?: number;
  onOpen?: () => void;
  className?: string;
  content?: JSX.Element[] | undefined;
}

export const PopoverCell = ({ count, itemsLength, onOpen, content, className = '' }: PopoverCellProps) => {
  const { t } = useTranslation();

  const renderPopover = (content: JSX.Element[] | undefined) => {
    if (count == 0 || itemsLength === 0) {
      return <div>{t('noRoomAssigned')}</div>;
    }

    if (!content) {
      return <Spinner className="w-6 h-6 text-th-primary" />;
    }

    return content;
  };

  return (
    <div className="w-full h-full">
      <Popover
        onOpen={onOpen}
        targetClasses="w-full h-full"
        target={<TableCell className={className} value={count} />}
        className="w-full h-full"
        content={
          <div className="p-[10px] flex flex-col gap-y-[10px] max-h-48 md:w-44 overflow-y-auto">
            {renderPopover(content)}
          </div>
        }
      />
    </div>
  );
};
