import React, { useCallback, useMemo, useState } from 'react';
import Dialog from '@molecules/Dialog';
import { Controller, useForm, useWatch } from 'react-hook-form';
import SelectProperty from '@organisms/SelectProperty';
import SelectInput from '@molecules/SelectInput';
import SelectUnit from '@organisms/SelectUnit';
import { PropertyType, TaskInfoType, ThinArea, ThinUnit } from '@typings/types';
import { DamageReportActionRequired, DamageReportArea, StandardizedDamageReport, TaskType } from '@typings/enums';
import Button, { ButtonType } from '@atoms/Button';
import useCreateOrUpdateTask, { DamageReportFormValues } from '@utils/hooks/useCreateOrUpdateTask';
import { useTranslation } from 'react-i18next';
import UploadMultiple from '@molecules/UploadMultiple';
import AutocompleteInput from '@molecules/AutocompleteInput';
import SelectDamageReportArea from '@organisms/SelectDamageReportArea';
import SelectDamageReportActionRequired from '@organisms/SelectDamageReportActionRequired';
import { formatDate } from '@utils/dateUtils';
import SelectTeam, { teamOptions } from '@organisms/SelectTeam';
import SelectPriority from '@organisms/SelectPriority';
import debounce from 'lodash.debounce';
import TextInput from '@molecules/TextInput';
import SelectDateWithHighlightedCheckout from '@organisms/SelectDateWithHighlightedCheckout';
import useImageUrls from '@utils/hooks/useImageUrls';
import { DateValue } from '@organisms/SelectDate/types';
import usePropertiesState from '@context/propertiesContext';
import { getDefaultFormValues } from './utils';
import { useAutoFocus } from '@utils/hooks/useAutoFocus';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArrayAny = any;

interface Props {
  apaleoReservationId?: string;
  editTask?: TaskInfoType;
  selectedUnit?: ThinUnit;
  onClose?: () => void;
  minDate?: Date;
  reservationCheckOutDate?: Date;
}

function AddDamageReport(props: Props) {
  const { apaleoReservationId, editTask, selectedUnit: defaultUnit, onClose, minDate, reservationCheckOutDate } = props;
  const { t } = useTranslation();
  const { selectedProperty } = usePropertiesState();

  const { images, pickerInitialImages } = useImageUrls(editTask?.imageUrls);

  const isEditTask = !!editTask;

  const defaultValues = getDefaultFormValues({
    property: selectedProperty,
    unit: defaultUnit,
    editTask,
    images,
    apaleoReservationId,
    minDate,
  });
  const form = useForm<DamageReportFormValues>({
    defaultValues,
    mode: 'onChange',
  });
  const {
    formState: { errors },
  } = form;

  const property = useWatch({
    control: form.control,
    name: 'property',
  });

  const defaultShowActionRequired = defaultValues.actionRequired
    ? !Object.values(DamageReportActionRequired).includes(defaultValues.actionRequired)
    : false;

  const [showActionRequiredTextInput, setShowActionRequiredTextInput] = useState(defaultShowActionRequired);
  const [selectedUnit, setSelectedUnit] = useState<ThinUnit | undefined>(defaultUnit);

  const onComplete = useCallback(() => {
    form.reset();
    setShowActionRequiredTextInput(false);
    onClose?.();
  }, [onClose]);

  const { isSubmitting, onSubmitDamageReport: onSubmit } = useCreateOrUpdateTask({
    property,
    editTask,
    type: TaskType.DAMAGE,
    onSuccess: onComplete,
  });

  const { setRef: setTitleRef } = useAutoFocus();

  const onSelectProperty = useCallback(
    (onChange: (...event: ArrayAny[]) => void) => {
      return (value: PropertyType) => {
        onChange(value);
        form.setValue('unit', undefined);
        setSelectedUnit(undefined);
      };
    },
    [form],
  );

  const onSelectUnit = useCallback(
    (onChange: (...event: ArrayAny[]) => void) => {
      return (unit: ThinUnit | ThinArea) => {
        onChange(unit);
        const u = unit as ThinUnit;
        // u?.number checks whether unit is selected (and not area)
        setSelectedUnit(u?.number ? u : undefined);
      };
    },
    [form],
  );

  const handleAreaOnTitleChange = useCallback(
    debounce((title) => {
      if (!title) {
        return;
      }
      if (
        [
          t(`standardizedDamageReports.${StandardizedDamageReport.BATHROOM_FILTER}`),
          t(`standardizedDamageReports.${StandardizedDamageReport.TOILET}`),
          t(`standardizedDamageReports.${StandardizedDamageReport.SHOWER}`),
          t(`standardizedDamageReports.${StandardizedDamageReport.SINK}`),
        ].includes(title as StandardizedDamageReport)
      ) {
        form.setValue('area', DamageReportArea.BATHROOM);
      }
      if (t(`standardizedDamageReports.${StandardizedDamageReport.STOVE}`) === (title as StandardizedDamageReport)) {
        form.setValue('area', DamageReportArea.KITCHEN);
      }
    }, 400),
    [form, t],
  );

  const onTitleChange = useCallback(
    (onChange: (...event: ArrayAny[]) => void) => {
      return (value: string) => {
        onChange(value);
        handleAreaOnTitleChange(value);
      };
    },
    [handleAreaOnTitleChange],
  );

  const onActionRequiredChange = useCallback(
    (onChange: (...event: ArrayAny[]) => void) => {
      return (actionRequired: string) => {
        if (actionRequired === DamageReportActionRequired.OTHER) {
          setShowActionRequiredTextInput(true);
          // todo: check how to remove field error instead of setting value to ' '
          // space value is a hack so the text input field that appears is not marked as error (required value)
          onChange(' ');
        } else {
          onChange(actionRequired);
          if (showActionRequiredTextInput) {
            setShowActionRequiredTextInput(false);
          }
        }
      };
    },
    [showActionRequiredTextInput],
  );

  const getActionRequiredOption = useCallback(
    (value: string): DamageReportActionRequired =>
      showActionRequiredTextInput ? DamageReportActionRequired.OTHER : (value as DamageReportActionRequired),
    [showActionRequiredTextInput],
  );

  const standardizedDamageReportsLabels = useMemo(
    () =>
      Object.keys(StandardizedDamageReport)
        // omit first 4
        .slice(4)
        .map((key) => {
          const value = t(`standardizedDamageReports.${key}`);
          return {
            value,
            title: value,
          };
        })
        .sort((a, b) => a.title.localeCompare(b.title)),
    [t],
  );

  return (
    <Dialog
      isOpen
      onClose={onComplete}
      isMobileSheet
      sheetFullHeight
      title={editTask?.id ? t('editItem') : t('addItem')}
      actionButton={
        <Button isLoading={isSubmitting} type={ButtonType.UNSTYLED} onClick={() => form.handleSubmit(onSubmit)()}>
          {t('save')}
        </Button>
      }
    >
      <div className={'md:w-96 flex flex-col px-4 overflow-y-scroll'}>
        <div className={'flex flex-1 flex-row'}>
          <div className={'flex-1'}>
            <Controller
              control={form.control}
              name={'title'}
              rules={{
                required: {
                  message: t('titleIsRequired'),
                  value: true,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <AutocompleteInput
                  forwardRef={setTitleRef}
                  value={value}
                  onChange={onTitleChange(onChange)}
                  placeholder={t('addTitle')}
                  options={standardizedDamageReportsLabels}
                  error={errors.title}
                />
              )}
            />
          </div>
          <div className={'py-3 pr-2'}>
            <UploadMultiple form={form} initialImages={pickerInitialImages} />
          </div>
        </div>

        <div className={'flex flex-col bg-gray-50 px-4 rounded-md'}>
          {!apaleoReservationId && (
            <Controller
              control={form.control}
              name={'property'}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <SelectProperty
                  value={value}
                  onSelect={onSelectProperty(onChange)}
                  actionButton={(onClickSelectProperty) => (
                    <SelectInput
                      disabled={isEditTask}
                      onClick={onClickSelectProperty}
                      value={value?.name}
                      label={t('property')}
                      placeholder={t('selectProperty')}
                    />
                  )}
                />
              )}
            />
          )}

          <Controller
            control={form.control}
            name={'unit'}
            rules={{
              required: {
                value: true,
                message: t('unitIsRequired'),
              },
            }}
            render={({ field: { onChange, value } }) => (
              <SelectUnit
                propertyId={property.id}
                value={value?.id}
                onSelect={onSelectUnit(onChange)}
                actionButton={(onClickSelectUnit) => (
                  <SelectInput
                    onClick={onClickSelectUnit}
                    value={value && ('number' in value ? value.number : value.name)}
                    label={t('rooms')}
                    placeholder={t('selectRooms')}
                    error={errors.unit}
                  />
                )}
              />
            )}
          />
          <Controller
            control={form.control}
            name={'team'}
            rules={{
              required: {
                message: t('teamIsRequired'),
                value: true,
              },
            }}
            render={({ field: { onChange, value } }) => (
              <SelectTeam
                value={value}
                enableGxTeam={property.enableGxTeam}
                onSelect={onChange}
                actionButton={(onClickSelectTeam) => (
                  <SelectInput
                    onClick={onClickSelectTeam}
                    value={value && t(teamOptions(property.enableGxTeam)[value].label)}
                    label={t('team')}
                    placeholder={t('selectTeam')}
                    error={errors.team}
                  />
                )}
              />
            )}
          />
          <Controller
            control={form.control}
            name={'area'}
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <SelectDamageReportArea
                value={value}
                onSelect={onChange}
                actionButton={(onClickSelectArea) => (
                  <SelectInput
                    onClick={onClickSelectArea}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    value={value ? t(`damageReportAreas.${value}`) : undefined}
                    label={`${t('area')}`}
                    placeholder={`${t('select')} ${t('area').toLowerCase()} (${t('optional').toLowerCase()})`}
                    error={errors.area}
                  />
                )}
              />
            )}
          />
          <Controller
            control={form.control}
            name={'actionRequired'}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => {
              const v = getActionRequiredOption(value);
              return (
                <SelectDamageReportActionRequired
                  value={getActionRequiredOption(value)}
                  onSelect={onActionRequiredChange(onChange)}
                  actionButton={(onClickSelectActionRequired) => (
                    <SelectInput
                      onClick={onClickSelectActionRequired}
                      value={v ? t(`damageReportActionsRequired.${v}`) : undefined}
                      label={t('actionRequired')}
                      placeholder={`${t('select')} ${t('actionRequired').toLowerCase()}`}
                      error={errors.actionRequired}
                    />
                  )}
                />
              );
            }}
          />
          {showActionRequiredTextInput && (
            <Controller
              control={form.control}
              name={'actionRequired'}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextInput value={value} onChange={onChange} placeholder={''} error={errors.actionRequired} />
              )}
            />
          )}
          <Controller
            control={form.control}
            name={'priority'}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <SelectPriority
                value={value}
                onSelect={onChange}
                actionButton={(onClickSelectPriority) => (
                  <SelectInput
                    onClick={onClickSelectPriority}
                    value={value ? t(`taskPriorities.${value}`) : undefined}
                    label={t('priority')}
                    placeholder={`${t('select')} ${t('priority').toLowerCase()}`}
                    error={errors.priority}
                  />
                )}
              />
            )}
          />
          <Controller
            name={'dueAt'}
            control={form.control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <SelectDateWithHighlightedCheckout
                value={value}
                onChange={onChange as unknown as (date: DateValue) => void}
                {...(selectedUnit
                  ? {
                      selectedUnit: {
                        id: selectedUnit.id,
                        propertyId: property.id,
                      },
                    }
                  : {})}
                minDate={minDate}
                checkOutDate={reservationCheckOutDate}
                actionButton={(onClickSelectDate) => (
                  <SelectInput
                    isLast
                    value={value && formatDate(value.date)}
                    label={t('dueDate')}
                    placeholder={t('selectDate')}
                    onClick={onClickSelectDate}
                    error={errors.dueAt}
                  />
                )}
              />
            )}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default AddDamageReport;
