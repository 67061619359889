import { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useSearchParam = <T = string>({
  key,
  defaultValue,
}: {
  key: string;
  defaultValue?: T;
}): [T, (value: T | null) => void] => {
  const navigate = useNavigate();

  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search.toString()), [search]);

  const setter = (newValue: T | null) => {
    /**
     * Use search from window to always get latest search params list
     */
    const search = window.location.search.toString();
    const newParams = new URLSearchParams(search);
    if (!newValue) {
      newParams.delete(key);
    } else {
      newParams.set(key, newValue.toString());
    }

    navigate({ search: newParams.toString() }, { replace: true });
  };

  return [(searchParams.get(key) || defaultValue || null) as T, setter];
};

export default useSearchParam;
