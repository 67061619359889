import { differenceInMinutes, isAfter } from 'date-fns';
import { FieldPath, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import { SlotForm } from './RoomSlotSidebar';
import { TFunction } from 'react-i18next';

export const formattedOption = (options: { id: string; title: string }[]) => {
  return options.map((option) => ({ id: option.id, label: option.title, value: option.id }));
};

type formErrorsType = {
  name: FieldPath<SlotForm>;
  type: string;
  message: string;
};

export function checkDateFormValidity(
  todayPropertyTimezone: Date,
  startDateFormatted: Date,
  endDateFormatted: Date,
  t: TFunction<'translation', undefined>,
  isOnGoingSlot?: boolean,
) {
  let isValidDate = true;
  const formDateErrors: formErrorsType[] = [];

  if (isAfter(todayPropertyTimezone, startDateFormatted) && !isOnGoingSlot) {
    formDateErrors.push({
      name: 'startDate',
      type: 'manual',
      message: t('errorStartDatePastSlotForm'),
    });
    isValidDate = false;
  } else if (isAfter(startDateFormatted, endDateFormatted)) {
    formDateErrors.push({
      name: 'endDate',
      type: 'manual',
      message: t('errorEndDatePastSlotForm'),
    });
    formDateErrors.push({
      name: 'endTime',
      type: 'manual',
      message: t('errorEndTimePastSlotForm'),
    });
    isValidDate = false;
  } else if (differenceInMinutes(endDateFormatted, startDateFormatted) < 30) {
    formDateErrors.push({
      name: 'endTime',
      type: 'manual',
      message: t('errorMinDurationSlotForm'),
    });
    isValidDate = false;
  }
  return { isValidDate, formDateErrors };
}

export function checkIsOnGoingSlot(todayPropertyTimezone: Date, startDateFormatted: Date, endDateFormatted: Date) {
  return isAfter(todayPropertyTimezone, startDateFormatted) && isAfter(endDateFormatted, todayPropertyTimezone);
}

export function showFormErrors(errors: formErrorsType[], setError: UseFormSetError<SlotForm>) {
  return errors.map(({ name, type, message }) => {
    setError(name, {
      type,
      message,
    });
  });
}

export function clearFormErrors(errors: formErrorsType[], clearErrors: UseFormClearErrors<SlotForm>) {
  const clearErrorsNames = errors.map(({ name }) => {
    return name;
  });
  clearErrors(clearErrorsNames);
}
