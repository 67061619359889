import React, { MouseEventHandler } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';

type Props = {
  name?: string;
  label?: string;
  value?: string;
  placeholder?: string;
  onClick: MouseEventHandler<HTMLElement>;
  isLast?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  disabled?: boolean;
};

function SelectInput({ isLast = false, ...props }: Props) {
  const { onClick, name, placeholder, label, value, error, disabled = false } = props;
  return (
    <>
      <div className={'flex flex-1 flex-row w-full items-center justify-between mt-1'}>
        {label && <span className={'text-sm font-bold text-gray-400'}>{label}</span>}
        <div className={'flex-col'} style={{ width: label ? '70%' : '100%' }}>
          <div
            onClick={!disabled ? onClick : undefined}
            className={`flex flex-row py-3 items-center ${!disabled ? 'cursor-pointer' : 'cursor-not-allowed'} ${
              (!isLast || error) && 'border-b border-gray-300'
            }'
          ${error ? 'border-red-500' : ''}
          `}
          >
            <span
              className={`
            ${value ? 'text-th-secondary' : 'text-gray-400'}
            ${error && 'text-red-500'} 
            transparent block w-full focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md`}
            >
              {value ?? placeholder}
            </span>
            <ChevronRightIcon className={'h-4 w-4'} />
          </div>
          {error && (
            <p className="mt-1 text-sm text-red-500" id={`${name}-error`}>
              {error.message}
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default SelectInput;
