import { useState } from 'react';
import { QueryClient } from 'react-query';

import { ColumnTitles } from './ColumnTitles';
import { TableBody } from './TableBody';

import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import { WeeklyViewPropertyData } from '@typings/types';

interface PropertyTableProps {
  propertyData: WeeklyViewPropertyData;
  propertyId: string;
  dates: Date[];
  memberBenefitsEnabled: boolean;
  paidEcisEnabled: boolean;
  queryClient: QueryClient;
  from: string;
  propertyIds: string[];
}

export type ExpandableSectionsType = {
  arrival: boolean;
  departure: boolean;
  midstays: boolean;
  operationalData: boolean;
  extraServices: boolean;
  tasks: boolean;
};

export const PropertyTable = ({
  propertyData,
  dates,
  paidEcisEnabled,
  propertyId,
  memberBenefitsEnabled,
  queryClient,
  from,
  propertyIds,
}: PropertyTableProps) => {
  const [expandedSections, setExpandedSections] = useState<ExpandableSectionsType>({
    arrival: true,
    departure: true,
    midstays: true,
    operationalData: false,
    extraServices: true,
    tasks: true,
  });
  const { isOperationsRole, isMaintainerRole, isRoomcheckerRole, isIctRole } = useRoleBasedUI();
  const isOperations = isOperationsRole(propertyId);
  const isMaintainer = isMaintainerRole(propertyId);
  const isRoomchecker = isRoomcheckerRole(propertyId);
  const isIct = isIctRole(propertyId);

  const toggleSection = (sectionKey: keyof ExpandableSectionsType) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionKey]: !prev[sectionKey],
    }));
  };

  const showArrivalAndDeparturePopovers = isMaintainer || isOperations || isRoomchecker || isIct;

  return (
    <div className="w-full">
      <ColumnTitles dates={dates} propertyName={propertyData.name} />
      <TableBody
        propertiesData={propertyData.data}
        propertyId={propertyId}
        showArrivalAndDeparturePopovers={showArrivalAndDeparturePopovers}
        memberBenefitsEnabled={memberBenefitsEnabled}
        paidEcisEnabled={paidEcisEnabled}
        queryClient={queryClient}
        propertyIds={propertyIds}
        isOperations={isOperations}
        expandedSections={expandedSections}
        toggleSection={toggleSection}
        from={from}
      />
    </div>
  );
};
