import icons from '@constants/icons';

export const NoResultFound = () => {
  return (
    <div className="flex flex-col justify-center items-center py-10 text-th-brown-50 font-sans font-semibold gap-4">
      <div>
        <icons.eventBusy />
      </div>
      <div className="text-center">
        <p>No slot found</p>
        <p>Try broadening your filters.</p>
      </div>
    </div>
  );
};
