import cn from 'classnames';
import { startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { UseMutationResult } from 'react-query';

import Dropdown from '@molecules/Dropdown';
import Popover from '@molecules/Popover';
import { MAX_APPROVED_LCOS } from '@organisms/WeeklyView/constants';

import { WeeklyViewData, WeeklyViewDateSummary } from '@typings/types';
import { openReservationInApaleo } from '@utils/unitUtils';
import dateUtils from '@utils/dateUtils';

interface LcoDropdownProps {
  dateData: WeeklyViewDateSummary;
  propertyId: string;
  allowDaySettingsEdit: boolean;
  saveDaySettingsMutation: UseMutationResult<
    WeeklyViewData,
    unknown,
    {
      date: Date;
      approvedLateCheckouts: number;
      latestAllowedCheckout: string;
      propertyId: string;
    },
    unknown
  >;
}

const getApprovedLateCheckoutsOptions = (from: number): { value: number | null; label: string }[] => {
  if (from > MAX_APPROVED_LCOS) return [];
  const options: { value: number | null; label: string }[] = Array(MAX_APPROVED_LCOS - from + 1)
    .fill(0)
    .map((_, i) => from + i)
    .map((i) => ({ value: i, label: `${i}` }));
  if (from === 0) {
    options.splice(0, 0, { value: null, label: '-' });
  }
  return options;
};

export const LcoDropdown = ({
  dateData,
  propertyId,
  allowDaySettingsEdit,
  saveDaySettingsMutation,
}: LcoDropdownProps) => {
  const { t } = useTranslation();
  const today = startOfDay(dateUtils.now());

  const approvedLcoLimit = dateData.approvedLcoLimit ?? 0;
  const approvedLcoCount = dateData.approvedLco.length;
  const isPastDate = dateUtils.isBefore(dateUtils.toDate(dateData.date), today);
  const isMismatch = !isPastDate && approvedLcoCount > approvedLcoLimit;
  const isDefault = dateData.approvedLcoLimit === dateData.defaultApprovedLcoLimit;
  const minApprovedLcoValue = Math.min(approvedLcoCount, approvedLcoLimit);
  const approvedLcoOptions = getApprovedLateCheckoutsOptions(minApprovedLcoValue);

  const renderPopoverContent = () => {
    return (
      <div className="p-4 flex flex-col flex-wrap max-h-48 md:w-44 md:overflow-scroll">
        {dateData.approvedLco.length === 0 ? (
          <div>{t('noRoomAssigned')}</div>
        ) : (
          dateData.approvedLco.map((lco) => (
            <button
              key={`${propertyId}-${today.toDateString()}`}
              className="text-blue-500 underline text-left w-auto"
              onClick={() => openReservationInApaleo(propertyId, lco.reservationExternalId)}
            >
              {lco.unitNumber}
            </button>
          ))
        )}
      </div>
    );
  };

  return (
    <>
      <Popover
        target={<span className={cn(isMismatch && 'px-1 text-white bg-th-accent')}>{approvedLcoCount}</span>}
        content={renderPopoverContent()}
      />
      <span>/</span>
      {allowDaySettingsEdit ? (
        <Dropdown
          value={dateData.approvedLcoLimit}
          items={approvedLcoOptions}
          onChange={(value) => {
            saveDaySettingsMutation.mutate({
              date: dateData.date,
              approvedLateCheckouts: value as number,
              latestAllowedCheckout: dateData.maxLcoTime,
              propertyId,
            });
          }}
          className={cn('font-bold px-1 rounded', isDefault ? 'bg-gray-200' : 'bg-yellow-300')}
        />
      ) : (
        <span>{dateData.approvedLcoLimit ?? '-'}</span>
      )}
    </>
  );
};
