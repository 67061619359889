import { ChevronDownIcon } from '@heroicons/react/solid';
import { useClickOutside } from '@utils/hooks/useClickOutside';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Option = {
  label: string;
  value: string;
};

interface AutocompleteInputMultiSelectionProps {
  label?: string;
  placeholder: string;
  options: Option[];
  selectedOptions: string[];
  onChange: (values: string[]) => void;
  disabled?: boolean;
  error?: string;
  buttonClassName?: string;
  optionsClassName?: string;
}

export const AutocompleteInputMultiSelection = ({
  label,
  options,
  selectedOptions,
  placeholder,
  buttonClassName,
  disabled = false,
  onChange,
  error,
  optionsClassName,
}: AutocompleteInputMultiSelectionProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [displayedOptions, setDisplayedOptions] = useState<Option[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useClickOutside(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    if (!isOpen) {
      setDisplayedOptions([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && displayedOptions.length === 0) {
      const selectedItems = options.filter((opt) => selectedOptions.includes(opt.value));
      const unselectedItems = options.filter((opt) => !selectedOptions.includes(opt.value));
      setDisplayedOptions([...selectedItems, ...unselectedItems]);
    }
  }, [isOpen, options, selectedOptions, displayedOptions.length]);

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  const handleOptionToggle = (value: string) => {
    const newSelectedOptions = selectedOptions.includes(value)
      ? selectedOptions.filter((item) => item !== value)
      : [...selectedOptions, value];

    onChange(newSelectedOptions);
  };

  const handleSelectAll = () => {
    const allSelected = selectedOptions.length === options.length;
    const newValues = allSelected ? [] : options.map((opt) => opt.value);
    onChange(newValues);
  };

  const allSelected = selectedOptions.length === options.length;
  const selectedItems = options.filter((opt) => selectedOptions.includes(opt.value));

  return (
    <div>
      {label && <span className="font-sans text-th-brown-300 font-semibold text-xs leading-4">{label}</span>}
      <div ref={wrapperRef}>
        <div className="relative">
          <button
            type="button"
            onClick={toggleDropdown}
            disabled={disabled}
            className={`border ${error ? 'border-th-red-300' : 'border-th-brown-100'} ${
              disabled && 'border-th-brown-100 bg-th-gray-250 cursor-default'
            } rounded-[4px] p-2 flex justify-between items-center gap-x-1 w-full ${
              !disabled && 'hover:bg-[#EDF0F3]'
            } overflow-hidden ${buttonClassName}`}
          >
            <div className="flex justify-start items-center gap-x-[2px] flex-grow overflow-hidden">
              <span className="block truncate">
                {selectedOptions.length > 0 ? selectedItems.map((opt) => opt.label).join(', ') : placeholder}
              </span>
            </div>
            <ChevronDownIcon
              className={`w-4 h-4 transition-transform duration-300 flex-shrink-0 ${isOpen && 'rotate-180'}`}
            />
          </button>

          {isOpen && (
            <div
              className={`w-full py-1 max-h-96 overflow-auto bg-white rounded-md shadow-lg 
              ring-black ring-opacity-5 text-base sm:text-sm ${optionsClassName}`}
            >
              <OptionItem
                label={t('selectAll')}
                checked={allSelected}
                onClick={handleSelectAll}
                className="border-b border-th-brown-50"
              />

              {displayedOptions.map((option, index) => (
                <OptionItem
                  key={option.value}
                  label={option.label}
                  checked={selectedOptions.includes(option.value)}
                  onClick={() => {
                    handleOptionToggle(option.value);
                  }}
                  className={index < displayedOptions.length - 1 ? 'border-b border-th-brown-50' : ''}
                />
              ))}
            </div>
          )}
        </div>
        {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
      </div>
    </div>
  );
};

type OptionItemProps = {
  label: string;
  checked: boolean;
  className?: string;
  onClick: () => void;
};

const OptionItem = ({ label, checked, className = '', onClick }: OptionItemProps) => {
  return (
    <button
      className={`w-full px-4 py-4 flex justify-between items-center cursor-pointer hover:bg-[#EDF0F3] text-th-brown ${className}`}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <span className="truncate capitalize">{label}</span>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          e.stopPropagation();
          onClick();
        }}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      />
    </button>
  );
};
