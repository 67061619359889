export enum UnitStatus {
  OCCUPIED_CLEANING_TODAY = 'OCCUPIED_CLEANING_TODAY',
  READY_TO_CLEAN = 'READY_TO_CLEAN',
  READY_TO_INSPECT = 'READY_TO_INSPECT',
  OCCUPIED = 'OCCUPIED',
  VACANT = 'VACANT',
  STAYOVER_READY_TO_CLEAN = 'STAYOVER_READY_TO_CLEAN',
  STAYOVER_READY_TO_INSPECT = 'STAYOVER_READY_TO_INSPECT',
}

export enum UnitStatusAction {
  CLEAN = 'CLEAN',
  TO_INSPECT = 'TO_INSPECT',
}

export enum AutomatedTaskType {
  KITCHEN_USE = 'KITCHEN_USE',
}

export enum RoomsFilterType {
  UNIT = 'UNIT',
  AREA = 'AREA',
}

export enum ReservationStatusKey {
  CONFIRMED = 'Confirmed',
  CANCELED = 'Canceled',
  IN_HOUSE = 'InHouse',
  NO_SHOW = 'NoShow',
  CHECKED_OUT = 'CheckedOut',
}

export enum MaintenanceSlotStatusKey {
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
  OUT_OF_ORDER = 'OUT_OF_ORDER',
  OUT_OF_INVENTORY = 'OUT_OF_INVENTORY',
}

export enum MaintenanceSlotReasonKey {
  CL = 'CL',
  MNT = 'MNT',
  BB = 'BB',
  SMK = 'SMK',
  VIP = 'VIP',
  PMP = 'PMP',
  RUSRU = 'RUSRU',
  RURNR = 'RURNR',
  HO = 'HO',
  OTHER = 'OTHER',
  RM = 'RM',
}

export enum ServerStateKey {
  DAILY_VIEW_UNITS = 'daily_view_units',
  PROPERTIES = 'properties',
  UNIT_DETAILS = 'unit_details',
  UNIT_NEXT_CHECKOUT_DATE = 'unit_next_checkout_date',
  PROPERTY_UNITS_AND_AREAS = 'property_units',
  LOST_AND_FOUND_TASKS = 'lost_and_found_tasks',
  DAMAGE_REPORT_TASKS = 'damage_report_tasks',
  TASK = 'task',
  TASKS = 'tasks',
  OVERDUE_TASKS = 'overdue_tasks',
  UNCONFIRMED_TASKS = 'unconfirmed_tasks',
  RESERVATION_TASKS = 'reservation_tasks',
  WEEKLY_VIEW = 'weekly_view',
  UNITS_CLEANED_TODAY_COUNT = 'units_cleaned_today_count',
  CLEANERS = 'cleaners',
  ASSIGNED_ROOMS = 'assigned_rooms',
  DAILY_CLEANING_REPORT = 'daily_cleaning_report',
  ARRIVALS_ON_DAY = 'arrivals_on_day',
  MEMBER_ARRIVALS_ON_DAY = 'member_arrivals_on_day',
  DEPARTURES_ON_DAY = 'departures_on_day',
  MIDSTAYS_ON_DAY = 'midstays_on_day',
  HSK_DELAYS_ON_DAY = 'hsk_delays_on_day',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_COUNT = 'notifications_count',
  NEXT_MIDSTAY_CLEAN = 'next_midstay_clean',
  NOTIFICATION_SETTINGS = 'notification_settings',
  RESERVATION = 'reservation',
  RESERVATION_CURRENT_TIME_SLICE = 'reservation_current_time_slice',
  DAY_SETTINGS = 'day_settings',
  LATE_CHECKOUTS_COUNT = 'late_checkouts_count',
  TASK_CATEGORIES = 'task_categories',
  FEATURE_TOGGLES = 'feature_toggles',
  ROOM_RACK = 'room_rack',
  ROOM_RACK_SLOT_AVAILABILITY = 'room_rack_slot_availability',
}

export enum Team {
  HOUSE_KEEPING = 'HOUSE_KEEPING',
  OPERATIONS = 'OPERATIONS',
  MAINTENANCE = 'MAINTENANCE',
  ICT = 'ICT',
  GX = 'GX',
}

export enum TaskType {
  STANDARD = 'STANDARD',
  MISCONDUCT = 'MISCONDUCT',
  DAMAGE = 'DAMAGE',
  LOST_AND_FOUND = 'LOST_AND_FOUND',
}

// first 4 are not used anymore, but we keep them so the translation can work for old damage reports
export enum StandardizedDamageReport {
  BATHROOM_FAN_LOUD = 'BATHROOM_FAN_LOUD',
  LIGHT_NOT_WORKING = 'LIGHT_NOT_WORKING',
  SINK_BLOCKED = 'SINK_BLOCKED',
  SHOWERDRAIN_BLOCKED = 'SHOWERDRAIN_BLOCKED',
  BATHROOM_FILTER = 'BATHROOM_FILTER',
  SINK = 'SINK',
  TOILET = 'TOILET',
  SHOWER = 'SHOWER',
  STOVE = 'STOVE',
  FRIDGE_MINIBAR = 'FRIDGE_MINIBAR',
  DOOR = 'DOOR',
  WINDOW = 'WINDOW',
  AC = 'AC',
  WIFI = 'WIFI',
  LIGHTBULB = 'LIGHTBULB',
  CHAIRS = 'CHAIRS',
  HEATING = 'HEATING',
  TV = 'TV',
  TABLES = 'TABLES',
  CHROMECAST = 'CHROMECAST',
}

export enum NotificationType {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  ERROR = 'ERROR',
}

export enum DamageReportArea {
  KITCHEN = 'KITCHEN',
  BED = 'BED',
  BATHROOM = 'BATHROOM',
  SEATING_AREA = 'SEATING_AREA',
  WALLS_CEILING_FLOOR = 'WALLS_CEILING_FLOOR',
  ICT = 'ICT',
}

export enum DamageReportActionRequired {
  CLEAN = 'CLEAN',
  REPLACE = 'REPLACE',
  REPAIR = 'REPAIR',
  CHECK = 'CHECK',
  OTHER = 'OTHER',
}

export enum TaskPriority {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum DeviceType {
  IOS = 'IOS',
  WEB = 'WEB',
}

export enum RecurrenceFilter {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum RoomRackQueryParams {
  SLOT_PANEL = 'slotPanel',
  START = 'start',
  ROOM_ID = 'roomId',
  SLOT_ID = 'slotId',
  SLOT_TYPE = 'slotType',
}
