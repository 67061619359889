import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@molecules/Dialog';
import SelectInput from '@molecules/SelectInput';
import { PropertyType, TaskInfoType, ThinUnit } from '@typings/types';
import SelectTeam, { teamOptions } from '@organisms/SelectTeam';
import SelectUnit from '@organisms/SelectUnit';
import SelectProperty from '@organisms/SelectProperty';
import { TaskType } from '@typings/enums';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import SelectDate from '@organisms/SelectDate/SelectDate';
import dateUtils, { formatDate } from '@utils/dateUtils';
import useCreateOrUpdateTask, { StandardTaskFormValues, taskValidation } from '@utils/hooks/useCreateOrUpdateTask';
import { useTranslation } from 'react-i18next';
import { getFrequencyValueLabel } from '@organisms/CustomRepetition/utils';
import AutocompleteInput from '@molecules/AutocompleteInput';
import UploadMultiple from '@molecules/UploadMultiple';
import useImageUrls from '@utils/hooks/useImageUrls';
import SelectPriority from '@organisms/SelectPriority';
import { getDefaultFormValues } from './utils';
import TextArea from '@molecules/TextArea';
import usePropertiesState from '@context/propertiesContext';
import cn from 'classnames';
import { isConfirmButAfterWorkHoursToday } from '@utils/taskUtils';
import { useConfirmation } from '@utils/hooks/useConfirmation';
import { addDays, startOfDay } from 'date-fns';
import { useAutoFocus } from '@utils/hooks/useAutoFocus';
import { TaskActionMapper } from '@api/taskAction.mapper';
import useTaskActionsState from '@context/taskActionsContext';
import { TaskAction } from '@api/taskAction.types';
import TaskActionTag from '@molecules/TaskActionTag';
import Spinner from '@atoms/Spinner';
import { XIcon } from '@heroicons/react/solid';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArrayAny = any;

interface Props {
  /**
   * In case of Gx/Apaleo tab, the task will be created for specific reservation only */
  apaleoReservationId?: string;
  minDate?: Date;
  maxDate?: Date;
  editTask?: TaskInfoType;
  selectedUnit?: ThinUnit;
  onClose?: () => void;
}

function CreateTask(props: Props) {
  const { apaleoReservationId, minDate, maxDate, editTask, selectedUnit, onClose } = props;
  const { t } = useTranslation();

  const { selectedProperty } = usePropertiesState();
  const { images, pickerInitialImages } = useImageUrls(editTask?.imageUrls);

  const { openConfirmationDialog, ConfirmationDialog } = useConfirmation();

  const isEditTask = !!editTask;

  const { data: taskActions, getTaskActionById } = useTaskActionsState();
  const taskActionOptions = taskActions.map(TaskActionMapper.toAutocompleteOption);

  const [taskAction, setTaskAction] = useState<TaskAction>();

  const defaultValues = getDefaultFormValues({
    property: selectedProperty,
    editTask,
    images,
    apaleoReservationId,
    minDate,
    unit: selectedUnit,
  });

  const form = useForm<StandardTaskFormValues>({
    mode: 'onChange',
    defaultValues,
  });

  const {
    formState: { isValid, errors },
  } = form;

  const property = useWatch({
    control: form.control,
    name: 'property',
  });

  useEffect(() => {
    if (editTask?.actionId) {
      const action = getTaskActionById(editTask.actionId);
      if (action) {
        setTaskAction(action);
        form.setValue('actionCategory', action);
      }
    }
  }, [editTask?.actionId]);

  const { isSubmitting, onSubmitStandardTask } = useCreateOrUpdateTask({
    property,
    editTask,
    type: TaskType.STANDARD,
    onSuccess: () => {
      onClose?.();
    },
  });

  const onSubmit: SubmitHandler<StandardTaskFormValues> = (data: StandardTaskFormValues) => {
    const showConfirmationDialog = isConfirmButAfterWorkHoursToday(
      data.dueAt.date!,
      data.property.timeZone,
      data.priority,
    );

    if (!showConfirmationDialog || isEditTask) {
      return onSubmitStandardTask(data, taskAction);
    }

    return openConfirmationDialog({
      title: t('createTask'),
      message: t('confirmHighPriorityTaskMessage'),

      primaryActionLabel: t('setTomorrow'),
      onClickPrimaryAction: () => {
        const dueAt = {
          ...data.dueAt,
          date: addDays(data.dueAt.date!, 1),
        };
        form.setValue('dueAt', dueAt);
        const newData = { ...data, dueAt };
        return onSubmitStandardTask(newData, taskAction);
      },

      secondaryActionLabel: t('keepToday'),
      onClickSecondaryAction: () => {
        return onSubmitStandardTask(data, taskAction);
      },
    });
  };

  const onSelectProperty = useCallback(
    (onChange: (...event: ArrayAny[]) => void) => {
      return (property: PropertyType) => {
        onChange(property);
        form.setValue('unit', undefined);
      };
    },
    [form],
  );

  const { setRef: setTitleRef, focus: titleFocusFn } = useAutoFocus();

  const onTitleChange = useCallback(
    (onChange: (...event: ArrayAny[]) => void) => {
      return (value: TaskAction | string) => {
        if (typeof value === 'string') {
          return onChange(value);
        }
        setTaskAction(value);
        form.setValue('actionCategory', value);
        if (value.description) {
          form.setValue('description', value.description);
        }
        if (value.team) {
          form.setValue('team', value.team);
        }
        if (value.priority) {
          form.setValue('priority', value.priority);
        }
        if (value.team) {
          form.setValue('team', value.team);
        }
        if (value.dueInDays) {
          const date = addDays(startOfDay(dateUtils.now()), value.dueInDays);
          form.setValue('dueAt', { date });
        }
        onChange(value.name);
      };
    },
    [form],
  );

  const onClickRemoveTaskAction = useCallback(() => {
    form.setValue('description', '');
    form.setValue('actionCategory', undefined);
    setTaskAction(undefined);
    titleFocusFn();
  }, [form, setTaskAction, titleFocusFn]);

  const onComplete = useCallback(() => {
    onClose?.();
    form.reset();
  }, [onClose, form]);

  return (
    <Dialog
      isOpen
      onClose={onComplete}
      isMobileSheet
      sheetFullHeight
      customHeader={
        <div
          className="w-full flex justify-between items-center px-4 pt-4 md:pt-1 pb-4 
                  border-b border-b-[#DEDEDE]"
        >
          <button
            type="button"
            className="bg-transparent rounded-md text-black hover:text-gray-500 focus:outline-none"
            onClick={onComplete}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="text-base font-bold font-serif">{editTask?.id ? t('editTask') : t('addTask')}</div>
          <div className="w-6" />
        </div>
      }
    >
      <>
        <div className={'md:w-96 flex flex-col px-4 overflow-y-scroll'}>
          <div className="flex flex-col">
            <div className={'flex flex-row'}>
              <div className={'flex-1'}>
                <Controller
                  control={form.control}
                  name={'title'}
                  rules={{
                    maxLength: {
                      message: t('titleMaxLength', {
                        maxLength: taskValidation.titleMaxLength,
                      }),
                      value: taskValidation.titleMaxLength,
                    },
                    required: {
                      message: t('titleIsRequired'),
                      value: true,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <AutocompleteInput
                      forwardRef={setTitleRef}
                      value={value}
                      visible={!taskAction}
                      options={taskActionOptions}
                      onChange={onTitleChange(onChange)}
                      placeholder={t('writeToSelectAction')}
                      error={errors.title}
                    />
                  )}
                />
              </div>
              <div className={'py-3 pl-2'}>
                <UploadMultiple form={form} initialImages={pickerInitialImages} />
              </div>
            </div>

            <div className="pb-2">
              <Controller
                control={form.control}
                name={'actionCategory'}
                rules={{
                  required: {
                    message: t('actionIsRequired'),
                    value: true,
                  },
                }}
                render={({ field: { value } }) => (
                  <TaskActionTag
                    errorMessage={errors.actionCategory?.message}
                    value={value}
                    onClickDelete={onClickRemoveTaskAction}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles.section}>
            {!apaleoReservationId && (
              <Controller
                control={form.control}
                name={'property'}
                defaultValue={defaultValues.property}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <SelectProperty
                    value={value}
                    onSelect={onSelectProperty(onChange)}
                    actionButton={(onClickSelectProperty) => (
                      <SelectInput
                        disabled={isEditTask}
                        onClick={onClickSelectProperty}
                        value={value?.name}
                        label={t('property')}
                        placeholder={t('selectProperty')}
                      />
                    )}
                  />
                )}
              />
            )}
            <Controller
              control={form.control}
              name={'unit'}
              defaultValue={
                selectedUnit
                  ? {
                      id: selectedUnit.id,
                      number: selectedUnit.number,
                    }
                  : undefined
              }
              rules={{
                required: {
                  value: !apaleoReservationId,
                  message: t('unitIsRequired'),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <SelectUnit
                  propertyId={property.id}
                  // todo currently only single room select works
                  value={value?.id}
                  onSelect={onChange}
                  actionButton={(onClickSelectUnit) => (
                    <SelectInput
                      value={value && ('number' in value ? value.number : value.name)}
                      onClick={onClickSelectUnit}
                      label={t('rooms')}
                      placeholder={t('selectRooms')}
                      error={errors.unit}
                    />
                  )}
                />
              )}
            />
            <Controller
              control={form.control}
              name={'team'}
              rules={{
                required: {
                  message: t('teamIsRequired'),
                  value: true,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <SelectTeam
                  value={value}
                  enableGxTeam={property.enableGxTeam}
                  onSelect={onChange}
                  actionButton={(onClickSelectTeam) => (
                    <SelectInput
                      onClick={onClickSelectTeam}
                      value={value && t(teamOptions(property.enableGxTeam)[value].label)}
                      label={t('team')}
                      placeholder={t('selectTeam')}
                      error={errors.team}
                    />
                  )}
                />
              )}
            />
            <Controller
              control={form.control}
              name={'priority'}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SelectPriority
                  value={value}
                  onSelect={onChange}
                  actionButton={(onClickSelectPriority) => (
                    <SelectInput
                      onClick={onClickSelectPriority}
                      value={value ? t(`taskPriorities.${value}`) : undefined}
                      label={t('priority')}
                      placeholder={`${t('select')} ${t('priority').toLowerCase()}`}
                      error={errors.priority}
                    />
                  )}
                />
              )}
            />
            <Controller
              name={'dueAt'}
              control={form.control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <SelectDate
                  minDate={minDate}
                  maxDate={maxDate}
                  value={value}
                  onChange={onChange}
                  actionButton={(onClickSelectDate) => (
                    <SelectInput
                      isLast
                      value={
                        value &&
                        `${formatDate(value.date)}${
                          value.recurrence ? ` • ${t(getFrequencyValueLabel(value.recurrence.frequency)!)}` : ''
                        }`
                      }
                      label={t('dueDate')}
                      placeholder={t('selectDate')}
                      onClick={onClickSelectDate}
                      error={errors.dueAt}
                    />
                  )}
                />
              )}
            />
          </div>
          <div className={styles.section}>
            <div className={'flex-1'}>
              <Controller
                control={form.control}
                name={'description'}
                render={({ field: { onChange, value } }) => (
                  <div className={'bg-white rounded-md mb-3 text-sm'}>
                    <TextArea
                      value={value}
                      onChange={onChange}
                      placeholder={t('addDescription')}
                      error={errors.description}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <ConfirmationDialog />
        </div>
        <div className="px-5 py-4 md:py-0 bg-white">
          <button
            onClick={form.handleSubmit(onSubmit)}
            className={`w-full py-[10px] text-white text-sm font-normal font-sans rounded-md ${
              !isValid ? 'bg-th-gray-300' : 'bg-th-secondary'
            } flex justify-center items-center`}
          >
            {isSubmitting ? <Spinner className={'text-white w-4 h-4'} /> : t('save')}
          </button>
        </div>
      </>
    </Dialog>
  );
}

const styles = {
  section: cn('flex flex-col bg-gray-50 px-4 rounded-md mb-4'),
};

export default CreateTask;
