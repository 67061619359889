import { AutocompleteOption } from '@molecules/AutocompleteInput/types';
import { TaskAction, TaskCategoryRecord } from './taskAction.types';
import { TaskPriority, Team } from '@typings/enums';

const teamNames: { [key: string]: Team } = {
  hsk: Team.HOUSE_KEEPING,
  mnt: Team.MAINTENANCE,
  ops: Team.OPERATIONS,
  ict: Team.ICT,
  gx: Team.GX,
};

const taskPriority: { [key: string]: TaskPriority } = {
  high: TaskPriority.HIGH,
  standard: TaskPriority.LOW,
};

export const TASK_CATEGORIES_DELIMITER = '>';

export class TaskActionMapper {
  static categoryToTaskActions(categoryDto: TaskCategoryRecord): TaskAction[] {
    const category = categoryDto.name;
    return categoryDto.subcategories.flatMap((subcategoryDto) => {
      const subcategory = subcategoryDto.name;
      return subcategoryDto.actions.map((actionDto) => {
        const {
          id,
          name,
          description,
          team: actionTeam,
          priority: actionPriority,
          dueInDays: actionDueInDays,
          automatedTask,
        } = actionDto;
        const categoryAndSubcategory = `${category} ${TASK_CATEGORIES_DELIMITER} ${subcategory}`;
        const team: Team | undefined = actionTeam ? teamNames[actionTeam] : undefined;
        const priority: TaskPriority | undefined = actionPriority ? taskPriority[actionPriority] : undefined;
        const dueInDays = actionDueInDays ? Number(actionDueInDays) : undefined;
        return {
          id,
          name,
          description,
          automatedTask,
          team,
          priority,
          dueInDays,
          category,
          subcategory,
          categoryAndSubcategory,
        };
      });
    });
  }

  static toTaskActions(dto: TaskCategoryRecord[]): TaskAction[] {
    return dto.flatMap(TaskActionMapper.categoryToTaskActions).sort(TaskActionMapper.sortTaskActions);
  }

  static toAutocompleteOption(data: TaskAction): AutocompleteOption<TaskAction> {
    return {
      title: data.name,
      helperText: data.categoryAndSubcategory,
      value: data,
    };
  }

  static sortTaskActions(x: TaskAction, y: TaskAction) {
    if (x.category !== y.category) {
      return x.category.localeCompare(y.category);
    }
    if (x.subcategory !== y.subcategory) {
      return x.subcategory.localeCompare(y.subcategory);
    }
    return x.name.localeCompare(y.name);
  }
}
