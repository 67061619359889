import LoadingMessage from '@atoms/LoadingMessage';
import { useAuth0 } from '@auth0/auth0-react';
import routes from '@constants/routes';
import useAppContext from '@context/appContext';
import usePropertiesState from '@context/propertiesContext';
import AdditionalTasks from '@organisms/AdditionalTasks';
import DailyOverview from '@organisms/DailyOverview';
import Notifications from '@organisms/Notifications';
import RoomAssignment from '@organisms/RoomAssignment';
import RoomRack from '@organisms/RoomRack';
import Tasks from '@organisms/Tasks';
import UnitView from '@organisms/UnitView';
import WeeklyView from '@organisms/WeeklyView';
import dateUtils from '@utils/dateUtils';
import Navbar from '@organisms/Navbar/Navbar';
import { TaskType } from '@typings/enums';
import analyticsService, { AppInitType } from '@utils/analyticsService';
import { useChat } from '@utils/hooks';
import useMessagingService from '@utils/hooks/useMessagingService';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import useSearchParam from '@utils/hooks/useSearchParam';
import { WebViewEventType, attachOpenNotificationFn, fireWebViewEvent } from '@utils/mobileAppService';
import storageService from '@utils/storageService';
import { startOfDay } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Route, Routes, useNavigate, useLocation, Outlet } from 'react-router-dom';
import SignIn from '@pages/SignIn';
import Settings from '@pages/Settings';
import TaskPage from '@pages/TaskPage';
import HousekeepingTab from '@pages/HousekeepingTab';
import Navigation from '@organisms/Navigation';
import HomeLayout from '@templates/HomeLayout';

export default function AppRouter(): JSX.Element {
  const { getAccessTokenSilently, user } = useAuth0();
  const today = startOfDay(dateUtils.now());
  const [taskViewDate, setTaskViewDate] = useState<Date>(today);
  const [weeklyViewDate, setWeeklyViewDate] = useState<Date>(today);

  const { isGxOnlyRole: isGxOnlyRoleFn } = useRoleBasedUI();
  const { selectedProperty, isLoading: isPropertiesLoading } = usePropertiesState();
  const { onLoggedInChat } = useChat();
  const queryClient = useQueryClient();

  const isGxOnlyRole = selectedProperty?.id && isGxOnlyRoleFn(selectedProperty.id);

  const { getMessagingToken } = useMessagingService();

  const navigate = useNavigate();
  const location = useLocation();

  const { isApaleoTab, isContentOnly, isFullScreen } = useAppContext();

  const [taskId] = useSearchParam({ key: 'taskId' });
  const [taskType] = useSearchParam<TaskType>({ key: 'taskType', defaultValue: TaskType.STANDARD });

  const initMobileApp = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    attachOpenNotificationFn(navigate);
    fireWebViewEvent({
      type: WebViewEventType.ON_LOAD,
      payload: { accessToken, userId: user?.sub ?? '' },
    });
  }, [navigate, user]);

  const onInit = useCallback(async () => {
    if (!user) {
      return;
    }
    let appInitType;
    if (storageService.isMobileApp()) {
      initMobileApp();
      appInitType = AppInitType.WEB_VIEW;
    } else if (isApaleoTab) {
      onLoggedInChat(user);
      appInitType = AppInitType.APALEO_TAB;
    } else {
      onLoggedInChat(user);
      getMessagingToken();
      appInitType = AppInitType.REGULAR;
    }
    await analyticsService.setUser(user?.sub ?? '', appInitType);
  }, [getMessagingToken]);

  useEffect(() => {
    onInit();
  }, [onInit]);

  useEffect(() => {
    /**
     * If Gx only role and url contains taskId, it should point user to Task Details page.
     */
    if (!isApaleoTab && isGxOnlyRole && taskId && taskType === TaskType.STANDARD) {
      navigate(
        { pathname: routes.TASK_DETAILS.replace(':id', taskId), search: location.search.toString() },
        { replace: true },
      );
    }
  }, [isApaleoTab, isGxOnlyRole, taskId, taskType, location.search]);

  /**
   * Due to chat being an iframe, when chat loads,
   * if we click anywhere outside chat,
   * it will refocus and reload all queries
   * This prevents it while it is open through task id.
   */
  useEffect(() => {
    if (isApaleoTab) {
      return;
    }
    const refetchOnWindowFocus = !taskId;
    queryClient.setDefaultOptions({
      queries: {
        refetchOnWindowFocus,
      },
    });
  }, [isApaleoTab, taskId]);

  if (isPropertiesLoading) {
    return <LoadingMessage className="flex-1 justify-center" />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className={'bg-gray-50 flex flex-1 w-full'}>
            <HomeLayout
              navigation={<Navigation />}
              content={
                <div className={'flex flex-1 min-h-0 flex-col w-full'}>
                  {!isContentOnly && (
                    <Navbar
                      taskViewDate={taskViewDate}
                      setTaskViewDate={setTaskViewDate}
                      weeklyViewDate={weeklyViewDate}
                      setWeeklyViewDate={setWeeklyViewDate}
                    />
                  )}
                  <div
                    className={`flex flex-col space-y-5 flex-1 ${isFullScreen ? 'px-2' : 'px-3 pb-5'} ${
                      !isContentOnly && 'md:pl-44'
                    } overflow-y-auto`}
                  >
                    {selectedProperty && <Outlet />}
                  </div>
                </div>
              }
            />
          </div>
        }
      >
        <Route path={routes.WEEKLY} element={<WeeklyView date={weeklyViewDate} />} />
        <Route path={routes.NOTIFICATIONS} element={<Notifications />} />

        <Route path={routes.TASKS} element={<Tasks />} />
        <Route
          path={routes.LOST_AND_FOUND}
          element={<AdditionalTasks type={TaskType.LOST_AND_FOUND} date={taskViewDate} />}
        />
        <Route path={routes.DAMAGE_REPORT} element={<AdditionalTasks type={TaskType.DAMAGE} date={taskViewDate} />} />
        <Route path={routes.UNIT_DETAILS} element={<UnitView />} />
        <Route path={routes.ROOM_RACK} element={<RoomRack />} />
        <Route index element={<DailyOverview />} />
        <Route path={routes.ASSIGN_ROOMS} element={<RoomAssignment />} />
      </Route>
      <Route path={routes.SIGN_IN} element={<SignIn />} />
      <Route path={routes.SETTINGS} element={<Settings />} />
      <Route path={routes.TASK_DETAILS} element={<TaskPage />} />
      <Route path={routes.APALEO_HOUSEKEEPING} element={<HousekeepingTab />} />
    </Routes>
  );
}
