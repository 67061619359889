import { format, isToday } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { getDateFnsCurrentLocale } from '@utils/getDateFnsCurrentLocale';
import { RowTitle } from './CellComponents/RowTitle';

interface TableHeaderProps {
  dates: Date[];
  propertyName: string;
}

export const ColumnTitles = ({ dates, propertyName }: TableHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-row items-center gap-x-4">
      <RowTitle isExpandable={false}>{t('date')}</RowTitle>
      <div className="bg-gray-100 w-full flex flex-row">
        {dates.map((date) => {
          const getDayName = format(date, 'E', { locale: getDateFnsCurrentLocale() });
          return (
            <div
              key={`${propertyName}-${date}`}
              className={`h-[45px] md:h-[49px] flex flex-col flex-1 justify-center items-center text-th-brown-300 
                border border-th-gray-100 font-normal text-xs md:text-sm md:leading-[18px] ${
                  isToday(new Date(date)) ? 'bg-th-secondary text-th-gray-50' : 'bg-th-gray-50'
                }`}
            >
              <div>{format(new Date(date), 'dd')}</div>
              <div>{getDayName}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
