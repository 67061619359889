import { useMemo } from 'react';
import { startOfDay, addDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { formatDate, getDatesBetweenDays, REQUEST_DATE_FORMAT } from '@utils/dateUtils';
import LoadingMessage from '@atoms/LoadingMessage';
import { useGetWeeklyView } from '@api/api';
import { isMemberBenefitsActivated } from '@utils/propertyUtils';
import usePropertiesState from '@context/propertiesContext';
import { PropertySection } from './components/PropertySection';
import Spinner from '@atoms/Spinner';

interface Props {
  date: Date;
}

export default function WeeklyView({ date }: Props) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { selectedProperties } = usePropertiesState();
  const propertyIds = useMemo(() => selectedProperties.map((p) => p.id), [selectedProperties]);

  const dates = useMemo(() => {
    const from = startOfDay(date);
    const to = addDays(from, 7);
    return getDatesBetweenDays(from, to);
  }, [date]);

  const from = formatDate(date, REQUEST_DATE_FORMAT);
  const { isLoading, isFetching, error, data } = useGetWeeklyView(propertyIds, from, {
    keepPreviousData: true,
  });

  const isFetchingNewProperties = propertyIds.some((id) => !data || !Object.keys(data).includes(id));

  if (isLoading && !data) {
    return <LoadingMessage />;
  }

  if (error) {
    return <div>{t('anErrorHasOccurred')}</div>;
  }

  return (
    <div className="flex flex-col">
      {data &&
        Object.keys(data).map((propId) => {
          const propertyData = data[propId];
          const selectedPropertyData = selectedProperties.find((p) => p.id === propId);
          const memberBenefitsEnabled = isMemberBenefitsActivated(selectedPropertyData?.memberBenefits || []);
          const paidEcisEnabled = selectedPropertyData?.enablePaidEci || false;

          return (
            <PropertySection
              key={propId}
              propertyData={propertyData}
              propertyId={propId}
              dates={dates}
              memberBenefitsEnabled={memberBenefitsEnabled}
              paidEcisEnabled={paidEcisEnabled}
              queryClient={queryClient}
              from={from}
              propertyIds={propertyIds}
            />
          );
        })}
      {isFetching && isFetchingNewProperties && (
        <div className="w-full flex justify-center items-center">
          <Spinner className="text-th-primary w-8 h-8" />
        </div>
      )}
    </div>
  );
}
