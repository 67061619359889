import { maintenanceSlotOptions, maintenanceReasonsSlot } from '../utils';
import { APALEO_MAINTENANCE_SLOT_REASON } from '../RoomRackTable/types';
import { ExclamationIcon } from '@heroicons/react/outline';

const NaApaleoReason = {
  icon: ExclamationIcon,
  translationKey: 'createdInApaleo',
  value: APALEO_MAINTENANCE_SLOT_REASON,
};

export const allReasonsSlotIncludingNotValid = [...maintenanceReasonsSlot, NaApaleoReason];

export const defaultOptions = {
  slotType: maintenanceSlotOptions,
  reason: maintenanceReasonsSlot,
};

export const defaultMinMaxDate = 365;
export const maxTimeCleaningAfter = 14;
