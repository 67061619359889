import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Hlasitý ventilátor do koupelny',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Světlo nefunguje',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Umyvadlo je zablokované',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Sprchový odtok je ucpaný',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Filtr do koupelny',
  [StandardizedDamageReport.SINK]: 'Dřez',
  [StandardizedDamageReport.TOILET]: 'Toaleta',
  [StandardizedDamageReport.SHOWER]: 'Sprcha',
  [StandardizedDamageReport.STOVE]: 'Sporák',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Lednice/minibar',
  [StandardizedDamageReport.DOOR]: 'Dveře',
  [StandardizedDamageReport.WINDOW]: 'Okno',
  [StandardizedDamageReport.AC]: 'AC',
  [StandardizedDamageReport.WIFI]: 'Wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Žárovka',
  [StandardizedDamageReport.CHAIRS]: 'Židle',
  [StandardizedDamageReport.HEATING]: 'Topení',
  [StandardizedDamageReport.TV]: 'televize',
  [StandardizedDamageReport.TABLES]: 'Tabulky',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'HSK Team',
  [Team.MAINTENANCE]: 'Maintenance Team',
  [Team.OPERATIONS]: 'Operations Team',
  [Team.ICT]: 'ICT Team',
  [Team.GX]: 'GX Team',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Kuchyně',
  [DamageReportArea.BED]: 'Postel',
  [DamageReportArea.BATHROOM]: 'Koupelna',
  [DamageReportArea.SEATING_AREA]: 'Prostor k sezení',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Stěny/strop/podlaha',
  [DamageReportArea.ICT]: 'ICT',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Čistý',
  [DamageReportActionRequired.REPLACE]: 'Nahradit',
  [DamageReportActionRequired.REPAIR]: 'Opravit',
  [DamageReportActionRequired.CHECK]: 'Šek',
  [DamageReportActionRequired.OTHER]: 'jiný',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Standard',
  [TaskPriority.HIGH]: 'Vysoký',
};

const popupNotifications = {
  taskDeletionNotification: 'úkoly jsou úspěšně smazané ',
  lostAndFoundDeletionNotification: 'Ztracené věci byli úspěšně smazané ',
  damageReportDeletionNotification: 'Hlášení škody bílo úspěšně zmazané',
  taskCreationNotification: 'Úkol byl úspěšně vytvořen ',
  lostAndFoundCreationNotification: 'Ztracené věci byli úspěšně vytvořen ',
  damageReportCreationNotification: 'Hlášení škody bílo úspěšně vytvořen ',
  taskCompletionNotification: 'Úkol byl úspěšně ukončeno ',
  lostAndFoundCompletionNotification: 'Ztracené věci byli úspěšně ukončeno',
  damageReportCompletionNotification: 'Hlášení škody bílo úspěšně ukončeno ',
  taskUpdateNotification: 'Úkol se úspěšně změnil  ',
  lostAndFoundUpdateNotification: 'Ztracené věci se úspěšně změnil ',
  damageReportUpdateNotification: 'Sladění škody se úspěšném změnil ',
  pleaseSelectRoomsNotification: 'Vyberte prosím pokoje',
  noShowReportNotification: 'No Show úspěšně nahlášeno pro místnost {{number}}',
  checkInReportNotification: 'Check in úspěšně nahlášeno pro místnost {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Zpráva o škodě byla úspěšně naplánována na {{date}}',
  hskDelayNotification: 'Úspěšné zpoždění HSK pro místnost {{číslo}}. Čas odbavení je aktualizován na {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
  [CleaningInterval.EVERY_4_DAYS]: 'Every 4 days',
};

export default {
  ...teamNames,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  moreFilters: 'Více filtrů',
  resetAllFilters: 'Obnovit všechny filtry',
  reset: 'Obnovit',
  cleaned: 'Čistý',
  allRoomsCleaned: `Všechny pokoje vyčištěny`,
  dirty: 'Špinavý',
  readyToClean: 'Čištění zapotřeby',
  stayoverReadyToClean: 'Obsazeno - připraven na čištění',
  occupiedCleaningToday: 'Obsazeno - čistit dnes',
  readyToInspect: 'Připraveno na kontrolu ',
  stayoverInspection: 'Obsazeno - kontrola',
  vacant: 'Volno',
  occupied: 'Obsazeno',
  clean: 'Čistý',
  daily: 'Denně',
  weekly: 'Týdne',
  biweekly: 'Každý druhy týden',
  monthly: 'Měsíčně',
  none: 'Žádné',
  tasks: 'Úkoly',
  notifications: 'Oznámení',
  more: 'Více',
  select: 'Vybrat',
  property: 'Dům',
  result: 'Výsledek',
  results: 'Výsledky',
  properties: 'Domy',
  rooms: 'Pokoje',
  team: 'Team',
  dueDate: 'Datum splatnosti',
  date: 'Datum',
  close: 'Zavřít',
  search: 'Hledat',
  login: 'Login',
  loading: 'Nabijí',
  anErrorHasOccurred: 'Chyba',
  chooseDate: 'Vybrat datum',
  selectDate: 'Vybrat datum',
  selectADate: 'Vyprat jeden datum',
  markAsClean: 'Označit jako čisté',
  markAsReadyToInspect: 'Připraveno pro kontrolu',
  viewTasks: 'Podívat se ne úkoly',
  eci: 'ECI',
  lco: 'LCO',
  snacks: 'SNACKS',
  roomLog: `Záznam pokoje`,
  taskLog: `Záznam úkolů`,
  seeDetails: 'Detaily',
  assignedTasks: 'Přidělené úkoly',
  delete: 'Vymazat',
  deleteTask: 'Vymazat úkol',
  deleteTaskConfirmationMessage: 'Jste se jistí že chcete vymazat tento úkol ?',
  deleteTaskRepeatsConfirmationMessage: 'Opakující úkol - jestli vymažte tento úkol, vymažte každý úkol',
  deleteOnlyThisTask: 'Vymazat jen tento úkol',
  deleteRepeatingTask: 'Vymazat opakující se úkoly',
  yes: 'Ano',
  no: 'Ne',
  pleaseWaitAFewSeconds: 'Prosím čekejte moment',
  deleting: 'Vymazá se',
  lostAndFound: 'Ztracené věci',
  current: 'Aktuální',
  archive: ' Archiv',
  edit: 'Upravovat',
  markAsResolved: 'Vyřízeno',
  markAsUnresolved: 'Není vyřízeno',
  add: 'Přidat',
  area: 'Úsek',
  addTask: 'Přidat úkol',
  editTask: 'Změnit úkol',
  takePhoto: 'Fotografie',
  seeTaskDetails: 'Zobrazit úkol',
  savePicturesAndStartCleaning: 'Uložte a začněte uklízet',
  savePictures: 'Uložit obrázky',
  addArea: 'Přidat úsek',
  addAreaDescription: 'Přidat úsek, pro tento úkol',
  selectProperty: 'Vybrat dům',
  selectRooms: 'Vyprat pokoje',
  selectTeam: 'Vybrat Team',
  selectDueDate: 'Vybrat datum',
  hskTeam: 'HSK Team',
  save: 'Uložit',
  writeTask: 'Vytvořit úkol ',
  actionIsRequired: `Je vyžadováno jednání. Odstraňte titul a vyberte akci ze seznamu.`,
  noActionSelected: `Není vybrána žádná akce`,
  changeSearchAction: `Zkuste změnit váš vyhledávací výraz`,
  noActionFound: `Nenalezena žádná akce`,
  selectActionHelp: `Nemůžete najít akci? Prosím, kontaktujte tým Ops, aby ji mohli požádat.`,
  writeToSelectAction: 'Napište pro výběr akce.',
  repetitionTime: 'Frekvence',
  repetition: 'Opakování',
  noRepetition: 'Žádné opakování',
  doesNotRepeat: 'Neopakovatelné',
  day: 'Den',
  week: 'Týden',
  month: 'Měsíc',
  repeatOn: 'Zopakovat v …',
  repeatsEvery: 'Zopakovat každý',
  description: 'Popis',
  title: 'Název',
  addTitle: 'Přidejte název',
  titleIsRequired: 'Název je povinný',
  titleMaxLength: 'Název by měl být kratší než {{maxLength}} znaků',
  teamIsRequired: 'Team zapotřebí',
  openTasks: 'Nedokončené úkoly',
  arrival: 'Příjezd',
  departure: 'Odjezd',
  checkIn: 'Check in',
  times: 'Čas',
  guests: 'Hosté',
  name: 'Jméno',
  additionalInfo: 'Další informace',
  addLostAndFound: 'Hlásit ztracené věci',
  addDamageReport: 'Hlásit poškození',
  unitIsRequired: 'Pokoj zapotřebí',
  addItem: 'Přidat zápis',
  editItem: 'Změnit zápis',
  startCleaning: 'Čištění - začínat',
  endCleaning: 'Čištění - ukončeno',
  endCleaningMessage: 'Zbylo 1 nedokončený úkol. Jste jsi jistá že čištění je ukončeno?',
  endCleaningMessage_plural: 'Zbylí {{count}} nedokončený úkoly. Jste jsi jistá že čištění je ukončeno?',
  cancelAndViewTasks: 'Přerušit a podívat se na úkoly',
  ignoreAndMarkAsReadyToInspect: 'Ano, připraveno pro kontrolu  ',
  ignoreAndMarkAsCleaned: 'Ano, pokoj je čistí',
  damageReports: 'Hlášení škody ',
  confirm: 'Potvrdit ',
  selectRoomOrArea: 'Vyprat pokoj/úsek nebo ',
  createNewOne: 'Vytvořit nové ',
  today: 'Dnes ',
  tomorrow: 'Zítra',
  noTasksMessage: 'Žádný úkoly nebili přidání ',
  cleaningStatus: 'Čištění ',
  logOut: 'Log out ',
  language: 'Řec ',
  selectLanguage: 'Vybrat řec ',
  isStandardized: 'Standardizované ? ',
  selectStandardizedTask: 'Vybrat standardní úkol ',
  overdue: 'Zpoždění ',
  arrivals: 'Přejezdy ',
  memberArrivals: 'Member arrivals',
  departures: 'Odjezdy ',
  stayovers: 'Midstay čištění ',
  occupancy: 'OTB obsazenost ',
  midstayCleaning: 'Midstay čištění ',
  roomsToSell: 'Volné pokoje ',
  editImages: 'Vybrat fotky ',
  toClean: 'čistit',
  toInspect: 'zkontrolovat',
  noRoomsForCleaningToday: 'Dnes žádné úklidové místnosti',
  noShow: 'No show',
  noShowReportConfirmationMessage: 'Potvrďte prosím, že pokoj číslo {{number}} se nezobrazuje.',
  noShowReportQuestion: 'Přihlásil se host včera?',
  checkInReportConfirmationMessage: 'Potvrďte prosím, že se host přihlásil na pokoj číslo {{number}}.',
  noResultsSearchMessage: 'Vašim kritériím vyhledávání neodpovídají žádné výsledky',
  cancel: 'zrušení',
  selectCleaner: 'Vyberte čistič',
  selectAll: 'Vybrat vše',
  floor: 'Podlaha',
  notAssigned: 'Nepřiřazena',
  assignXRooms: 'Přidělte {{total}} místností',
  assignRooms: 'Přidělte místnosti',
  approvedLCOs: 'Schválené LCO',
  cleaner: 'Čistič',
  roomsCleanForToday: 'Všechny pokoje jsou pro dnešek čisté!🎉🎉',
  viewDamageReports: 'Prohlédněte si zprávy o škodách',
  noTasks: 'Žádné úkoly',
  noDamageReports: 'Žádné zprávy o škodách',
  dailyCleaningReport: 'Zpráva o denním úklidu',
  stayover: 'Zůstat',
  roomMoveFrom: 'Přesun místnosti z {{value}}',
  roomMoveTo: 'Přesun místnosti na {{value}}',
  unassignAll: 'Zrušit přiřazení všech',
  unassignRoomsConfirmationMessage: 'Opravdu chcete zrušit přiřazení {{total}} místností?',
  unassignRoomsTitle: 'Zrušit přiřazení místností',
  noRoomAssigned: 'Žádná přidělená místnost',
  action: 'Akce',
  actionsSelected: '{{total}} akce vybrána(y)',
  hideActions: 'Skrýt akce',
  showActions: 'Zobrazit akce',
  actionRequired: 'Je vyžadována akce',
  disableActionTitleModal: 'Obnovit filtr {{name}}',
  disableActionDescriptionModal: 'Chcete-li použít filtr {{currentFilter}}, resetujte prosím filtr {{resetFilter}}.',
  priority: 'Přednost',
  lcoUntil: 'LCO dokud',
  created: 'Vytvořeno',
  startNow: 'Začněte hned',
  scheduleTime: 'Naplánujte si čas',
  viewInfo: 'Zobrazit informace',
  reminder: 'Připomínka',
  reminderTaskNotificationText: 'Váš naplánovaný úkol má být dokončen dnes',
  inProgress: 'Probíhá',
  scheduled: 'Naplánováno',
  moveMidstayCleaning: 'Move Stayover Cleaning?',
  moveMidstayTitle: 'Move Midstay',
  moveMidstayDescription: 'Opravdu chcete přesunout průběžný úklid na {{date}}?',
  moveMidstayNotPossible: 'Promiňte! Úklid v tomto pokoji nelze přesunout na jiný den!',
  moveMidstayNotification: 'Pobytové čištění bylo úspěšně přesunuto na {{date}}.',
  pleaseContactOps: 'Kontaktujte prosím provozní tým',
  midCleanMovedTo: 'Pobytový úklid se přesunul na: {{value}}',
  optional: 'volitelný',
  hskDelay: 'zpoždění HSK',
  hskDelays: 'HSK zpoždění',
  hskDelayMessage: 'Aktualizujte čas příjezdu pro pokoj {{number}}',
  notStarted: 'Nezačal',
  resolved: 'Vyřešeno',
  noDamageReportsToday: 'Pro dnešek nejsou hlášeny žádné škody',
  yourTask: 'Váš úkol',
  yourTaskGX: 'Váš úkol z GX',
  damageReportedByGX: 'Poškození hlášeno GX',
  stay: 'Podle data pobytu',
  checkOut: 'Čas odhlášení',
  noPermission: 'Nemáte povolení, kontaktujte prosím podporu.',
  approvedCheckoutTime: 'Schválený čas odhlášení',
  requestedCheckoutTime: 'Požadovaný čas odhlášení',
  checkinTime: 'Čas příjezdu',
  commentForHousekeeping: 'Komentář pro úklid',
  numberOfApprovedLCOs: 'Počet schválených LCO',
  notSet: 'Nenastaveno',
  vip: 'VIP',
  extraService: 'Dodatečná služba',
  reservationMissing: 'Rezervace je pravděpodobně zrušena, přepněte na všeobecnou záložku',
  confirmFor: 'Potvrdit pro {{date}}',
  confirmAnotherDay: 'Potvrdit na jiný den',
  taskConfirmed: 'Potvrdil(a) jsi úkol pro {{date}}',
  confirmYourTask: 'Potvrďte svůj úkol',
  confirmYourTaskGX: 'Potvrďte svůj úkol od GX týmu.',
  confirmHighPriorityTaskMessage:
    'Chcete opravdu nastavit termín splatnosti na dnešek? Je pravděpodobné, že úkol nebude dnes potvrzen a dokončen.',
  setTomorrow: 'Nastavit na zítra',
  keepToday: 'Zachovejte dnes',
  createTask: 'Vytvořit úkol',
  confirmationNeeded: 'Potřeba potvrdit',
  pictureTooBlurry: `Obrázek je příliš rozmazaný. Pořiďte prosím jasnou fotku a nahrajte ji.`,
  tryAgain: `Zkuste to znovu`,
  addDescription: `Přidat popis`,
  togglePushNotificationsLabel: `Upozornění na iOS pomocí stlačení`,
  roomRack: `Pokoje`,
  newSlot: `Nový slot`,
  searchByRoomNumber: `Číslo pokoje`,
  reservation: `Rezervace`,
  outOfOrder: `Out of Order`,
  outOfService: `Out of Service`,
  outOfInventory: `Out of Inventory`,
  slotType: `Typ slotu`,
  selectType: `Vyberte typ`,
  reason: `Důvod`,
  selectReason: `Vyberte důvod`,
  selectDateAndTime: `Datum a čas`,
  startDate: `Datum zahájení`,
  startTime: `Čas zahájení`,
  endDate: `Datum ukončení`,
  endTime: `Konec času`,
  room: `Pokoj`,
  selectRoom: `Vyberte pokoj`,
  cleanAfterBlock: `Čištění nutné po slotu`,
  addSlot: `Přidat slot`,
  updateSlot: `Aktualizovat slot`,
  errorStartDatePastSlotForm: `Datum zahájení nemůže být v minulosti.`,
  errorEndDatePastSlotForm: `Datum konce nemůže být před datem začátku`,
  errorEndTimePastSlotForm: `Čas ukončení nemůže být před časem začátku`,
  errorMinDurationSlotForm: `Minimální délka slotu je 30 minut`,
  errorOverbookedSlotForm: `Přiřazení může vést k přeplnění vybranými dnem(i). Vytvořte jej pouze pokud je skutečně nutné, v opačném případě zvolte jiné časové období.`,
  outOfOrderInfo: `OOO - Out of Order. Mělo by být použito, když je pokoj nefunkční a nelze jej využít hostem.`,
  outOfServiceInfo: `OOS - Out of Service. Umožňuje prodat pokoj. Pokoj označený jako Out of Service je plně funkční a má pouze drobné kosmetické vady.`,
  outOfInventoryInfo: `OOI - Out of Inventory. Používá tým Launch pro dlouhodobé plány údržby, jako je renovace celého patra.`,
  maintenanceReason: `Údržba`,
  cleaningIssueReason: `Problém s čištěním`,
  roomMoveBlockReason: `Přesun pokoje/hotelu`,
  bedBugsReason: `Chyby v posteli`,
  smokingReason: `Kouření`,
  vipGuestReason: `Host VIPu`,
  pmpReason: `PMP`,
  rampUpSruReason: `Ramp-up: strategický`,
  rampUpRnrReason: `Ramp-up: pokoj není připraven`,
  hoReason: `Optimalizace HSK`,
  othersReason: `Jiný důvod`,
  createdInApaleo: `Vytvořeno v Apaleo`,
  reasonNotApaleo: `Vytvořeno v Apaleo není platný důvod. Vyberte prosím jiný.`,
  quarantineReason: `Karanténa`,
  slotLog: `Slot log`,
  deleteSlot: `Odstranit slot`,
  deleteSlotLabel: `Prosím uveďte důvod smazání`,
  errorDeleteSlot: `Nemůžete odstranit slot.`,
  errorDeleteInfoSlot: `Probíhající slot nelze odstranit, místo toho jej lze zkrátit.`,
  fieldRequired: `{{field}}: pole je povinné`,
  from: `od `,
  until: `do `,
  issueResolveDeleteReason: `Problém byl vyřešen / slot není potřeba`,
  createdByMistakeDeleteReason: `Slot vytvořen omylem`,
  guestRejectedMoveDeleteReason: `Host odmítl přesunout se do jiného pokoje`,
  guestAcceptedMoveDeleteReason: `Host přijal přesun pokoje`,
  deleteReasonIsRequired: `Důvod odstranění je povinný`,
  editSlot: `Upravit slot`,
  earlyCheckinByHour: `ECI - uklizeno do {{hour}}:00`,
  paidEcis: `Placené ECIs v {{hour}}:00`,
  invalidTimeFormat: `Formát pole {{field}} je neplatný`,
  errorPastDateEdit: `Datum zahájení nelze změnit, pokud je v minulosti.`,
  operationsData: `Operační data`,
  vacantRooms: `Volné pokoje`,
  extraServices: `Dodatečné služby`,
  memberSnacks: `Člen občerstvení`,
  oooRooms: `Pokoje OOO`,
};
