import Button from '@atoms/Button';
import { XIcon } from '@heroicons/react/solid';
import Dialog from '@molecules/Dialog';

type ErrorDialogProps = {
  title: string;
  error: string;
  buttonLabel: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ErrorDialog = ({ title, error, buttonLabel, isOpen, onClose }: ErrorDialogProps) => {
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      isMobileSheet
      customHeader={
        <div
          className="w-full flex justify-between items-center px-4 pb-4 pt-2 
        border-b border-b-[#DEDEDE]"
        >
          <button
            type="button"
            className="bg-transparent rounded-md text-black hover:text-gray-500 focus:outline-none"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="text-base font-bold font-serif">{title}</div>
          <button className="text-[#8B9DA5] text-base font-normal font-serif"></button>
        </div>
      }
    >
      <div className="flex flex-col items-center px-3 w-full md:w-72 pt-6">
        <div className={'text-th-secondary text-center mb-6 font-semibold'}>{error}</div>
        <Button className={'w-full'} onClick={onClose}>
          {buttonLabel}
        </Button>
      </div>
    </Dialog>
  );
};
