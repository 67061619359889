import icons from '@constants/icons';
import { ChevronDoubleRightIcon, ClockIcon } from '@heroicons/react/outline';

import { SlotOption, SlotType, SlotValue } from './types';
import { allMaintenanceSlotReasons, SlotItem } from './RoomRackTable/types';
import { RoomRackType, ReservationStatus, SVGIconType } from '@typings/types';
import { MaintenanceSlotStatusKey, ReservationStatusKey, MaintenanceSlotReasonKey } from '@typings/enums';

export const reservationStatusSlot: {
  icon: SVGIconType;
  value: ReservationStatus;
}[] = [
  { icon: icons.confirmed, value: ReservationStatusKey.CONFIRMED },
  { icon: icons.inHouse, value: ReservationStatusKey.IN_HOUSE },
  { icon: icons.noShow, value: ReservationStatusKey.NO_SHOW },
  { icon: icons.checkedOut, value: ReservationStatusKey.CHECKED_OUT },
];

export const maintenanceReasonsSlot: {
  icon: SVGIconType;
  translationKey: string;
  value: MaintenanceSlotReasonKey;
}[] = [
  { icon: icons.maintenance, translationKey: 'maintenanceReason', value: MaintenanceSlotReasonKey.MNT },
  { icon: icons.cleaningIssue, translationKey: 'cleaningIssueReason', value: MaintenanceSlotReasonKey.CL },
  { icon: icons.roomMoveBlock, translationKey: 'roomMoveBlockReason', value: MaintenanceSlotReasonKey.RM },
  { icon: icons.bedBugs, translationKey: 'bedBugsReason', value: MaintenanceSlotReasonKey.BB },
  { icon: icons.smoking, translationKey: 'smokingReason', value: MaintenanceSlotReasonKey.SMK },
  { icon: icons.vipGuest, translationKey: 'vipGuestReason', value: MaintenanceSlotReasonKey.VIP },
  { icon: icons.pmp, translationKey: 'pmpReason', value: MaintenanceSlotReasonKey.PMP },
  { icon: ClockIcon, translationKey: 'rampUpRnrReason', value: MaintenanceSlotReasonKey.RURNR },
  { icon: icons.rampUp, translationKey: 'rampUpSruReason', value: MaintenanceSlotReasonKey.RUSRU },
  { icon: ChevronDoubleRightIcon, translationKey: 'hoReason', value: MaintenanceSlotReasonKey.HO },
  { icon: icons.other, translationKey: 'othersReason', value: MaintenanceSlotReasonKey.OTHER },
];

export const reservationStatusOptions: SlotOption[] = [
  {
    label: 'Confirmed',
    type: SlotType.RESERVATION,
    value: ReservationStatusKey.CONFIRMED,
  },
  {
    label: 'No show',
    type: SlotType.RESERVATION,
    value: ReservationStatusKey.NO_SHOW,
  },
  {
    label: 'In house',
    type: SlotType.RESERVATION,
    value: ReservationStatusKey.IN_HOUSE,
  },
  {
    label: 'Checked out',
    type: SlotType.RESERVATION,
    value: ReservationStatusKey.CHECKED_OUT,
  },
];

export const maintenanceSlotOptions: SlotOption[] = [
  {
    label: 'Out of order',
    type: SlotType.MAINTENANCE,
    value: MaintenanceSlotStatusKey.OUT_OF_ORDER,
  },
  {
    label: 'Out of service',
    type: SlotType.MAINTENANCE,
    value: MaintenanceSlotStatusKey.OUT_OF_SERVICE,
  },
  {
    label: 'Out of inventory',
    type: SlotType.MAINTENANCE,
    value: MaintenanceSlotStatusKey.OUT_OF_INVENTORY,
  },
];

export const slotOptions: SlotOption[] = [...reservationStatusOptions, ...maintenanceSlotOptions];

const defaultSlotStyle: React.CSSProperties = {
  border: '0px solid',
  color: 'black',
  fontFamily: 'Proxima-Nova, sans',
  borderRadius: '4px',
};

export const slotColor: Record<SlotValue, React.CSSProperties> = {
  [ReservationStatusKey.CONFIRMED]: { background: '#F9FAFB', border: '1px solid #9E9E9E', color: '#333333' },
  [ReservationStatusKey.NO_SHOW]: { background: '#F9FAFB', border: '1px solid #9E9E9E', color: '#333333' },
  [ReservationStatusKey.IN_HOUSE]: { background: '#4A4A49', border: '1px solid transparent', color: '#F9FAFB' },
  [ReservationStatusKey.CHECKED_OUT]: { background: '#CECECE', border: '1px solid transparent', color: '#333333' },
  [MaintenanceSlotStatusKey.OUT_OF_ORDER]: {
    background: '#EF8B69',
    border: '1px solid transparent',
    color: '#333333',
  },
  [MaintenanceSlotStatusKey.OUT_OF_SERVICE]: {
    background: '#6794F3',
    border: '1px solid transparent',
    color: '#333333',
  },
  [MaintenanceSlotStatusKey.OUT_OF_INVENTORY]: {
    background: '#66AE7E',
    border: '1px solid transparent',
    color: '#333333',
  },
};

export const formatSlotsData = (roomRack: RoomRackType) => {
  const slotData: SlotItem[] = [];
  for (const { unitId, unitNumber, maintenances, reservations } of roomRack) {
    for (const { id, checkIn, checkOut, externalId, status } of reservations) {
      slotData.push({
        id: `${id}-${unitNumber}-${checkIn.getTime()}-${checkOut.getTime()}-reservation`,
        group: unitId,
        roomNumber: unitNumber,
        title: externalId,
        externalId: externalId,
        start_time: checkIn.getTime(),
        end_time: checkOut.getTime(),
        status: status,
        stackOrder: 0,
        type: SlotType.RESERVATION,
        itemProps: { style: { ...defaultSlotStyle, ...slotColor[status] } },
      });
    }
    for (const { id, from, to, type, description, endWithCleaning, reason, isDeleted } of maintenances) {
      if (!isDeleted) {
        slotData.push({
          id: id,
          group: unitId,
          roomNumber: unitNumber,
          title: reason,
          start_time: from.getTime(),
          end_time: to.getTime(),
          stackOrder: 1,
          status: type,
          type: SlotType.MAINTENANCE,
          reason: reason as allMaintenanceSlotReasons,
          cleanAfterBlock: endWithCleaning,
          description: description ?? undefined,
          itemProps: { style: { ...defaultSlotStyle, ...slotColor[type] } },
        });
      }
    }
  }
  return slotData;
};
