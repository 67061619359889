import { useMemo } from 'react';
import Loader from '@molecules/Loader';
import type { Id } from 'react-calendar-timeline';
import { RoomRackTable } from './RoomRackTable';

import { DateRangeType, SlotItem } from './RoomRackTable/types';

type RoomRackTableSectionProps = {
  roomsData: { id: string; title: string }[] | null;
  slotData: SlotItem[] | null;
  dateRange: DateRangeType;
  onSlotClick: (itemId: Id) => void;
  isFetching: boolean;
};

export const RoomRackTableSection = ({
  roomsData,
  slotData,
  dateRange,
  onSlotClick,
  isFetching,
}: RoomRackTableSectionProps) => {
  const componentKey = useMemo(() => `room-rack-${dateRange.start}-${dateRange.end}`, [dateRange.start, dateRange.end]);

  if (!roomsData || !slotData) return null;

  return (
    <div className="relative h-auto overflow-auto">
      {isFetching && (
        <div className="absolute z-50 flex justify-center items-center w-full h-full">
          <Loader />
        </div>
      )}
      <RoomRackTable
        key={componentKey}
        onSlotClick={onSlotClick}
        dateRange={dateRange}
        slotsData={slotData}
        roomsData={roomsData}
      />
    </div>
  );
};
