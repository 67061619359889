import usePropertiesState from '@context/propertiesContext';
import UnitDetails from '@organisms/UnitDetails';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSearchParam from '@utils/hooks/useSearchParam';
import { TaskType } from '@typings/enums';

export default function UnitView() {
  const { t } = useTranslation();
  const { selectedProperty } = usePropertiesState();

  const location = useLocation();
  const [propertyId] = useSearchParam({ key: 'propertyId' });
  const [taskId] = useSearchParam({ key: 'taskId' });
  const [taskType] = useSearchParam({ key: 'taskType', defaultValue: TaskType.STANDARD });

  const { id: unitId } = useParams<{ id: string }>();

  if (!unitId || !propertyId) {
    return <div>{t('anErrorHasOccurred')}</div>;
  }

  const { expandTasks, expandDamageReports, openCreationModal } = location.state ?? {};

  const shouldExpandTasks = expandTasks || (!!taskId && taskType === TaskType.STANDARD);
  const shouldExpandDamageReports = expandDamageReports || (!!taskId && taskType === TaskType.DAMAGE);

  return (
    <UnitDetails
      unitId={unitId}
      selectedProperty={selectedProperty}
      expandTasks={shouldExpandTasks}
      expandDamageReports={shouldExpandDamageReports}
      openCreationModal={openCreationModal}
    />
  );
}
