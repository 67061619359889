import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TaskInfoType } from '@typings/types';
import { TaskType } from '@typings/enums';

import icons from '@constants/icons';
import { CameraIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';

import Dialog from '@molecules/Dialog';
import IconWithText from '@molecules/IconWithText';
import useTaskActionsState from '@context/taskActionsContext';
import { PicturesKitchenUseModal } from './PicturesKitchenUseModal';
import MarkdownRenderer from '@atoms/MarkdownRenderer';
import usePropertiesState from '@context/propertiesContext';
import { getDefaultFormValues } from '@organisms/CreateTask/utils';
import useCreateOrUpdateTask, { StandardTaskFormValues } from '@utils/hooks/useCreateOrUpdateTask';
import useImageUrls from '@utils/hooks/useImageUrls';
import { useGetOneTask, useGetUnitDetails } from '@api/api';
import useToggleCompleteTask from '@utils/hooks/useToggleCompleteTask';
import { checkImagesForBlur } from '@utils/detectBlurImage';
import { PicturesErrorBlurryKitchenUseModal } from './PicturesErrorBlurryKitchenUseModal';

type KitchenUseTaskModalProps = {
  unitId: string;
  onClose: () => void;
  startCleaning?: () => void;
  saveButtonLabel: string;
  kitchenUseTask: TaskInfoType;
};

export default function KitchenUseTaskModal({
  unitId,
  onClose,
  startCleaning,
  saveButtonLabel,
  kitchenUseTask,
}: KitchenUseTaskModalProps) {
  const { t } = useTranslation();
  const { getTaskActionById } = useTaskActionsState();
  const toggleCompleteMutation = useToggleCompleteTask({ onSuccess: onClose });

  const { selectedProperty } = usePropertiesState();
  const { images: initialTaskImages, pickerInitialImages } = useImageUrls(kitchenUseTask?.imageUrls);

  const [images, setImages] = useState<{ [key: string]: File }>({});
  const [isImageBlurryError, setIsImageBlurryError] = useState<boolean>(false);
  const [isPictureKitchenUseModalOpen, setIsPictureKitchenUseModalOpen] = useState<boolean>(false);

  const { data: task } = useGetOneTask({ id: kitchenUseTask.id, propertyId: kitchenUseTask.propertyId });

  const kitchenUseTaskAction = useMemo(() => {
    if (kitchenUseTask && kitchenUseTask.actionId) {
      const taskActionDetails = getTaskActionById(kitchenUseTask.actionId);
      if (taskActionDetails) return taskActionDetails;
    }

    return undefined;
  }, [kitchenUseTask]);

  const { data: unit } = useGetUnitDetails({
    propertyId: kitchenUseTask.propertyId,
    unitId,
    timeZone: selectedProperty?.timeZone,
  });

  useEffect(() => {
    setImages({ ...pickerInitialImages });
  }, [pickerInitialImages]);

  const defaultValues = getDefaultFormValues({
    property: selectedProperty,
    editTask: task ?? undefined,
    images: initialTaskImages,
    apaleoReservationId: unit?.reservation?.externalId,
    unit,
  });

  const form = useForm<StandardTaskFormValues>({
    mode: 'onChange',
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'images',
  });

  const ref = useRef<HTMLInputElement | null>(null);

  const onUpload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    ref.current?.click();
  };

  const onRemoveFile = useCallback(
    (url: string) => {
      const newImages = { ...images };
      const fileName = newImages[url].name;
      delete newImages[url];
      setImages(newImages);
      const index = fields.findIndex((f) => f.image.name === fileName);
      remove(index);
    },
    [images, fields],
  );

  const updateImagesState = (files: File[]): void => {
    append(files.map((file) => ({ image: file })));
    setImages((prevImages) => ({
      ...prevImages,
      ...Object.fromEntries(files.map((file) => [URL.createObjectURL(file), file])),
    }));
  };

  const onChangeFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files ? Array.from(e.target.files) : ([] as File[]);
      if (files.length === 0) return;

      try {
        const isAnyImageBlurry = await checkImagesForBlur(files);

        if (isAnyImageBlurry) {
          setIsImageBlurryError(true);
        } else {
          updateImagesState(files);
          setIsImageBlurryError(false);
        }

        setIsPictureKitchenUseModalOpen(true);
      } catch (error) {
        updateImagesState(files);
        setIsPictureKitchenUseModalOpen(true);
        console.error('Error processing images:', error);
      }
    },
    [images, append, setImages, setIsImageBlurryError, setIsPictureKitchenUseModalOpen],
  );

  const toggleComplete = useCallback(() => {
    if (!toggleCompleteMutation.isLoading) {
      toggleCompleteMutation.mutate({ task: kitchenUseTask });
    }
  }, [toggleCompleteMutation, task]);

  const property = useWatch({
    control: form.control,
    name: 'property',
  });

  const { isSubmitting, onSubmitStandardTask } = useCreateOrUpdateTask({
    property,
    editTask: task ?? undefined,
    type: TaskType.STANDARD,
  });

  const onSubmit = (data: StandardTaskFormValues) => {
    onSubmitStandardTask(data, kitchenUseTaskAction);
    toggleComplete();
    startCleaning?.();
  };

  const renderKitchenUseDialogContent = useCallback(() => {
    return (
      <div>
        {kitchenUseTaskAction?.description && (
          <div className="bg-th-gray-100 p-4 font-sans text-base font-normal flex justify-center items-start gap-2 max-w-md">
            <InformationCircleIcon className="w-6 h-6" />
            <div className="flex-1">
              <MarkdownRenderer markdown={kitchenUseTaskAction?.description}></MarkdownRenderer>
            </div>
          </div>
        )}
        <div className="flex flex-col justify-center items-center gap-2 p-4">
          <button
            onClick={onUpload}
            className={`w-full py-2 text-white text-base font-normal font-sans rounded-md
              bg-th-secondary flex justify-center items-center`}
          >
            <input
              ref={ref}
              accept="image/*"
              id="file-input"
              name="file-input"
              type="file"
              capture="environment"
              hidden
              multiple
              onChange={onChangeFile}
            />
            <CameraIcon className="w-6 h-6 mr-[6px] text-th-light-green" />
            <span>{t('takePhoto')}</span>
          </button>
        </div>
      </div>
    );
  }, [kitchenUseTaskAction?.description]);

  if (isPictureKitchenUseModalOpen) {
    return (
      <>
        <PicturesKitchenUseModal
          form={form}
          images={images}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          saveButtonLabel={saveButtonLabel}
          onRemoveFile={onRemoveFile}
          onChangeFile={onChangeFile}
          onClose={() => setIsPictureKitchenUseModalOpen(false)}
          description={kitchenUseTaskAction?.description}
        />
        <PicturesErrorBlurryKitchenUseModal
          isImageBlurry={isImageBlurryError}
          onClose={() => setIsImageBlurryError(false)}
          onChangeFile={onChangeFile}
        />
      </>
    );
  }

  return (
    <Dialog
      isOpen
      onClose={onClose}
      customHeader={
        <div className="w-full flex md:flex-row-reverse justify-between items-center px-4 pt-4 md:pt-3 pb-3 border-b border-b-[#DEDEDE]">
          <button
            type="button"
            className="bg-transparent rounded-md text-black hover:text-gray-500 focus:outline-none"
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div
            className={'flex flex-row items-center justify-end md:justify-start space-x-2 pl-4 md:pl-0 flex-1 md:w-80'}
          >
            <IconWithText Icon={icons.door} text={kitchenUseTask?.unit?.number} large />
          </div>
        </div>
      }
      isMobileSheet
    >
      <>{renderKitchenUseDialogContent()}</>
    </Dialog>
  );
}
