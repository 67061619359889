import { motion, useAnimationControls } from 'framer-motion';
import { useState, useEffect, useRef } from 'react';

export const AnimateSectionExpand = ({ children, isOpen }: { children: React.ReactNode; isOpen: boolean }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const controls = useAnimationControls();
  const animationState = useRef<'entering' | 'idle' | 'exiting'>('idle');
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      controls.set({
        height: isOpen ? 'auto' : 0,
        opacity: isOpen ? 1 : 0,
      });
      isFirstRender.current = false;
      return;
    }

    setIsAnimating(true);
    animationState.current = isOpen ? 'entering' : 'exiting';
    controls.start({
      height: isOpen ? 'auto' : 0,
      opacity: isOpen ? 1 : 0,
    });
  }, [controls, isOpen]);

  return (
    <div>
      <motion.div
        initial={isFirstRender.current ? false : { height: 0, opacity: 0 }}
        animate={controls}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        onAnimationStart={() => {
          setIsAnimating(true);
        }}
        onAnimationComplete={() => {
          setIsAnimating(false);
          animationState.current = 'idle';
        }}
        style={{
          pointerEvents: isOpen ? 'auto' : 'none', // Disable pointer events when closed
        }}
        className={isAnimating ? 'overflow-hidden' : 'overflow-visible'}
      >
        {children}
      </motion.div>
    </div>
  );
};
