import cn from 'classnames';
import Dropdown from '@molecules/Dropdown';
import { WeeklyViewData, WeeklyViewDateSummary } from '@typings/types';
import { UseMutationResult } from 'react-query';
import { LATEST_ALLOWED_CHECKOUT_OPTIONS } from '@organisms/WeeklyView/constants';

interface LcoTimeDropdownProps {
  dateData: WeeklyViewDateSummary;
  propertyId: string;
  allowEdit?: boolean;
  saveDaySettingsMutation: UseMutationResult<
    WeeklyViewData,
    unknown,
    {
      date: Date;
      approvedLateCheckouts: number;
      latestAllowedCheckout: string;
      propertyId: string;
    },
    unknown
  >;
}

export const LcoTimeDropdown = ({ dateData, propertyId, allowEdit, saveDaySettingsMutation }: LcoTimeDropdownProps) => {
  const approvedLcoLimit = dateData.approvedLcoLimit;

  if (!approvedLcoLimit) {
    return <span className={'font-bold px-1 rounded bg-gray-200 cursor-not-allowed w-10'}>{'-'}</span>;
  }
  if (!allowEdit) {
    return <span>{approvedLcoLimit > 0 ? dateData.maxLcoTime : '-'}</span>;
  }

  const isDefault = dateData.maxLcoTime === dateData.defaultMaxLcoTime;
  return (
    <Dropdown
      value={dateData.maxLcoTime}
      items={LATEST_ALLOWED_CHECKOUT_OPTIONS}
      onChange={(value) => {
        saveDaySettingsMutation.mutate({
          date: dateData.date,
          approvedLateCheckouts: approvedLcoLimit,
          latestAllowedCheckout: value as string,
          propertyId,
        });
      }}
      className={cn('font-bold px-1 rounded', isDefault ? 'bg-gray-200' : 'bg-yellow-300')}
      relativity={false}
    />
  );
};
