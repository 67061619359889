import useApiCall from '@utils/hooks/useApiCall';
import unitsApi from '@api/unitsApi';
import { useMutation, useQueryClient } from 'react-query';
import { useCallback, useState } from 'react';
import { UnitDetailsInfoType, UnitInfoType } from '@typings/types';
import Dialog from '@molecules/Dialog';
import Button, { ButtonType } from '@atoms/Button';
import { useTranslation } from 'react-i18next';
import { NotificationType, ServerStateKey } from '@typings/enums';
import useNotifications from '@utils/hooks/useNotifications';

interface Params {
  onSuccess?: () => void;
}

function useNoShowReport({ onSuccess }: Params) {
  const [unit, setUnit] = useState<UnitDetailsInfoType | UnitInfoType>();
  const [answer, setAnswer] = useState<boolean>();

  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { showNotification } = useNotifications();

  const updateUnitInDailyView = useCallback(
    (u: UnitDetailsInfoType | UnitInfoType | undefined, guestCheckedIn: boolean) => {
      if (!u) return;
      const stateKey = [ServerStateKey.DAILY_VIEW_UNITS, u.propertyId];
      if (guestCheckedIn) {
        queryClient.invalidateQueries(stateKey);
      } else {
        queryClient.setQueryData(stateKey, (oldData?: UnitInfoType[]) =>
          oldData
            ? oldData.map((uu) =>
                uu.id === u.id
                  ? {
                      ...uu,
                      noShowReported: true,
                    }
                  : uu,
              )
            : [],
        );
      }
    },
    [],
  );

  const onCloseConfirmationDialog = useCallback(() => {
    setUnit(undefined);
    setAnswer(undefined);
  }, []);

  const noShowReportRequest = useApiCall<void>(unitsApi.noShowReport);
  const noShowReportMutation = useMutation(
    (v: { propertyId: string; id: string; guestCheckedIn: boolean }) =>
      noShowReportRequest({
        params: { propertyId: v.propertyId, id: v.id },
        body: { guestCheckedIn: v.guestCheckedIn },
      }),
    {
      onSuccess: (data, variables) => {
        if (variables.guestCheckedIn) {
          showNotification(
            t('popupNotifications.checkInReportNotification', { number: unit?.number }),
            NotificationType.SUCCESS,
          );
        } else {
          showNotification(
            t('popupNotifications.noShowReportNotification', { number: unit?.number }),
            NotificationType.SUCCESS,
          );
        }
        updateUnitInDailyView(unit, variables.guestCheckedIn);
        onCloseConfirmationDialog();
        onSuccess?.();
      },
    },
  );

  const noShowReport = useCallback(
    (u: UnitDetailsInfoType | UnitInfoType, guestCheckedIn: boolean) => {
      if (noShowReportMutation.isLoading) {
        return;
      }
      noShowReportMutation.mutate({
        id: u?.id,
        propertyId: u?.propertyId ?? (u as UnitDetailsInfoType)?.property.id,
        guestCheckedIn,
      });
    },
    [noShowReportMutation],
  );

  const renderNoShowReportQuestionDialog = useCallback(() => {
    return (
      <Dialog isOpen={!!unit && answer === undefined} onClose={() => setUnit(undefined)} isMobileSheet withoutHeader>
        <div className={'flex flex-col items-center px-3'}>
          <div className={'text-th-secondary text-center mb-6'}>
            {t('noShowReportQuestion', { number: unit?.number })}
          </div>
          <Button type={ButtonType.SECONDARY} className={'w-full'} onClick={() => setAnswer(true)}>
            {t('yes')}
          </Button>
          <Button type={ButtonType.SECONDARY} className={'my-1 py-2 w-full'} onClick={() => setAnswer(false)}>
            {t('no')}
          </Button>
        </div>
      </Dialog>
    );
  }, [noShowReportMutation, unit, answer]);

  const renderNoShowReportConfirmationDialog = useCallback(() => {
    return (
      <Dialog isOpen={answer !== undefined} onClose={onCloseConfirmationDialog} isMobileSheet withoutHeader>
        <div className={'flex flex-col items-center px-3'}>
          <div className={'text-th-secondary text-center mb-6'}>
            {t(answer ? 'checkInReportConfirmationMessage' : 'noShowReportConfirmationMessage', {
              number: unit?.number,
            })}
          </div>
          <Button
            isLoading={noShowReportMutation.isLoading}
            className={'w-full'}
            onClick={() => unit && noShowReport(unit, !!answer)}
          >
            {t('confirm')}
          </Button>
          <Button
            type={ButtonType.SECONDARY}
            className={'my-1 py-2 w-full text-gray-400'}
            onClick={onCloseConfirmationDialog}
          >
            {t('cancel')}
          </Button>
        </div>
      </Dialog>
    );
  }, [noShowReportMutation, unit, noShowReport, answer]);

  return {
    onClickNoShowReport: setUnit,
    renderNoShowReportQuestionDialog,
    renderNoShowReportConfirmationDialog,
  };
}

export default useNoShowReport;
