import { MaintenanceSlotStatus, ReservationStatus } from '@typings/types';

export enum SlotType {
  RESERVATION = 'RESERVATION',
  MAINTENANCE = 'MAINTENANCE',
}

export type SlotValue = ReservationStatus | MaintenanceSlotStatus;

export interface SlotOption {
  label: string;
  type: SlotType.RESERVATION | SlotType.MAINTENANCE;
  value: SlotValue;
}

export enum SlotDialogueRoomRackParamValues {
  OPEN = 'open',
  CLOSED = 'closed',
}
