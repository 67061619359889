import { WeeklyViewDateSummary, WeeklyViewDateSummaryUnitgroups } from '@typings/types';
import { isToday } from 'date-fns';

export const extractUniqueUnitGroups = (
  data: WeeklyViewDateSummary[],
  type: 'arrivalsUnitGroups' | 'departuresUnitGroups' | 'midstaysUnitGroups',
): { id: string; name: string }[] => {
  const uniqueMap = new Map<string, { id: string; name: string }>();

  data.forEach((item) => {
    if (item[type] && item[type].length > 0) {
      item[type].forEach((unitGroup) => {
        if (unitGroup.id && unitGroup.name) {
          uniqueMap.set(unitGroup.id, {
            id: unitGroup.id,
            name: unitGroup.name,
          });
        }
      });
    }
  });

  return Array.from(uniqueMap.values());
};

export const getAllRowsCount = (
  propertiesData: WeeklyViewDateSummary[],
  rows: Array<keyof Omit<WeeklyViewDateSummary, keyof WeeklyViewDateSummaryUnitgroups>>,
) => {
  const allRowsCount = {} as Record<keyof WeeklyViewDateSummary, number>;

  for (const row of rows) {
    allRowsCount[row] = 0;
  }

  for (const property of propertiesData) {
    for (const subject of rows) {
      const value = property[subject];
      allRowsCount[subject] += Array.isArray(value) ? value.length : (value as number);
    }
  }

  return allRowsCount;
};

export const countUnitGroupTotal = (
  propertiesData: WeeklyViewDateSummary[],
  subject: 'arrivalsUnitGroups' | 'departuresUnitGroups' | 'midstaysUnitGroups',
  subSubject: string,
) => {
  return propertiesData.reduce((acc, curr) => {
    const value = curr[subject].find((d) => d.id === subSubject)?.value ?? 0;
    return acc + (Array.isArray(value) ? value.length : value);
  }, 0);
};

export const isTodayStyle = (date: Date) => (isToday(new Date(date)) ? 'bg-[#E7EFF2]' : '');
const isTodayUnitgroupStyle = (date: Date) => (isToday(new Date(date)) ? 'bg-[#C1DFD6]' : 'bg-[#EFF2F5]');
const rowCellStyle = `border-b-[#EFF2F5]`;
const rowCellUnitGroupStyle = `border-b-[#CECECE]`;
const nonClickableCellStyle = `!text-th-brown-300`;

export const getCellMainStyle = (date: Date, isClickable = true) => {
  const dateStyle = isTodayStyle(date);
  if (!isClickable) {
    return `${rowCellStyle} ${nonClickableCellStyle} ${dateStyle}`;
  }
  return `${rowCellStyle} ${dateStyle}`;
};

export const getCellUnitGroupStyle = (date: Date) => {
  const dateStyle = isTodayUnitgroupStyle(date);
  return `${rowCellUnitGroupStyle} ${nonClickableCellStyle} ${dateStyle}`;
};
