import {
  DamageReportActionRequired,
  DamageReportArea,
  StandardizedDamageReport,
  TaskPriority,
  Team,
} from '@typings/enums';
import { CleaningInterval } from '@typings/types';

const standardizedDamageReports = {
  [StandardizedDamageReport.BATHROOM_FAN_LOUD]: 'Ventilator de baie zgomotos',
  [StandardizedDamageReport.LIGHT_NOT_WORKING]: 'Lumina / lampa nu funcționează',
  [StandardizedDamageReport.SINK_BLOCKED]: 'Chiuveta este blocată',
  [StandardizedDamageReport.SHOWERDRAIN_BLOCKED]: 'Canalul de duș este blocat',
  [StandardizedDamageReport.BATHROOM_FILTER]: 'Filtru de baie',
  [StandardizedDamageReport.SINK]: 'Chiuvetă',
  [StandardizedDamageReport.TOILET]: 'Toaletă',
  [StandardizedDamageReport.SHOWER]: 'Duș',
  [StandardizedDamageReport.STOVE]: 'Cuptor',
  [StandardizedDamageReport.FRIDGE_MINIBAR]: 'Frigider/Minibar',
  [StandardizedDamageReport.DOOR]: 'Uşă',
  [StandardizedDamageReport.WINDOW]: 'Fereastră',
  [StandardizedDamageReport.AC]: 'AC / Aer condiționat',
  [StandardizedDamageReport.WIFI]: 'Wifi',
  [StandardizedDamageReport.LIGHTBULB]: 'Bec',
  [StandardizedDamageReport.CHAIRS]: 'Scaune',
  [StandardizedDamageReport.HEATING]: 'Incalzire',
  [StandardizedDamageReport.TV]: 'Televizor',
  [StandardizedDamageReport.TABLES]: 'Mese',
  [StandardizedDamageReport.CHROMECAST]: 'Chromecast',
};

const teamNames = {
  [Team.HOUSE_KEEPING]: 'Echipa HSK',
  [Team.MAINTENANCE]: 'Echipa de întreținere',
  [Team.OPERATIONS]: 'Echipa de operațiuni',
  [Team.ICT]: 'ICT',
  [Team.GX]: 'GX',
};

const damageReportAreas = {
  [DamageReportArea.KITCHEN]: 'Bucătărie',
  [DamageReportArea.BED]: 'Pat',
  [DamageReportArea.BATHROOM]: 'Baie',
  [DamageReportArea.SEATING_AREA]: 'Zona de relaxare',
  [DamageReportArea.WALLS_CEILING_FLOOR]: 'Pereți/Tavan/Pardoseală',
  [DamageReportArea.ICT]: 'TIC',
};

const damageReportActionsRequired = {
  [DamageReportActionRequired.CLEAN]: 'Curat',
  [DamageReportActionRequired.REPLACE]: 'A inlocui',
  [DamageReportActionRequired.REPAIR]: 'Reparație',
  [DamageReportActionRequired.CHECK]: 'Verifica',
  [DamageReportActionRequired.OTHER]: 'Alte',
};

const taskPriorities = {
  [TaskPriority.LOW]: 'Scăzut',
  [TaskPriority.HIGH]: 'Înalt',
};

const popupNotifications = {
  taskDeletionNotification: 'Sarcina a fost eliminată cu succes',
  lostAndFoundDeletionNotification: 'Elementul pierdut și găsit a fost eliminat cu succes',
  damageReportDeletionNotification: 'Raportul de daune a fost șters cu succes',
  taskCreationNotification: 'Sarcina a fost creată cu succes',
  lostAndFoundCreationNotification: 'Elementul pierdut și găsit a fost creat cu succes',
  damageReportCreationNotification: 'Raportul de daune a fost creat cu succes',
  taskCompletionNotification: 'Sarcina a fost finalizată cu succes',
  lostAndFoundCompletionNotification: 'Elementul pierdut și găsit a fost rezolvat cu succes',
  damageReportCompletionNotification: 'Raportul de daune a fost rezolvat cu succes',
  taskUpdateNotification: 'Sarcina a fost actualizată cu succes',
  lostAndFoundUpdateNotification: 'Elementul pierdut și găsit a fost actualizat cu succes',
  damageReportUpdateNotification: 'Raportul de daune a fost actualizat cu succes',
  pleaseSelectRoomsNotification: 'Vă rugăm să selectați camera',
  noShowReportNotification: 'Neapariția a fost raportată cu succes pentru camera {{ number }}',
  checkInReportNotification: 'Check-In a fost raportat cu succes pentru camera {{number}}',
  damageReportWorkingTimeScheduledNotification: 'Raportul de daune programat cu succes pentru {{date}}',
  hskDelayNotification:
    'Întârzierea de HSK reușită pentru camera {{number}}. Ora de check-in este actualizată la {{time}}',
};

const cleaningInterval = {
  next: 'Next cleaning',
  interval: 'Cleaning interval',
  timeRange: 'Time range',
  lastCleaning: 'Last cleaning',
  stayoverCleaning: 'Stayover cleaning',
  [CleaningInterval.WEEKLY_OR_END]: 'Weekly or end of stay',
  [CleaningInterval.BIWEEKLY]: 'Biweekly',
  [CleaningInterval.DAILY]: 'Daily',
  [CleaningInterval.NONE]: 'None',
  [CleaningInterval.EVERY_4_DAYS]: 'Every 4 days',
};

export default {
  ...teamNames,
  standardizedDamageReports,
  damageReportAreas,
  damageReportActionsRequired,
  taskPriorities,
  popupNotifications,
  cleaningInterval,
  resetAllFilters: 'Resetați toate filtrele',
  moreFilters: 'Mai multe filtre',
  reset: 'Resetare',
  allRoomsCleaned: `Toate camerele curățate`,
  cleaned: 'Curățate',
  dirty: 'Murdar',
  readyToClean: 'Pregatit de curățat',
  stayoverReadyToClean: 'Stayover - Gata de curățat',
  occupiedCleaningToday: 'Ocupat - Curăţenie astăzi',
  readyToInspect: 'Gata de inspectat',
  stayoverInspection: 'Inspecție de ședere',
  vacant: 'Vacant',
  occupied: 'Ocupat',
  clean: 'Curat',
  daily: 'Zilnic',
  weekly: 'Săptămânal',
  biweekly: 'Bi-săptămânal',
  monthly: 'Lunar',
  none: 'Niciunul',
  tasks: 'Sarcini',
  notifications: 'Notificări',
  more: 'Mai mult',
  select: 'Selectați',
  property: 'Proprietate',
  result: 'Rezultat',
  results: 'Rezultate',
  properties: 'Proprietăți',
  rooms: 'Camere',
  team: 'Echipă',
  dueDate: 'Data scadentă',
  date: 'Data',
  close: 'Închide',
  search: 'Căutare',
  login: 'Autentificare',
  loading: 'Se încarcă...',
  anErrorHasOccurred: 'A avut loc o eroare',
  chooseDate: 'Alegeți Data',
  selectDate: 'Selectați data',
  selectADate: 'Selectați o dată',
  markAsClean: 'Marcați ca curat',
  markAsReadyToInspect: 'Marcați ca fiind gata de inspectare',
  viewTasks: 'Vizualizați sarcini',
  eci: 'ECI',
  lco: 'LCO',
  roomLog: `Jurnalul camerei`,
  taskLog: `Jurnal de sarcini`,
  snacks: 'SNACKS',
  seeDetails: 'Vezi detalii',
  assignedTasks: 'Sarcini atribuite',
  delete: 'Șterge',
  deleteTask: 'Ștergeți sarcina',
  deleteTaskConfirmationMessage: 'Sigur doriți să ștergeți această sarcină?',
  deleteTaskRepeatsConfirmationMessage:
    'Aceasta este o sarcina care se repetă. Puteți șterge numai aceasta sau și sarcinile viitoare.',
  deleteOnlyThisTask: 'Ștergeți numai această sarcină',
  deleteRepeatingTask: 'Ștergeți sarcina care se repetă',
  yes: 'da',
  no: 'Nu',
  pleaseWaitAFewSeconds: 'Vă rugăm să așteptați câteva secunde',
  deleting: 'Se va șterge',
  lostAndFound: 'Pierdut si gasit',
  current: 'Actual',
  archive: 'Arhiva ',
  edit: 'Editați',
  markAsResolved: 'Marcați ca rezolvat',
  markAsUnresolved: 'Marcați ca nerezolvat',
  add: 'Adăuga',
  area: 'Zonă',
  addTask: 'Adăugați sarcină',
  seeTaskDetails: 'Vezi sarcina',
  savePicturesAndStartCleaning: 'Salvați și începeți curățenia',
  savePictures: 'Salvați fotografii',
  editTask: 'Editați sarcina',
  takePhoto: 'Fă o fotografie',
  addArea: 'Adăugați zonă',
  addAreaDescription: 'Adăugați o zonă a hotelului pentru această sarcină',
  selectProperty: 'Selectați proprietatea',
  selectRooms: 'Selectați camere',
  selectTeam: 'Alege echipa',
  selectDueDate: 'Selectați data',
  hskTeam: 'Echipa HSK',
  actionIsRequired: `Acțiunea este necesară. Ștergeți titlul și selectați o acțiune din listă.`,
  noActionSelected: `Nicio acțiune selectată`,
  changeSearchAction: `Încearcă să schimbi termenul de căutare`,
  noActionFound: `Nicio acțiune găsită`,
  selectActionHelp: `Nu găsiți o acțiune? Vă rugăm să contactați echipa de Operațiuni pentru a o solicita.`,
  writeToSelectAction: 'Scrieți pentru a selecta o acțiune',
  save: 'Salvați',
  repetition: 'Repetiție',
  noRepetition: 'Fără repetiție',
  writeTask: 'Scrie sarcina',
  repetitionTime: 'Timp de repetare',
  doesNotRepeat: 'Nu se repeta',
  day: 'Zi ',
  week: 'Săptămână',
  month: 'Lună',
  repeatOn: 'Repetați la',
  repeatsEvery: 'Se repetă fiecare',
  description: 'Descriere',
  title: 'Titlu',
  addTitle: 'Adaugă titlu',
  titleIsRequired: 'Titlul este obligatoriu.',
  titleMaxLength: 'Titlul trebuie să fie mai scurt de {{maxLength}} caractere.',
  teamIsRequired: 'Este necesară echipă',
  openTasks: 'Deschide sarcini',
  arrival: 'Sosire',
  departure: 'Plecare',
  checkIn: 'Check In',
  times: 'Timp',
  guests: 'Vizitatori',
  name: 'Nume',
  additionalInfo: 'Informații suplimentare',
  addLostAndFound: 'Adăugați pierdut și găsit',
  addDamageReport: 'Adăugați raportul de daune',
  unitIsRequired: 'Este necesară unitatea',
  addItem: 'Adaugare element',
  editItem: 'Editați elementul',
  startCleaning: 'Începeți curățarea',
  endCleaning: 'Încheiați curățarea',
  endCleaningMessage: 'Există 1 sarcină deschisă. Ești sigur că ești gata cu curățenia?',
  endCleaningMessage_plural: 'Sunt {{count}} sarcini deschise. Ești sigur că vrei să termini curățenia?',
  cancelAndViewTasks: 'Anulați și vizualizați sarcini',
  ignoreAndMarkAsReadyToInspect: 'Ignorați și marcați ca fiind gata de inspectare',
  ignoreAndMarkAsCleaned: 'Ignorați și marcați ca și curățat',
  damageReports: 'Rapoarte de daune',
  confirm: 'A confirma',
  selectRoomOrArea: 'Selectați camera/zona sau',
  createNewOne: 'creați unul nou',
  today: 'Azi',
  tomorrow: 'Mâine',
  noTasksMessage: 'Nu a fost adăugată nicio sarcină',
  cleaningStatus: 'Curațenie',
  logOut: 'Deconectați-vă',
  language: 'Limba',
  selectLanguage: 'Selecteaza limba',
  isStandardized: 'Este o sarcină standardizată?',
  selectStandardizedTask: 'Selectați sarcina standardizată',
  overdue: 'Depașit',
  arrivals: 'Sosiri',
  memberArrivals: 'Member arrivals',
  departures: 'Plecări',
  stayovers: 'Curațenie la mijlocul sejurului',
  occupancy: 'Ocuparea OTB',
  midstayCleaning: 'Curațenie la mijlocul sejurului',
  roomsToSell: 'Camere de vanzare',
  editImages: 'Editați imagini',
  toClean: 'a curața',
  toInspect: 'a inspecta',
  noRoomsForCleaningToday: 'Nu există camere pentru curățenie astăzi',
  noShow: 'Neprezentare',
  noShowReportConfirmationMessage: 'Vă rugăm să confirmați că numărul camerei {{number}} nu s-a prezentat.',
  noShowReportQuestion: 'A făcut oaspetele check-in ieri?',
  checkInReportConfirmationMessage:
    'Vă rugăm să confirmați că oaspetele a făcut check-in la numărul camerei {{number}}.',
  noResultsSearchMessage: 'Niciun rezultat nu corespunde criteriilor dvs. de căutare',
  cancel: 'Anulare',
  selectCleaner: 'Alegeți un produs de curățare',
  selectAll: 'Selectează tot',
  floor: 'Podea',
  notAssigned: 'Nealocat',
  assignXRooms: 'Alocați {{total}} camere',
  assignRooms: 'Alocați camere',
  approvedLCOs: 'LCO aprobate',
  cleaner: 'Curățător',
  roomsCleanForToday: 'Toate camerele sunt curate pentru azi!🎉🎉',
  viewDamageReports: 'Vizualizați rapoartele de daune',
  noTasks: 'Fără sarcini',
  noDamageReports: 'Fără rapoarte de daune',
  dailyCleaningReport: 'Raport zilnic de curățenie',
  stayover: 'Rămâne peste',
  roomMoveFrom: 'Mutarea camerei de la {{value}}',
  roomMoveTo: 'Camera mutată în {{value}}',
  unassignAll: 'Anulați atribuirea tuturor',
  unassignRoomsConfirmationMessage: 'Sigur doriți să anulați atribuirea de {{total}} camere?',
  unassignRoomsTitle: 'Anulați atribuirea camerelor',
  hideActions: 'Ascunde acțiuni',
  showActions: 'Afișează acțiuni',
  noRoomAssigned: 'Nicio cameră alocată',
  disableActionTitleModal: 'Resetați filtrul {{name}}',
  disableActionDescriptionModal:
    'Pentru a folosi filtrul {{currentFilter}}, va rugam sa resetati filtrul {{resetFilter}}.',
  action: 'Acțiune',
  actionsSelected: '{{total}} acțiuni selectate',
  actionRequired: 'Acțiune cerută',
  priority: 'Prioritate',
  lcoUntil: 'LCO Până la',
  created: 'Creată',
  startNow: 'Începe acum',
  scheduleTime: 'Programează timpul',
  viewInfo: 'Vedeți informații',
  reminder: 'Aducere aminte',
  reminderTaskNotificationText: 'Sarcina dvs. programată este programată astăzi',
  inProgress: 'În curs',
  scheduled: 'Programat',
  moveMidstayCleaning: 'Mutați Stayover Cleaning?',
  moveMidstayTitle: 'Mută ​​curățenia intermediară',
  moveMidstayDescription: 'Sigur doriți să mutați curățenia intermediară la {{date}}?',
  moveMidstayNotPossible:
    'Scuze! Curățenia la mijlocul sejurului pentru această cameră nu poate fi mutată într-o altă zi!',
  moveMidstayNotification: 'Curățenia a fost mutată cu succes la {{date}}.',
  pleaseContactOps: 'Vă rugăm să contactați echipa de operațiuni',
  midCleanMovedTo: 'Curățenia de ședere a fost mutată la: {{value}}',
  optional: 'opțional',
  hskDelay: 'întârziere HSK',
  hskDelays: 'HSK întârziată',
  hskDelayMessage: 'Actualizați ora de check-in pentru camera {{number}}',
  notStarted: 'Nu a început',
  resolved: 'Rezolvat',
  noDamageReportsToday: 'Niciun raport de daune pentru azi',
  yourTask: 'Sarcina ta',
  yourTaskGX: 'Sarcina dvs. de la GX',
  damageReportedByGX: 'Daune raportate de GX',
  stay: 'Datele de ședere',
  checkOut: 'Ora de check-out',
  noPermission: 'Nu aveți permisiune, vă rugăm să contactați suportul.',
  approvedCheckoutTime: 'Timpul de finalizare aprobate',
  requestedCheckoutTime: 'Timpul solicitat pentru check-out',
  checkinTime: 'Ora de check-in',
  commentForHousekeeping: 'Comentariu pentru serviciul de menaj',
  numberOfApprovedLCOs: 'Numărul de LCO-uri aprobate',
  notSet: 'Nesetat',
  vip: 'VIP',
  extraService: 'Extra service',
  reservationMissing: 'Reservation is most likely cancelled, switch to General tab instead',
  confirmFor: 'Confirmă Pentru {{date}}',
  confirmAnotherDay: 'Confirmă Pentru {{date}}',
  taskConfirmed: 'Ai confirmat cerința pentru {{date}}.',
  confirmYourTask: 'Confirmați sarcina dumneavoastră',
  confirmYourTaskGX: 'Confirmați sarcina dvs. de la GX',
  confirmHighPriorityTaskMessage:
    'Vrei cu adevărat să setezi data scadenței pentru astăzi? Este posibil ca sarcina să nu fie confirmată și finalizată astăzi.',
  setTomorrow: 'Setează pentru mâine',
  keepToday: 'Păstrează astăzi',
  createTask: 'Creează sarcină',
  confirmationNeeded: 'Confirmare necesară',
  pictureTooBlurry: `Imaginea este prea neclară. Vă rugăm să faceți o fotografie clară și să o încărcați.`,
  tryAgain: `Încearcă din nou`,
  addDescription: `Adăugați descrierea`,
  togglePushNotificationsLabel: `Notificări push pentru iOS`,
  roomRack: `Camere`,
  newSlot: `Slot nou`,
  searchByRoomNumber: `Număr cameră`,
  reservation: `Rezervare`,
  outOfOrder: `Out of Order`,
  outOfService: `Out of Service`,
  outOfInventory: `Out of Inventory`,
  slotType: `Tip slot`,
  selectType: `Selectează tipul`,
  reason: `Motiv`,
  selectReason: `Selectați motivul`,
  selectDateAndTime: `Date și oră`,
  startDate: `Dată de începere`,
  startTime: `Ora de începere`,
  endDate: `Data de încheiere`,
  endTime: `Ora de încheiere`,
  room: `Camere`,
  selectRoom: `Selectați cameră`,
  cleanAfterBlock: `Curățenie necesară după slot`,
  addSlot: `Adăugați slot`,
  updateSlot: `Actualizare slot`,
  errorStartDatePastSlotForm: `Data de începere nu poate fi în trecut`,
  errorEndDatePastSlotForm: `Data de încheiere nu poate fi anterior datei de început`,
  errorEndTimePastSlotForm: `Timpul de încheiere nu poate fi înainte de timpul de începere`,
  errorMinDurationSlotForm: `Durata minimă a slotului este de 30 de minute`,
  errorOverbookedSlotForm: `Slot-ul poate duce la suprarezervare în ziua (zilele) selectată(i). Creaţi-o doar dacă este cu adevărat necesar, altfel selectaţi un alt interval de timp.`,
  outOfOrderInfo: `OOO - Out of Order. Trebuie utilizat atunci când o cameră nu este funcțională și nu poate fi utilizată de un oaspete.`,
  outOfServiceInfo: `OOS - Out of Service. Permite vânzarea camerei. O cameră marcată ca Out of Service este complet funcțională și are doar defecte cosmetice minore.`,
  outOfInventoryInfo: `OOI - Out of Inventory. Utilizat de echipa Launch pentru planuri de întreținere pe termen lung, cum ar fi renovarea unui etaj întreg.`,
  maintenanceReason: `Întreținere`,
  cleaningIssueReason: `Problema de curățenie`,
  roomMoveBlockReason: `Mutarea camerei/hotelului`,
  bedBugsReason: `Păduchi de pat`,
  smokingReason: `Fumatul`,
  vipGuestReason: `Oaspete VIP`,
  pmpReason: `PMP`,
  rampUpSruReason: `Ramp-up: strategic`,
  rampUpRnrReason: `Ramp-up: camera nu este gata`,
  hoReason: `Optimizare HSK`,
  othersReason: `Motivul altul`,
  createdInApaleo: `Creat în Apaleo`,
  reasonNotApaleo: `Creat în Apaleo nu este un motiv valid. Vă rugăm să selectați un altul.`,
  quarantineReason: `Carantină`,
  slotLog: `Slot log`,
  deleteSlot: `Șterge slotul`,
  deleteSlotLabel: `Vă rugăm să furnizați motivul ștergerii`,
  errorDeleteSlot: `Nu poți șterge slotul.`,
  errorDeleteInfoSlot: `Un slot în desfășurare nu poate fi șters, în schimb poate fi scurtat.`,
  fieldRequired: `{{field}}: câmpul este obligatoriu`,
  from: `din `,
  until: `până la `,
  issueResolveDeleteReason: `Problema a fost rezolvată / slotul nu este necesar`,
  createdByMistakeDeleteReason: `Slot creat din greșeală`,
  guestRejectedMoveDeleteReason: `Oaspetele a refuzat mutarea în altă cameră`,
  guestAcceptedMoveDeleteReason: `Oaspetele a acceptat mutarea în altă cameră`,
  deleteReasonIsRequired: `Motivul pentru ștergere este obligatoriu`,
  editSlot: `Editați slotul`,
  earlyCheckinByHour: `ECI - curățat până la {{hour}}:00`,
  paidEcis: `ECI plătite la {{hour}}:00`,
  invalidTimeFormat: `Formatul {{field}} este invalid`,
  errorPastDateEdit: `Data de început nu poate fi schimbată dacă este în trecut.`,
  operationsData: `Date operaționale`,
  vacantRooms: `Camere vacante`,
  extraServices: `Servicii suplimentare`,
  memberSnacks: `Gustări pentru membri`,
  oooRooms: `Camere OOO`,
};
