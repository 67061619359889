import { useEffect, useMemo, useState } from 'react';
import { addDays, startOfWeek } from 'date-fns';
import { useTranslation } from 'react-i18next';
import type { Id } from 'react-calendar-timeline';
import usePropertiesState from '@context/propertiesContext';
import useAppContext from '@context/appContext';
import { useRoomRackQueryParams } from './useRoomRackQueryParams';

import { DateRangeNavigation, AddSlotButton, SearchByRoom, SlotFilter } from './Header';
import { NoResultFound } from './RoomRackTable/components/NoResultFound';
import { RoomRackTableSection } from './RoomRackTableSection';
import { RoomSlotSidebar } from './RoomSlotSidebar';
import Loader from '@molecules/Loader';
import { ErrorDialog } from './ErrorDialog';

import useGetRoomRackData from '@utils/hooks/useGetRoomRackData';
import { formatSlotsData, slotOptions } from './utils';

import { SlotDialogueRoomRackParamValues, SlotType, SlotValue } from './types';
import { DateRangeType, SlotItem } from './RoomRackTable/types';
import { RoomRackQueryParams } from '@typings/enums';
import { formatDate, DATE_ISO_STRING } from '@utils/dateUtils';
import './RoomRackStyle.css';

export const MOBILE_DEFAULT_DATE_RANGE_LENGTH = 6;
export const DESKTOP_DEFAULT_DATE_RANGE_LENGTH = 13;
export const APALEO_DEFAULT_DATE_RANGE_LENGTH = 19;

export const DEFAULT_INITIAL_DATE = startOfWeek(new Date(), { weekStartsOn: 1 });

export default function RoomRack() {
  const {
    updateParam,
    removeParams,
    updateAndRemoveParams,
    isSlotOpen,
    startDate,
    slotIdParam,
    roomIdParam,
    slotStatuses,
    queryErrors,
    setQueryErrors,
  } = useRoomRackQueryParams();

  const [isSlotDialogOpen, setIsSlotDialogOpen] = useState<boolean>(isSlotOpen);
  const [slotSelected, setSlotSelected] = useState<SlotItem | null>(null);

  const [dateRangeLength, setDateRangeLength] = useState<number>(DESKTOP_DEFAULT_DATE_RANGE_LENGTH);
  const [dateRange, setDateRange] = useState<DateRangeType>({
    start: startDate,
    end: addDays(startDate, DESKTOP_DEFAULT_DATE_RANGE_LENGTH),
  });

  const [filteredByRoomId, setFilteredByRoomId] = useState<string | undefined>(roomIdParam ?? undefined);
  const [filteredSlotByStatus, setFilteredSlotByStatus] = useState<SlotValue[]>(slotStatuses ?? []);

  const [errors, setErrors] = useState<{ [key in RoomRackQueryParams]?: string } | null>(null);

  const { t } = useTranslation();
  const { selectedProperty } = usePropertiesState();
  const { id: propertyId, externalId, timeZone } = selectedProperty;

  const { isFullScreen } = useAppContext();

  const { roomRack, isFetching } = useGetRoomRackData({
    propertyId,
    timeZone,
    fromDate: dateRange.start.toISOString(),
    toDate: dateRange.end.toISOString(),
  });

  useEffect(() => {
    if (slotIdParam && roomRack && slotData.length > 0 && !slotSelected) {
      const foundSlot = slotData.find((slot) => slot.id === slotIdParam);
      if (foundSlot) {
        setSlotSelected(foundSlot);
        setIsSlotDialogOpen(true);
      } else {
        setErrors((prevState) => ({ ...prevState, [RoomRackQueryParams.SLOT_ID]: t('Slot not found') }));
        removeParams([RoomRackQueryParams.SLOT_ID]);
      }
    }
  }, [slotIdParam, roomRack]);

  useEffect(() => {
    if (queryErrors) {
      setErrors((prevState) => ({ ...prevState, ...queryErrors }));
    }
  }, [queryErrors]);

  useEffect(() => {
    setDateRange({
      start: startDate,
      end: addDays(startDate, dateRangeLength),
    });
    updateParam(RoomRackQueryParams.START, formatDate(new Date(startDate), DATE_ISO_STRING));
  }, [dateRangeLength]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleMediaQueryChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setDateRangeLength(MOBILE_DEFAULT_DATE_RANGE_LENGTH);
      } else if (isFullScreen) {
        setDateRangeLength(APALEO_DEFAULT_DATE_RANGE_LENGTH);
      } else {
        setDateRangeLength(DESKTOP_DEFAULT_DATE_RANGE_LENGTH);
      }
    };

    handleMediaQueryChange(mediaQuery as unknown as MediaQueryListEvent);
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => mediaQuery.removeEventListener('change', handleMediaQueryChange);
  }, [isFullScreen]);

  const roomsData = useMemo(() => {
    if (!roomRack) return null;
    return roomRack.map(({ unitId, unitNumber }) => {
      return { id: unitId, title: `${unitNumber}` };
    });
  }, [roomRack]);

  const slotData = useMemo(() => (roomRack && formatSlotsData(roomRack)) ?? [], [roomRack]);

  const slotFilteredData = useMemo(() => {
    if (filteredSlotByStatus.length === 0) return slotData;
    return slotData.filter(({ status }) => filteredSlotByStatus.includes(status));
  }, [slotData, filteredSlotByStatus]);

  const roomsFilteredData = useMemo(() => {
    if (!roomsData) return null;
    const selectedRoom = filteredByRoomId && roomsData.find((room) => room.id === filteredByRoomId);
    if (selectedRoom) {
      return [{ id: selectedRoom.id, title: selectedRoom.title }];
    } else if (filteredSlotByStatus.length > 0) {
      return roomsData.filter((room) => {
        return slotFilteredData.some((slot) => slot.group === room.id);
      });
    } else {
      return roomsData;
    }
  }, [roomsData, filteredByRoomId, filteredSlotByStatus, slotFilteredData]);

  const handleNewSlot = () => {
    setSlotSelected(null);
    setIsSlotDialogOpen(true);
    updateAndRemoveParams({
      set: { [RoomRackQueryParams.SLOT_PANEL]: SlotDialogueRoomRackParamValues.OPEN },
      remove: [RoomRackQueryParams.SLOT_ID],
    });
  };

  const handleDateRangeChange = ({ start, end }: { start: Date; end: Date }) => {
    setDateRange({ start, end });
    updateAndRemoveParams({
      set: { [RoomRackQueryParams.START]: formatDate(new Date(start), DATE_ISO_STRING) },
      remove: [RoomRackQueryParams.SLOT_ID],
    });
  };

  const handleRoomSearch = (roomSearched: string) => {
    if (!roomSearched || roomSearched === '') {
      setFilteredByRoomId(undefined);
      removeParams([RoomRackQueryParams.ROOM_ID]);
    } else if (roomSearched === filteredByRoomId) {
      setFilteredByRoomId('');
      removeParams([RoomRackQueryParams.ROOM_ID]);
    } else {
      setFilteredByRoomId(roomSearched);
      updateParam(RoomRackQueryParams.ROOM_ID, roomSearched);
    }
  };

  const handleSlotFiltered = (newSelectedSlots: SlotValue[]) => {
    if (newSelectedSlots.length === 0) {
      removeParams([RoomRackQueryParams.SLOT_TYPE]);
    } else {
      updateParam(RoomRackQueryParams.SLOT_TYPE, newSelectedSlots.join(','));
    }
    setFilteredSlotByStatus(newSelectedSlots);
  };

  const handleSlotClick = (itemId: Id) => {
    const item = slotData.find((slot) => slot.id === itemId);

    if (!item) return;
    if (item.type === SlotType.RESERVATION) {
      setIsSlotDialogOpen(false);
      setSlotSelected(null);
      window.open(`https://app.apaleo.com/${externalId}/reservations/${item?.externalId}/actions`, '_blank');
    } else {
      setIsSlotDialogOpen(true);
      setSlotSelected(item);
      updateAndRemoveParams({
        set: { [RoomRackQueryParams.SLOT_ID]: `${item.id}` },
        remove: [RoomRackQueryParams.SLOT_PANEL],
      });
    }
  };
  const handleCloseErrorDialog = (paramId: RoomRackQueryParams) => {
    setErrors((prev) => {
      if (prev === null) return null;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [paramId]: _, ...rest } = prev;
      return rest;
    });

    setQueryErrors((prev) => {
      if (prev === null) return null;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [paramId]: _, ...rest } = prev;
      return rest;
    });
  };

  if (isFetching && !roomsFilteredData)
    return (
      <div className="flex justify-center items-center mt-20">
        <Loader />
      </div>
    );

  if (!roomsFilteredData || !slotFilteredData || !roomsData) return <div>Error data</div>;

  return (
    <div className="flex flex-col h-full">
      <div className="px-2 sm:px-0 flex flex-col sm:flex-row justify-between gap-y-2 sm:gap-4 mb-4 sm:mb-6 mt-2 font-sans text-th-brown text-sm leading-[18px] font-semibold">
        <div className="flex flex-wrap items-center gap-2">
          <AddSlotButton handleNewSlot={handleNewSlot} />
          <SearchByRoom filteredByRoomId={filteredByRoomId} roomsData={roomsData} handleRoomSearch={handleRoomSearch} />
          <SlotFilter
            placeholder={t('slotType')}
            options={slotOptions}
            selectedOptions={filteredSlotByStatus}
            onChange={handleSlotFiltered}
          />
          <div className="sm:hidden flex-shrink-0">
            <DateRangeNavigation
              dateRange={dateRange}
              handleDateRangeChange={handleDateRangeChange}
              dateRangeLength={dateRangeLength}
            />
          </div>
        </div>
        <div className="hidden sm:block flex-shrink-0">
          <DateRangeNavigation
            dateRange={dateRange}
            handleDateRangeChange={handleDateRangeChange}
            dateRangeLength={dateRangeLength}
          />
        </div>
      </div>

      <RoomRackTableSection
        roomsData={roomsFilteredData}
        slotData={slotFilteredData}
        dateRange={dateRange}
        onSlotClick={handleSlotClick}
        isFetching={isFetching}
      />

      {roomsFilteredData.length === 0 && <NoResultFound />}

      {isSlotDialogOpen && (
        <RoomSlotSidebar
          slotSelected={slotSelected}
          rooms={roomsData}
          dateRange={dateRange}
          onClose={() => {
            setIsSlotDialogOpen(false);
            setSlotSelected(null);
            removeParams([RoomRackQueryParams.SLOT_ID, RoomRackQueryParams.SLOT_PANEL]);
          }}
        />
      )}
      {errors &&
        Object.entries(errors).map(([paramError, errorMsg]) => (
          <ErrorDialog
            key={paramError}
            error={errorMsg}
            title="Url Error"
            buttonLabel={t('close')}
            isOpen
            onClose={() => handleCloseErrorDialog(paramError as RoomRackQueryParams)}
          />
        ))}
    </div>
  );
}
