import { MouseEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon, ChevronLeftIcon, ClockIcon, PlusCircleIcon } from '@heroicons/react/outline';
import { ServerStateKey, TaskType, UnitStatus } from '@typings/enums';
import unitStatusesInfo from '@constants/unitStatusesInfo';
import UnitTasks from '@organisms/UnitTasks';
import Button, { ButtonType } from '@atoms/Button';
import CreateTask from '@organisms/CreateTask';
import { PropertyType, TaskInfoType, UnitDetailsInfoType } from '@typings/types';
import LabelWithValue from '@molecules/LabelWithValue';
import icons from '@constants/icons';
import useEndCleaning from '@utils/hooks/useEndCleaning';
import useAuth from '@utils/hooks/useAuth';
import { DATE_AND_DAY_OF_WEEK, DATE_FORMAT_SHORT, formatDate, TIME_FORMAT } from '@utils/dateUtils';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import UnitAdditionalTasks from '@organisms/UnitAdditionalTasks';
import AddDamageReport from '@organisms/AddDamageReport';
import { openReservationInApaleo } from '@utils/unitUtils';
import CollapsibleSection from '@molecules/CollapsibleSection';
import { useGetUnitDetails } from '@api/api';
import routes from '@constants/routes';
import { groupTasksByType } from '@utils/taskUtils';
import { TaskDetailsDialog } from '@organisms/TaskDetails';
import useDeleteTask from '@utils/hooks/useDeleteTask';
import useSearchParam from '@utils/hooks/useSearchParam';

interface Props {
  unitId: string;
  selectedProperty: Pick<PropertyType, 'id' | 'externalId' | 'timeZone'>;
  expandTasks?: boolean;
  expandDamageReports?: boolean;
  openCreationModal?: boolean;
}

export default function UnitDetails({
  unitId,
  selectedProperty,
  expandTasks = false,
  expandDamageReports = false,
  openCreationModal = false,
}: Props) {
  const { id: propertyId, timeZone } = selectedProperty;
  const { isRoomChecker, isCleaner, isOperations } = useAuth();

  const { isCleanerOnlyRole } = useRoleBasedUI();
  const setTaskId = useSearchParam({ key: 'taskId' })[1];

  const { t } = useTranslation();

  const {
    error,
    data: unit,
    refetch,
  } = useGetUnitDetails({
    propertyId,
    unitId,
    timeZone,
  });

  const [editTask, setEditTask] = useState<TaskInfoType>();
  const [editAdditionalTask, setEditAdditionalTask] = useState<TaskInfoType>();
  const [taskDialogOpen, setTaskDialogOpen] = useState(openCreationModal);
  const [additionalTaskOpen, setAdditionalTaskOpen] = useState(false);

  const isCleanerOnly = isCleanerOnlyRole(propertyId);

  const isCleaning = !!unit?.currentCleaningId;

  const {
    isLoading: isEndCleaningLoading,
    renderEndCleaningConfirmationDialog,
    onClickEndCleaning,
  } = useEndCleaning({
    queriesToInvalidate: [ServerStateKey.DAILY_VIEW_UNITS, ServerStateKey.UNIT_DETAILS],
  });

  const navigate = useNavigate();

  const { setTaskForDeletion, renderDeleteConfirmationDialog } = useDeleteTask({});

  const tasksByType = unit ? groupTasksByType(unit.tasks) : {};
  const tasks = tasksByType[TaskType.STANDARD] ?? [];
  const damageReports = tasksByType[TaskType.DAMAGE] ?? [];

  const onBack = useCallback(() => {
    navigate({ pathname: routes.ROOT, search: `?propertyId=${selectedProperty.id}` });
  }, []);

  const onReservationLinkClick = useCallback(
    async (e: MouseEvent<HTMLDivElement>, reservationExternalId: string) => {
      e.stopPropagation();
      if (selectedProperty?.externalId) {
        openReservationInApaleo(selectedProperty.externalId, reservationExternalId);
      }
    },
    [selectedProperty],
  );

  const renderArrivalInfo = useCallback(
    (u: UnitDetailsInfoType) => {
      if (!u) return null;

      if (!u.reservation) {
        if (u.status === UnitStatus.READY_TO_CLEAN && u.hskDelayUntil) {
          return (
            <div className={'flex flex-col px-4 py-2 border-b'}>
              <span className={'text-sm flex flex-row items-center'}>
                <icons.hskDelay className={'w-5 h-5 inline mr-1'} />
                {/*// todo check if this is same as reservation hsk delay...*/}
                {t('hskDelay')}: {formatDate(u.hskDelayUntil, TIME_FORMAT)}
              </span>
            </div>
          );
        }
        return null;
      }

      const { reservation } = u;

      return (
        <CollapsibleSection
          defaultOpen={!expandTasks && !expandDamageReports}
          renderHeader={(open) => (
            <div className={'flex flex-row flex-1 items-center justify-between px-4 py-3'}>
              <div className={'flex flex-row items-center'}>
                <div className={'text-th-primary font-serif font-bold uppercase'}>{t('arrival')}</div>
                <div
                  className={'text-sm text-blue-500 underline ml-3'}
                  onClick={(e) => onReservationLinkClick(e, reservation.externalId)}
                >
                  {reservation.externalId}
                </div>
                {reservation.isMember && <icons.member className={'w-5 h-3'}></icons.member>}
              </div>
              <ChevronDownIcon className={`${open ? 'transform rotate-180' : ''} h-5 w-5`} />
            </div>
          )}
        >
          <div className={'flex flex-col px-4'}>
            <div className={'flex flex-col'}>
              <div className={'flex flex-row mb-2'}>
                <LabelWithValue
                  className={'flex-1'}
                  label={`${t('checkIn')}`}
                  value={
                    <div className={'flex flex-col'}>
                      <div className={'flex flex-row items-center'}>
                        <div className={'bg-th-accent p-1 rounded-full mr-1'}>
                          <ClockIcon className={'h-5 w-5 text-white'} />
                        </div>
                        {formatDate(reservation?.arrival, TIME_FORMAT)}
                      </div>
                      <div>
                        {u.roomMoveFrom && (
                          <span className={'text-red-500 text-sm'}>
                            <icons.roomMove className={'w-5 h-5 fill-current inline mr-1'} />
                            {t('roomMoveFrom', { value: u.roomMoveFrom })}
                          </span>
                        )}
                        {u.roomMoveTo && (
                          <span className={'text-red-500 text-sm'}>
                            <icons.roomMove className={'w-5 h-5 fill-current inline mr-1'} />
                            {t('roomMoveTo', { value: u.roomMoveTo })}
                          </span>
                        )}
                        {u.status === UnitStatus.READY_TO_CLEAN && reservation.hskDelayUntil && (
                          <span className={'text-sm flex flex-row items-center mt-1'}>
                            <icons.hskDelay className={'w-5 h-5 inline mr-1'} />
                            {t('hskDelay')}: {formatDate(reservation.hskDelayUntil, TIME_FORMAT)}
                          </span>
                        )}
                      </div>
                    </div>
                  }
                />
                <LabelWithValue
                  className={'flex-1'}
                  label={t('stay')}
                  value={`
                              ${formatDate(reservation.arrival, DATE_FORMAT_SHORT)} - 
                              ${formatDate(reservation.departure, DATE_FORMAT_SHORT)}
                              `}
                />
              </div>
              <div className={'flex flex-row mb-2'}>
                <LabelWithValue
                  className={'flex-1'}
                  label={t('guests')}
                  value={
                    <div className={'flex flex-1 flex-row items-center'}>
                      {reservation.adults ? (
                        <div className={'flex flex-row items-center mr-2'}>
                          <div className={'bg-gray-100 p-1 rounded-full mr-1'}>
                            <icons.adult className={'h-5 w-5 text-black'} />
                          </div>
                          <span>{reservation.adults}</span>
                        </div>
                      ) : null}
                      {reservation.children ? (
                        <div className={'flex flex-row items-center'}>
                          <div className={'bg-gray-100 p-1 rounded-full mr-1'}>
                            <icons.children className={'h-5 w-5 text-black'} />
                          </div>
                          <span>{reservation.children}</span>
                        </div>
                      ) : null}
                    </div>
                  }
                />
                <LabelWithValue className={'flex-1'} label={t('name')} value={reservation.guestName} />
              </div>
              {reservation.midCleanMovedTo && (
                <span className={'text-red-500 font-bold text-sm'}>
                  {t('midCleanMovedTo', {
                    value: formatDate(reservation.midCleanMovedTo, DATE_AND_DAY_OF_WEEK),
                  })}
                </span>
              )}
              {reservation.additionalInfo && (
                <div className={'flex flex-row'}>
                  <LabelWithValue label={t('additionalInfo')} value={reservation.additionalInfo} />
                </div>
              )}
            </div>
          </div>
        </CollapsibleSection>
      );
    },
    [onReservationLinkClick],
  );

  const renderDepartureInfo = useCallback(
    (u: UnitDetailsInfoType) => {
      if (!u?.previousReservation) return null;

      const reservation = u.previousReservation;

      return (
        <CollapsibleSection
          defaultOpen={!expandTasks && !expandDamageReports}
          renderHeader={(open) => (
            <div className={'flex flex-row flex-1 items-center justify-between px-4 py-3'}>
              <div className={'flex flex-row items-center'}>
                <div className={'text-th-primary font-serif font-bold uppercase'}>{t('departure')}</div>
                <div
                  className={'text-sm text-blue-500 underline ml-3'}
                  onClick={(e) => onReservationLinkClick(e, reservation.externalId)}
                >
                  {reservation.externalId}
                </div>
                {reservation.isMember && <icons.member className={'w-5 h-3'}></icons.member>}
              </div>
              <ChevronDownIcon className={`${open ? 'transform rotate-180' : ''} h-5 w-5`} />
            </div>
          )}
        >
          <div className={'flex flex-col px-4'}>
            <div className={'flex flex-col'}>
              <div className={'flex flex-row mb-2'}>
                <LabelWithValue
                  className={'flex-1'}
                  label={`${t('checkOut')}`}
                  value={
                    <div className={'flex flex-col'}>
                      <div className={'flex flex-row items-center'}>
                        <div className={'bg-th-accent p-1 rounded-full mr-1'}>
                          <ClockIcon className={'h-5 w-5 text-white'} />
                        </div>
                        {formatDate(reservation.departure, TIME_FORMAT)}
                      </div>
                      <div>
                        {u.roomMoveFrom && (
                          <span className={'text-red-500 text-sm'}>
                            <icons.roomMove className={'w-5 h-5 fill-current inline mr-1'} />
                            {t('roomMoveFrom', { value: u.roomMoveFrom })}
                          </span>
                        )}
                        {u.roomMoveTo && (
                          <span className={'text-red-500 text-sm'}>
                            <icons.roomMove className={'w-5 h-5 fill-current inline mr-1'} />
                            {t('roomMoveTo', { value: u.roomMoveTo })}
                          </span>
                        )}
                      </div>
                    </div>
                  }
                />
                <LabelWithValue
                  className={'flex-1'}
                  label={t('stay')}
                  value={`
                              ${formatDate(reservation.arrival, DATE_FORMAT_SHORT)} - 
                              ${formatDate(reservation.departure, DATE_FORMAT_SHORT)}
                              `}
                />
              </div>
              <div className={'flex flex-row mb-2'}>
                <LabelWithValue
                  className={'flex-1'}
                  label={t('guests')}
                  value={
                    <div className={'flex flex-1 flex-row items-center'}>
                      {reservation.adults ? (
                        <div className={'flex flex-row items-center mr-2'}>
                          <div className={'bg-gray-100 p-1 rounded-full mr-1'}>
                            <icons.adult className={'h-5 w-5 text-black'} />
                          </div>
                          <span>{reservation.adults}</span>
                        </div>
                      ) : null}
                      {reservation.children ? (
                        <div className={'flex flex-row items-center'}>
                          <div className={'bg-gray-100 p-1 rounded-full mr-1'}>
                            <icons.children className={'h-5 w-5 text-black'} />
                          </div>
                          <span>{reservation.children}</span>
                        </div>
                      ) : null}
                    </div>
                  }
                />
                <LabelWithValue className={'flex-1'} label={t('name')} value={reservation.guestName} />
              </div>
              {reservation.additionalInfo && (
                <div className={'flex flex-row'}>
                  <LabelWithValue label={t('additionalInfo')} value={reservation.additionalInfo} />
                </div>
              )}
            </div>
          </div>
        </CollapsibleSection>
      );
    },
    [onReservationLinkClick],
  );

  if (error) {
    return <div>{t('anErrorHasOccurred')}</div>;
  }

  if (!unit) {
    return null;
  }

  return (
    <div className={'flex flex-col flex-1 min-h-0'}>
      <div className={'flex px-3 pt-3 pb-6'}>
        <ChevronLeftIcon className={'w-5 h-5 cursor-pointer text-th-secondary'} onClick={onBack} />
      </div>
      {/* Don't add flex class here, somehow flex-1 alone expands element to the bottom.. Revisit this */}
      <div className={'flex-1 overflow-auto px-3 pb-5'}>
        <div className={'bg-white rounded-lg shadow-md'}>
          <div className={'bg-gray-100 justify-between flex flex-row py-3 px-4 rounded-t-lg'}>
            <div className={'font-serif font-bold text-xl text-th-brown'}>{unit.number}</div>
            <div
              className={cn(
                'uppercase text-sm py-1 px-2 rounded text-white font-bold',
                isCleaning ? 'bg-th-dark-blue' : 'bg-red-400',
              )}
            >
              {isCleaning ? t('cleaningStatus') : t(unitStatusesInfo[unit.status].label)}
            </div>
          </div>
          {isCleaning && ((isCleaner && unit.currentCleaningByMe) || isRoomChecker || isOperations) && (
            <div className={'px-3 pt-5 pb-5'}>
              <Button
                isLoading={isEndCleaningLoading}
                onClick={onClickEndCleaning(unit)}
                type={ButtonType.OUTLINED}
                className={'w-full'}
              >
                {isRoomChecker || isOperations ? t('markAsClean') : t('markAsReadyToInspect')}
              </Button>
            </div>
          )}
          {renderDepartureInfo(unit)}
          {renderArrivalInfo(unit)}
          <CollapsibleSection
            defaultOpen={expandTasks || isCleaning}
            renderHeader={(open) => (
              <div className={'flex flex-row flex-1 items-center justify-between px-4 py-3'}>
                <div className={'text-th-primary font-serif font-bold uppercase'}>{t('assignedTasks')}</div>
                <ChevronDownIcon className={`${open ? 'transform rotate-180' : ''} h-5 w-5`} />
              </div>
            )}
          >
            <UnitTasks
              tasks={tasks}
              onClick={(t) => setTaskId(t.id)}
              onEditTask={setEditTask}
              onDeleteTask={setTaskForDeletion}
            />
            <TaskDetailsDialog
              propertyId={propertyId}
              onClickEdit={setEditTask}
              isCleanerOnly={isCleanerOnly}
              onClickDelete={setTaskForDeletion}
              onTaskAction={() => {
                refetch();
              }}
            />
          </CollapsibleSection>
          {!isCleanerOnly && (
            <div className={'pl-3 pt-5 pb-5'}>
              <Button onClick={() => setTaskDialogOpen(true)} Icon={PlusCircleIcon} type={ButtonType.OUTLINED}>
                {t('addTask')}
              </Button>
              {(taskDialogOpen || editTask) && (
                <CreateTask
                  apaleoReservationId={unit.reservation?.externalId}
                  editTask={editTask}
                  selectedUnit={unit}
                  onClose={() => {
                    setTaskDialogOpen(false);
                    setEditTask(undefined);
                  }}
                />
              )}
            </div>
          )}
          <CollapsibleSection
            defaultOpen={expandDamageReports || isCleaning}
            renderHeader={(open) => (
              <div className={'flex flex-row flex-1 items-center justify-between px-4 py-3'}>
                <div className={'text-th-primary font-serif font-bold uppercase'}>{t('damageReports')}</div>
                <ChevronDownIcon className={`${open ? 'transform rotate-180' : ''} h-5 w-5`} />
              </div>
            )}
          >
            <UnitAdditionalTasks tasks={damageReports} onEditTask={setEditAdditionalTask} />
          </CollapsibleSection>
          <div className={'pl-3 pt-5 pb-5'}>
            {(additionalTaskOpen || editAdditionalTask) && (
              <AddDamageReport
                editTask={editAdditionalTask}
                onClose={() => {
                  setAdditionalTaskOpen(false);
                  setEditAdditionalTask(undefined);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {renderEndCleaningConfirmationDialog()}
      {renderDeleteConfirmationDialog()}
    </div>
  );
}
