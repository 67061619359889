import { SearchIcon } from '@heroicons/react/outline';

interface Props {
  value?: string;
  onChange: (value: string) => void;
}

export default function SearchBar({ value, onChange }: Props) {
  return (
    <div className={'rounded-md bg-white flex flex-row flex-shrink-0 items-center p-3 shadow-md'}>
      <SearchIcon className={'w-5 h-5 mr-2 text-gray-400'} />
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={'block w-full p-0 focus:outline-none sm:text-sm border-0 bg-transparent'}
      />
    </div>
  );
}
