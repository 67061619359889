import { useMemo } from 'react';
import { Listbox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { AdjustmentsIcon } from '@heroicons/react/outline';

import { reservationStatusSlot, slotColor } from '../utils';

import { SlotOption, SlotType, SlotValue } from '../types';

interface SlotFilterProps {
  placeholder: string;
  options: SlotOption[];
  selectedOptions: SlotValue[];
  onChange: (values: SlotValue[]) => void;
}

export const SlotFilter = ({ placeholder, options, selectedOptions, onChange }: SlotFilterProps) => {
  const handleOptionToggle = (option: SlotValue) => {
    const isSelected = selectedOptions.some((selectedOption) => selectedOption === option);

    if (isSelected) {
      onChange(selectedOptions.filter((item) => item !== option));
    } else {
      onChange([...selectedOptions, option]);
    }
  };

  return (
    <div>
      <Listbox value={selectedOptions} onChange={onChange} multiple>
        {({ open }) => (
          <div className="relative">
            <Listbox.Button className="border border-th-brown-100 rounded-[4px] px-2 py-[6px] flex justify-between items-center gap-x-1 w-full hover:bg-[#EDF0F3]">
              <div className="flex justify-start items-center gap-x-[2px]">
                <AdjustmentsIcon className="w-[14px] h-[14px]" />
                <span className="block truncate text-th-brown-300 text-sm leading-[18px]">{placeholder}</span>
                {selectedOptions.length > 0 && (
                  <div className="bg-black text-white rounded-full w-3 h-3 text-[7px] flex justify-center items-center">
                    {selectedOptions.length}
                  </div>
                )}
              </div>
              <ChevronDownIcon className={`w-4 h-w-4 transition-transform duration-300 ${open && 'rotate-180'}`} />
            </Listbox.Button>
            <Listbox.Options
              className="absolute z-[60] py-4 overflow-auto w-60 bg-white rounded-2xl 
            shadow-lg ring-black ring-opacity-5 focus:outline-none"
            >
              {options.map((option, index) => {
                const isLastItem = index === options.length - 1;
                const isSelected = selectedOptions.some((selectedOption) => selectedOption === option.value);

                const OptionIcon = useMemo(() => {
                  if (option.type === SlotType.RESERVATION) {
                    return reservationStatusSlot.find((status) => status.value === option.value)?.icon;
                  }
                  return null;
                }, [option]);

                return (
                  <Listbox.Option
                    key={option.value}
                    value={option.value}
                    className={`cursor-pointer px-4 rounded-[4px] hover:bg-[#EDF0F3] focus:bg-th-light-green 
                  text-th-brown font-medium text-base ${isSelected && 'bg-th-light-green'}`}
                  >
                    <div
                      className={`flex justify-between items-center py-4 ${
                        !isLastItem && 'border-b border-th-brown-50'
                      }`}
                    >
                      <div className="flex justify-start items-center w-full gap-x-2">
                        {OptionIcon ? (
                          <div
                            className="w-6 h-6 rounded-[4px] flex justify-center items-center"
                            style={{ ...slotColor[option.value] }}
                          >
                            <OptionIcon width={16} height={16} />
                          </div>
                        ) : (
                          <div style={{ ...slotColor[option.value] }} className={`w-6 h-6 rounded-[4px]`} />
                        )}
                        <span className={`block truncate capitalize`}>{option.label}</span>
                      </div>
                      <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => handleOptionToggle(option.value)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </div>
        )}
      </Listbox>
    </div>
  );
};
