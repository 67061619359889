import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/outline';

import Popover from '@molecules/Popover';
import ActionItem from '@molecules/ActionItem';
import { DeleteModal } from './DeleteModal';
import { SlotItem } from '@organisms/RoomRack/RoomRackTable/types';

import { slotLogsUrl } from '@constants/url';
import icons from '@constants/icons';

type SettingsSlotSidebarProps = {
  slotItem: SlotItem;
  selectedProperty: { id: string; timeZone: string };
  dateRange: { start: Date; end: Date };
};

export const SettingsSlotSidebar = ({ slotItem, selectedProperty, dateRange }: SettingsSlotSidebarProps) => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onClickDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const openSlotLog = ({ slotId }: { slotId: string }) => {
    window.open(`${slotLogsUrl}?Maintenance+Slot+ID=${slotId}`, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={'flex h-6'} onClick={(e) => e.stopPropagation()}>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        selectedProperty={selectedProperty}
        dateRange={dateRange}
        slotItem={slotItem}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <Popover
        target={<DotsHorizontalIcon className={'w-6 h-6 ml-2 cursor-pointer text-th-secondary'} />}
        contentFn={(close) => (
          <div className={'flex flex-col px-4 md:w-40 py-6 gap-y-4'}>
            {!!slotItem.id && (
              <ActionItem
                name={t('slotLog')}
                Icon={icons.taskLog}
                action={() => {
                  openSlotLog({ slotId: slotItem.id as string });
                  close?.();
                }}
              />
            )}
            <ActionItem
              name={t('delete')}
              Icon={TrashIcon}
              action={() => {
                onClickDelete();
                close?.();
              }}
              isDelete
              isLast
            />
          </div>
        )}
      />
    </div>
  );
};
