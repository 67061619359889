import React, { useCallback, useMemo } from 'react';
import { getFrequencyFromRRuleStr } from '@utils/repetitionUtils';
import ImagePreview from '@atoms/ImagePreview/ImagePreview';
import { DATE_FORMAT_SHORT, formatDate } from '@utils/dateUtils';
import { TaskInfoType } from '@typings/types';
import { useTranslation } from 'react-i18next';
import TaskActions from '@organisms/TaskActions';
import useRoleBasedUI from '@utils/hooks/useRoleBasedUI';
import { AutomatedTaskType, TaskPriority, TaskType } from '@typings/enums';
import IconWithText from '@molecules/IconWithText';
import icons from '@constants/icons';
import { OfficeBuildingIcon, CameraIcon } from '@heroicons/react/outline';
import { FlagIcon } from '@heroicons/react/solid';
import { isConfirmTaskAvailable, isTaskOverdue, numberToTaskPriority, taskConfirmationIcon } from '@utils/taskUtils';
import usePropertiesState from '@context/propertiesContext';
import useToggleCompleteTask from '@utils/hooks/useToggleCompleteTask';
import Spinner from '@atoms/Spinner';
import CheckMark from '@molecules/CheckMark';
import { useTaskTitleAndDescription } from '@utils/hooks';
import useTaskActionsState from '@context/taskActionsContext';

interface Props {
  task: TaskInfoType;
  canResolveTask?: boolean;
  onClick: (task: TaskInfoType) => void;
  onClickEdit: (task: TaskInfoType) => void;
  onClickDelete: (task: TaskInfoType) => void;
}

function TaskInfo({ task, canResolveTask = true, onClick, onClickEdit, onClickDelete }: Props) {
  const { t } = useTranslation();

  const { selectedProperty } = usePropertiesState();
  const { timeZone } = selectedProperty;

  const { isCleanerOnlyRole } = useRoleBasedUI();
  const { getTaskActionByAutomatedType } = useTaskActionsState();
  const isCleanerOnly = isCleanerOnlyRole(task.propertyId);

  const isOverdueTask = useMemo(() => isTaskOverdue(timeZone), [timeZone]);
  const isOverdue = isOverdueTask(task);
  const datePreview = formatDate(task.dueAt ?? task.createdAt, DATE_FORMAT_SHORT) + taskConfirmationIcon(task);

  const showCreator = task.type === TaskType.STANDARD || task.type === TaskType.DAMAGE;
  const isCompleted = !!task.completedAt;

  const toggleCompleteMutation = useToggleCompleteTask({});

  const { title } = useTaskTitleAndDescription(task);

  const priority = task.priority !== null ? numberToTaskPriority(task.priority) : null;

  const isNotConfirmed = isConfirmTaskAvailable(task) && !task.confirmedAt;

  const bgColor = isNotConfirmed ? 'bg-th-yellow-300' : isOverdue ? 'bg-red-100' : 'bg-white';

  const kitchenUseActionId = useMemo(() => {
    return getTaskActionByAutomatedType(AutomatedTaskType.KITCHEN_USE)?.id;
  }, [getTaskActionByAutomatedType]);

  const onClickToggleComplete = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!toggleCompleteMutation.isLoading) {
        toggleCompleteMutation.mutate({ task });
      }
    },
    [toggleCompleteMutation, task],
  );

  const isWipDamageReportTask = !task.completedAt && (task.workingTimeEntryId || task.isScheduled);

  const TaskIcon = () => {
    if (isNotConfirmed) {
      return <icons.taskUnconfirmed className={`w-5 h-5`} />;
    } else {
      if (toggleCompleteMutation.isLoading) {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Spinner className={'w-5 h-5 text-th-primary'} />
          </div>
        );
      } else if (task.actionId === kitchenUseActionId && !isCompleted) {
        return <CameraIcon className={`w-6 h-6`} />;
      } else {
        return (
          <CheckMark
            isChecked={isCompleted}
            className={isOverdue ? 'border-red-400' : ''}
            onClick={onClickToggleComplete}
            disabled={!canResolveTask}
          />
        );
      }
    }
  };

  return (
    <div className={`flex flex-row px-3 pt-2 w-full cursor-pointer ${bgColor}`} onClick={() => onClick(task)}>
      <div className={'flex flex-col w-8'}>
        <TaskIcon />
        <div className={'text-[8px] text-gray-400 font-bold pt-3 uppercase'}>
          {getFrequencyFromRRuleStr(task.recurrence)}
        </div>
      </div>
      <div className={'flex flex-col flex-1 min-w-0 pb-2 border-b'}>
        <div className="flex flex-row justify-between">
          <div
            className={`flex flex-row items-center truncate font-bold mb-2 ${
              task.completedAt ? 'text-gray-400 line-through' : 'text-th-secondary'
            }`}
          >
            {task.imageUrls.length > 0 && (
              <div className={'w-6 h-6 mr-2'}>
                <ImagePreview url={task.imageUrls[0]} />
              </div>
            )}
            {title}
          </div>

          <div>
            {!isCleanerOnly && (
              <div>
                <TaskActions task={task} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
              </div>
            )}
          </div>
        </div>
        <div className={'flex flex-1 flex-row items-center justify-between'}>
          <div className={'flex flex-1 flex-row items-center justify-between'}>
            <div className={'flex flex-row flex-1 items-center md:justify-start space-x-3 overflow-hidden flex-wrap'}>
              {task.unit && <IconWithText Icon={icons.door} text={task.unit.number} />}
              {task.area && <IconWithText Icon={OfficeBuildingIcon} text={task.area.name} />}
              <IconWithText Icon={icons.emptyCalendar} text={datePreview} />
              {showCreator && <IconWithText Icon={icons.user} text={task.createdByName} />}
              {priority === TaskPriority.HIGH && <FlagIcon className={'w-4 h-4 text-red-400'} />}
              {isWipDamageReportTask && (
                <div
                  className={
                    'flex-shrink-0 mt-2 md:mt-0 px-2 py-1 rounded bg-th-secondary text-white font-bold text-xs md:text-md'
                  }
                >
                  {task.workingTimeEntryId ? t('inProgress').toUpperCase() : t('scheduled').toUpperCase()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(TaskInfo);
