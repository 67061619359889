import env from '@constants/env';
import * as Sentry from '@sentry/react';

if (env.isProd) {
  Sentry.init({
    dsn: env.sentryDsn,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.5,
  });
}
