import React, { useEffect, useState } from 'react';
import { TaskType } from '@typings/enums';
import AddLostAndFoundItem from '@organisms/AddLostAndFoundItem';
import AddButton from '@atoms/AddButton';
import AddDamageReport from '@organisms/AddDamageReport';
import { TaskInfoType, ThinUnit } from '@typings/types';
import CreateTask from '@organisms/CreateTask';
import { useLocation } from 'react-router-dom';

interface Props {
  type: TaskType;
  editTask?: TaskInfoType;
  onClose?: () => void;
  isCleanerOnly?: boolean;
}

function FloatingAddButton(props: Props) {
  const { type, editTask, onClose, isCleanerOnly = false } = props;

  const location = useLocation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const [selectedUnit, setSelectedUnit] = useState<ThinUnit | undefined>(undefined);

  const extendedProps = {
    ...props,
    selectedUnit,
  };

  useEffect(() => {
    if (location.state?.openCreationModal) {
      delete location.state.openCreationModal;
      setDialogOpen(true);
    }
    const unit = location.state?.unit;
    if (unit) {
      delete location.state.unit;
      setSelectedUnit(unit);
    }
  }, [location, setDialogOpen, setSelectedUnit]);

  const getComponent = (type: TaskType) => {
    if (type === TaskType.LOST_AND_FOUND) {
      return AddLostAndFoundItem;
    }
    if (type === TaskType.DAMAGE) {
      return AddDamageReport;
    }
    return CreateTask;
  };

  const onCloseAction = () => {
    setDialogOpen(false);
    setSelectedUnit(undefined);
    onClose?.();
  };

  if (isCleanerOnly || !type) {
    return null;
  }

  const TaskComponent = getComponent(type);

  return (
    <>
      <AddButton onClick={() => setDialogOpen(true)} />
      {(dialogOpen || editTask) && <TaskComponent {...extendedProps} onClose={onCloseAction} />}
    </>
  );
}

export default FloatingAddButton;
