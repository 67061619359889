import { useMemo, useState } from 'react';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { isBefore, startOfDay } from 'date-fns';
import { AnimatePresence } from 'framer-motion';

import unitsApi from '@api/unitsApi';
import propertiesApi from '@api/propertiesApi';
import { TableCell } from './CellComponents/TableCell';
import { PopoverCell } from './CellComponents/PopoverCell';
import { LcoDropdown } from './CellComponents/LcoDropdown';
import { LcoTimeDropdown } from './CellComponents/LcoTimeDropdown';

import useApiCall from '@utils/hooks/useApiCall';
import dateUtils, { toDate } from '@utils/dateUtils';

import { ServerStateKey } from '@typings/enums';
import { WeeklyViewDateSummary, WeeklyViewData, AssignedUnitDetailed } from '@typings/types';

import icons from '@constants/icons';
import { PAID_ECIS_HOUR } from '../constants';
import { RowTitle } from './CellComponents/RowTitle';
import { ExpandableSectionsType } from './PropertyTable';
import { AnimateSectionExpand } from './CellComponents/AnimateSectionExpand';
import { popoverPlainRooms, popoverRedirectRoomRack, popoverRedirectRooms } from './CellComponents/PopoverContent';
import {
  countUnitGroupTotal,
  extractUniqueUnitGroups,
  getAllRowsCount,
  getCellMainStyle,
  getCellUnitGroupStyle,
  isTodayStyle,
} from '../utils';

interface TableBodyProps {
  propertiesData: WeeklyViewDateSummary[];
  propertyId: string;
  showArrivalAndDeparturePopovers: boolean;
  memberBenefitsEnabled: boolean;
  isOperations: boolean;
  paidEcisEnabled: boolean;
  queryClient: QueryClient;
  from: string;
  propertyIds: string[];
  expandedSections: ExpandableSectionsType;
  toggleSection: (sectionKey: keyof ExpandableSectionsType) => void;
}

export const TableBody = ({
  propertiesData,
  propertyId,
  isOperations,
  showArrivalAndDeparturePopovers,
  memberBenefitsEnabled,
  paidEcisEnabled,
  queryClient,
  from,
  propertyIds,
  expandedSections,
  toggleSection,
}: TableBodyProps) => {
  const { t } = useTranslation();
  const today = startOfDay(dateUtils.now());

  const [unitsRequest, setUnitsRequest] = useState<{
    propertyId: string;
    type: 'arrivals' | 'departures' | 'midstays' | 'hskDelays';
    date: Date;
  }>();

  const arrivalsOnDayApiCall = useApiCall<AssignedUnitDetailed[]>(unitsApi.arrivalsOnDay);
  const departuresOnDayApiCall = useApiCall<AssignedUnitDetailed[]>(unitsApi.departuresOnDay);
  const midstaysOnDayApiCall = useApiCall<AssignedUnitDetailed[]>(unitsApi.midstaysOnDay);
  const hskDelaysOnDayApiCall = useApiCall<AssignedUnitDetailed[]>(unitsApi.hskDelaysOnDay);
  const memberArrivalsOnDayApiCall = useApiCall<AssignedUnitDetailed[]>(unitsApi.arrivalsOnDay);
  const saveDaySettingsApiCall = useApiCall<WeeklyViewData>(propertiesApi.saveDaySettings);

  const { data: arrivalsOnDay } = useQuery(
    [ServerStateKey.ARRIVALS_ON_DAY, unitsRequest?.propertyId, unitsRequest?.date, unitsRequest?.type],
    () =>
      arrivalsOnDayApiCall({
        params: { propertyId: unitsRequest!.propertyId },
        query: { date: unitsRequest!.date, includeUnitDetails: true },
      }),
    { enabled: unitsRequest?.type === 'arrivals' },
  );

  const { data: memberArrivalsOnDay } = useQuery(
    [ServerStateKey.MEMBER_ARRIVALS_ON_DAY, unitsRequest?.propertyId, unitsRequest?.date, unitsRequest?.type],
    () =>
      memberArrivalsOnDayApiCall({
        params: { propertyId: unitsRequest!.propertyId },
        query: { date: unitsRequest!.date, membersOnly: true, includeUnitDetails: true },
      }),
    { enabled: unitsRequest?.type === 'arrivals' },
  );

  const { data: departuresOnDay } = useQuery(
    [ServerStateKey.DEPARTURES_ON_DAY, unitsRequest?.propertyId, unitsRequest?.date, unitsRequest?.type],
    () =>
      departuresOnDayApiCall({
        params: { propertyId: unitsRequest!.propertyId },
        query: { date: unitsRequest!.date, includeUnitDetails: true },
      }),
    { enabled: unitsRequest?.type === 'departures' },
  );

  const { data: midstaysOnDay } = useQuery(
    [ServerStateKey.MIDSTAYS_ON_DAY, unitsRequest?.propertyId, unitsRequest?.date, unitsRequest?.type],
    () =>
      midstaysOnDayApiCall({
        params: { propertyId: unitsRequest!.propertyId },
        query: { date: unitsRequest!.date, includeUnitDetails: true },
      }),
    { enabled: unitsRequest?.type === 'midstays' },
  );

  const { data: hskDelaysOnDay } = useQuery(
    [ServerStateKey.HSK_DELAYS_ON_DAY, unitsRequest?.propertyId, unitsRequest?.date, unitsRequest?.type],
    () =>
      hskDelaysOnDayApiCall({
        params: { propertyId: unitsRequest!.propertyId },
        query: { date: unitsRequest!.date, includeUnitDetails: true },
      }),
    { enabled: unitsRequest?.type === 'hskDelays' },
  );

  const saveDaySettingsMutation = useMutation(
    (v: { date: Date; approvedLateCheckouts: number; latestAllowedCheckout: string; propertyId: string }) =>
      saveDaySettingsApiCall({
        params: { propertyId: v.propertyId },
        body: {
          date: v.date,
          approvedLateCheckouts: v.approvedLateCheckouts,
          latestAllowedCheckout: v.latestAllowedCheckout,
        },
      }),
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData([ServerStateKey.WEEKLY_VIEW, propertyIds, from], (oldData?: WeeklyViewData) => {
          const propertyData = oldData?.[variables.propertyId];
          if (!propertyData) return oldData || {};
          const changedItem = oldData[variables.propertyId].data.find((d) => d.date === variables.date);
          if (!changedItem) return oldData || {};
          const indexOfChangedItem = propertyData.data.indexOf(changedItem);
          const newPropertyData = {
            ...propertyData,
            data: [
              ...propertyData.data.slice(0, indexOfChangedItem),
              {
                ...changedItem,
                approvedLcoLimit: variables.approvedLateCheckouts,
                maxLcoTime: variables.latestAllowedCheckout,
              },
              ...propertyData.data.slice(indexOfChangedItem + 1, propertyData.data.length),
            ],
          };
          return {
            ...oldData,
            [variables.propertyId]: newPropertyData,
          };
        });
      },
    },
  );

  const arrivalsUnitGroupsRows = useMemo(
    () => extractUniqueUnitGroups(propertiesData, 'arrivalsUnitGroups'),
    [propertiesData],
  );
  const departuresUnitGroupsRows = useMemo(
    () => extractUniqueUnitGroups(propertiesData, 'departuresUnitGroups'),
    [propertiesData],
  );
  const midstaysUnitGroupsRows = useMemo(
    () => extractUniqueUnitGroups(propertiesData, 'midstaysUnitGroups'),
    [propertiesData],
  );
  const allMainRowsCount = useMemo(
    () => getAllRowsCount(propertiesData, ['arrivals', 'departures', 'midstays', 'memberArrivals', 'paidEcis']),
    [propertiesData],
  );

  return (
    <div>
      <div className="flex flex-row gap-x-4">
        <RowTitle
          onClick={() => toggleSection('arrival')}
          isExpanded={expandedSections.arrival}
          isExpandable={arrivalsUnitGroupsRows.length > 0 ? true : false}
          count={`${allMainRowsCount.arrivals}`}
        >
          {t('arrivals')}
        </RowTitle>
        <div className="flex flex-row bg-white w-full">
          {propertiesData.map((dateData) => {
            const cellMainStyle = getCellMainStyle(dateData.date);
            const cellMainStyleNoClickable = getCellMainStyle(dateData.date, false);

            if (showArrivalAndDeparturePopovers) {
              const arrivalsCount = dateData.arrivals;
              return (
                <PopoverCell
                  key={`${dateData.date}-arrivals`}
                  count={arrivalsCount}
                  itemsLength={arrivalsOnDay?.length}
                  className={cellMainStyle}
                  onOpen={() =>
                    arrivalsCount > 0 &&
                    setUnitsRequest({
                      propertyId,
                      type: 'arrivals',
                      date: dateData.date,
                    })
                  }
                  content={popoverRedirectRooms(arrivalsOnDay, propertyId)}
                />
              );
            }
            return (
              <TableCell
                key={`${dateData.date}-arrivals`}
                value={dateData.arrivals}
                className={cellMainStyleNoClickable}
              />
            );
          })}
        </div>
      </div>
      <AnimatePresence>
        <AnimateSectionExpand isOpen={expandedSections.arrival}>
          {arrivalsUnitGroupsRows.map((unitGroup) => (
            <div key={unitGroup.id} className="flex flex-row gap-x-4">
              <RowTitle
                className={'h-[32px] md:h-[32px] pl-6 sm:pl-10 font-normal'}
                isExpandable={false}
                count={`${countUnitGroupTotal(propertiesData, 'arrivalsUnitGroups', unitGroup.id)}`}
              >
                {unitGroup.name}
              </RowTitle>
              <div className="flex flex-row bg-white w-full">
                {propertiesData.map((dateData) => {
                  const unitgroupCellStyle = getCellUnitGroupStyle(dateData.date);
                  const arrivalCount = dateData.arrivalsUnitGroups?.find((d) => d.id === unitGroup.id)?.value ?? 0;
                  return (
                    <TableCell
                      key={`${dateData.date}-arrivalsUnitGroups`}
                      value={arrivalCount}
                      className={unitgroupCellStyle}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </AnimateSectionExpand>
      </AnimatePresence>

      <div className="flex flex-row gap-x-4">
        <RowTitle
          onClick={() => toggleSection('departure')}
          isExpanded={expandedSections.departure}
          isExpandable={departuresUnitGroupsRows.length > 0 ? true : false}
          count={`${allMainRowsCount.departures}`}
        >
          {t('departures')}
        </RowTitle>
        <div className="flex flex-row bg-white w-full">
          {propertiesData.map((dateData) => {
            const cellMainStyle = getCellMainStyle(dateData.date);
            const cellMainStyleNoClickable = getCellMainStyle(dateData.date, false);

            const departuresCount = dateData.departures;

            if (showArrivalAndDeparturePopovers) {
              return (
                <PopoverCell
                  key={`${dateData.date}-departures`}
                  count={departuresCount}
                  itemsLength={departuresOnDay?.length}
                  className={cellMainStyle}
                  onOpen={() =>
                    departuresCount > 0 &&
                    setUnitsRequest({
                      propertyId,
                      type: 'departures',
                      date: dateData.date,
                    })
                  }
                  content={popoverRedirectRooms(departuresOnDay, propertyId)}
                />
              );
            }
            return (
              <TableCell
                key={`${dateData.date}-departures`}
                value={dateData.departures}
                className={cellMainStyleNoClickable}
              />
            );
          })}
        </div>
      </div>
      <AnimatePresence>
        <AnimateSectionExpand isOpen={expandedSections.departure}>
          {departuresUnitGroupsRows.map((unitGroup) => (
            <div key={unitGroup.id} className="flex flex-row gap-x-4">
              <RowTitle
                className={'h-[32px] md:h-[32px] pl-6 sm:pl-10 font-normal'}
                isExpandable={false}
                count={`${countUnitGroupTotal(propertiesData, 'departuresUnitGroups', unitGroup.id)}`}
              >
                {unitGroup.name}
              </RowTitle>
              <div className="flex flex-row bg-white w-full">
                {propertiesData.map((dateData) => {
                  const unitgroupCellStyle = getCellUnitGroupStyle(dateData.date);
                  const departuresCount = dateData.departuresUnitGroups?.find((d) => d.id === unitGroup.id)?.value ?? 0;
                  return (
                    <TableCell
                      key={`${dateData.date}-departuresUnitGroups`}
                      value={departuresCount}
                      className={unitgroupCellStyle}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </AnimateSectionExpand>
      </AnimatePresence>

      <div className="flex flex-row gap-x-4">
        <RowTitle
          onClick={() => toggleSection('midstays')}
          isExpanded={expandedSections.midstays}
          isExpandable={midstaysUnitGroupsRows.length > 0 ? true : false}
          count={`${allMainRowsCount.midstays}`}
        >
          {t('midstayCleaning')}
        </RowTitle>
        <div className="flex flex-row bg-white w-full">
          {propertiesData.map((dateData) => {
            const cellMainStyle = getCellMainStyle(dateData.date);
            const cellMainStyleNoClickable = getCellMainStyle(dateData.date, false);
            const midstaysCount = dateData.midstays;

            if (showArrivalAndDeparturePopovers) {
              return (
                <PopoverCell
                  key={`${dateData.date}-midstays`}
                  count={midstaysCount}
                  itemsLength={midstaysOnDay?.length}
                  className={cellMainStyle}
                  onOpen={() =>
                    midstaysCount > 0 &&
                    setUnitsRequest({
                      propertyId,
                      type: 'midstays',
                      date: dateData.date,
                    })
                  }
                  content={popoverRedirectRooms(midstaysOnDay, propertyId)}
                />
              );
            }
            return (
              <TableCell
                key={`${dateData.date}-midstays`}
                value={dateData.midstays}
                className={cellMainStyleNoClickable}
              />
            );
          })}
        </div>
      </div>
      <AnimatePresence>
        <AnimateSectionExpand isOpen={expandedSections.midstays}>
          {midstaysUnitGroupsRows.map((unitGroup) => (
            <div key={unitGroup.id} className="flex flex-row gap-x-4">
              <RowTitle
                className={'h-[32px] md:h-[32px] pl-6 sm:pl-10 font-normal'}
                isExpandable={false}
                count={`${countUnitGroupTotal(propertiesData, 'midstaysUnitGroups', unitGroup.id)}`}
              >
                {unitGroup.name}
              </RowTitle>
              <div className="flex flex-row bg-white w-full">
                {propertiesData.map((dateData) => {
                  const unitgroupCellStyle = getCellUnitGroupStyle(dateData.date);
                  const midstaysCount = dateData.midstaysUnitGroups?.find((d) => d.id === unitGroup.id)?.value ?? 0;
                  return (
                    <TableCell
                      key={`${dateData.date}-midstaysUnitGroups`}
                      value={midstaysCount}
                      className={unitgroupCellStyle}
                    />
                  );
                })}
              </div>
            </div>
          ))}
        </AnimateSectionExpand>
      </AnimatePresence>

      {memberBenefitsEnabled && (
        <div className="flex flex-row gap-x-4">
          <RowTitle onClick={() => toggleSection('tasks')} isExpanded={expandedSections.tasks} isExpandable={true}>
            {t('tasks')}
          </RowTitle>
          <div className="flex flex-row bg-th-gray-200 w-full">
            {propertiesData.map((dateData) => (
              <div key={`${dateData.date}-operation-data`} />
            ))}
          </div>
        </div>
      )}
      <AnimatePresence>
        {memberBenefitsEnabled && (
          <AnimateSectionExpand isOpen={expandedSections.tasks}>
            {
              <div className="flex flex-row gap-x-4">
                <RowTitle
                  isExpandable={false}
                  className={'pl-6 sm:pl-10 font-normal flex'}
                  count={`${allMainRowsCount.memberArrivals}`}
                >
                  <div className="flex justify-start items-center">
                    <icons.snacks className={'w-3 h-3 mr-1'} />
                    {t('memberSnacks')}
                  </div>
                </RowTitle>
                <div className="flex flex-row bg-white w-full">
                  {propertiesData.map((dateData) => {
                    const cellMainStyle = getCellMainStyle(dateData.date);
                    const cellMainStyleNoClickable = getCellMainStyle(dateData.date, false);
                    const memberArrivalsCount = dateData.memberArrivals;

                    if (showArrivalAndDeparturePopovers) {
                      return (
                        <PopoverCell
                          key={`${dateData.date}-member-arrivals`}
                          count={memberArrivalsCount}
                          itemsLength={memberArrivalsOnDay?.length}
                          className={cellMainStyle}
                          onOpen={() =>
                            memberArrivalsCount > 0 &&
                            setUnitsRequest({
                              propertyId,
                              type: 'arrivals',
                              date: dateData.date,
                            })
                          }
                          content={popoverRedirectRooms(memberArrivalsOnDay, propertyId)}
                        />
                      );
                    }
                    return (
                      <TableCell
                        key={`${dateData.date}-member-arrivals`}
                        value={dateData.memberArrivals}
                        className={cellMainStyleNoClickable}
                      />
                    );
                  })}
                </div>
              </div>
            }
          </AnimateSectionExpand>
        )}
      </AnimatePresence>
      <div className="flex flex-row gap-x-4">
        <RowTitle
          onClick={() => toggleSection('operationalData')}
          isExpanded={expandedSections.operationalData}
          isExpandable={true}
        >
          {t('operationsData')}
        </RowTitle>
        <div className="flex flex-row bg-th-gray-200 w-full">
          {propertiesData.map((dateData) => (
            <div key={`${dateData.date}-operation-data`} />
          ))}
        </div>
      </div>
      <AnimatePresence>
        <AnimateSectionExpand isOpen={expandedSections.operationalData}>
          <div className="flex flex-row gap-x-4">
            <RowTitle className={'pl-6 sm:pl-10 font-normal'} isExpandable={false}>
              {t('oooRooms')}
            </RowTitle>
            <div className="flex flex-row bg-white w-full">
              {propertiesData.map((dateData) => {
                const cellMainStyle = getCellMainStyle(dateData.date);

                return (
                  <PopoverCell
                    key={`${dateData.date}-outOfOrder`}
                    count={dateData.outOfOrderSlots?.length ?? 0}
                    itemsLength={dateData.outOfOrderSlots.length}
                    className={cellMainStyle}
                    content={popoverRedirectRoomRack(dateData.outOfOrderSlots, propertyId)}
                  />
                );
              })}
            </div>
          </div>

          <div className="flex flex-row gap-x-4">
            <RowTitle className={'pl-6 sm:pl-10 font-normal'} isExpandable={false}>
              {t('hskDelays')}
            </RowTitle>
            <div className="flex flex-row bg-white w-full">
              {propertiesData.map((dateData) => {
                const cellMainStyle = getCellMainStyle(dateData.date);
                const cellMainStyleNoClickable = getCellMainStyle(dateData.date, false);
                const hskDelaysCount = dateData.hskDelays;

                if (showArrivalAndDeparturePopovers) {
                  return (
                    <PopoverCell
                      key={`${dateData.date}-hskDelays`}
                      count={hskDelaysCount}
                      itemsLength={hskDelaysOnDay?.length ?? 0}
                      className={cellMainStyle}
                      onOpen={() =>
                        hskDelaysCount > 0 &&
                        setUnitsRequest({
                          propertyId,
                          type: 'hskDelays',
                          date: dateData.date,
                        })
                      }
                      content={popoverRedirectRooms(hskDelaysOnDay, propertyId)}
                    />
                  );
                }
                return (
                  <TableCell
                    key={`${dateData.date}-hskDelays`}
                    value={dateData.hskDelays}
                    className={cellMainStyleNoClickable}
                  />
                );
              })}
            </div>
          </div>

          <div className="flex flex-row gap-x-4">
            <RowTitle className={'pl-6 sm:pl-10 font-normal'} isExpandable={false}>
              {t('vacantRooms')}
            </RowTitle>
            <div className="flex flex-row bg-white w-full">
              {propertiesData.map((dateData) => {
                const cellMainStyleNoClickable = getCellMainStyle(dateData.date, false);
                return (
                  <TableCell
                    key={`${dateData.date}-vacant`}
                    value={dateData.vacant}
                    className={cellMainStyleNoClickable}
                  />
                );
              })}
            </div>
          </div>

          <div className="flex flex-row gap-x-4">
            <RowTitle className={'pl-6 sm:pl-10 font-normal'} isExpandable={false}>
              {t('occupancy')}
            </RowTitle>
            <div className="flex flex-row bg-white w-full">
              {propertiesData.map((dateData) => {
                const cellMainStyleNoClickable = getCellMainStyle(dateData.date, false);
                return (
                  <TableCell
                    key={`${dateData.date}-occupancy`}
                    value={`${dateData.occupancy}%`}
                    className={cellMainStyleNoClickable}
                  />
                );
              })}
            </div>
          </div>
        </AnimateSectionExpand>
      </AnimatePresence>
      <div className="flex flex-row gap-x-4">
        <RowTitle
          onClick={() => toggleSection('extraServices')}
          isExpanded={expandedSections.extraServices}
          isExpandable={true}
        >
          {t('extraServices')}
        </RowTitle>
        <div className="flex flex-row bg-th-gray-200 w-full">
          {propertiesData.map((dateData) => (
            <div key={`${dateData.date}-operation-data`} />
          ))}
        </div>
      </div>
      <AnimatePresence>
        <AnimateSectionExpand isOpen={expandedSections.extraServices}>
          <div className="flex flex-row gap-x-4">
            <RowTitle className={'pl-6 sm:pl-10 font-normal'} isExpandable={false}>
              {t('approvedLCOs')}
            </RowTitle>
            <div className="flex flex-row bg-white w-full">
              {propertiesData.map((dateData) => {
                const canEditDaySettings = isOperations && !isBefore(toDate(dateData.date), today);
                const dateStyle = isTodayStyle(dateData.date);
                return (
                  <div
                    key={`${dateData.date}-approvedLCOs`}
                    className={`flex justify-center items-center w-full border-b border-gray-100 px-2 ${dateStyle}`}
                  >
                    <div
                      className={`flex flex-row items-center justify-center space-x-0.5 py-1 w-full text-center text-th-secondary font-semibold text-sm md:text-base`}
                    >
                      <LcoDropdown
                        dateData={dateData}
                        propertyId={propertyId}
                        allowDaySettingsEdit={canEditDaySettings}
                        saveDaySettingsMutation={saveDaySettingsMutation}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex flex-row gap-x-4">
            <RowTitle className={'pl-6 sm:pl-10 font-normal'} isExpandable={false}>
              {t('lcoUntil')}
            </RowTitle>
            <div className="flex flex-row bg-white w-full">
              {propertiesData.map((dateData) => {
                const canEditDaySettings = isOperations && !isBefore(toDate(dateData.date), today);
                const dateStyle = isTodayStyle(dateData.date);
                return (
                  <div
                    key={`${dateData.date}-lcoUntil`}
                    className={`py-2 w-full ${paidEcisEnabled ? 'border-b border-gray-100' : ''} ${dateStyle}`}
                  >
                    <div
                      className={`flex flex-row items-center justify-center space-x-0.5 py-1 w-full text-center text-th-secondary font-semibold text-sm md:text-base`}
                    >
                      <LcoTimeDropdown
                        dateData={dateData}
                        propertyId={propertyId}
                        allowEdit={canEditDaySettings}
                        saveDaySettingsMutation={saveDaySettingsMutation}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {paidEcisEnabled && (
            <div className="flex flex-row gap-x-4">
              <RowTitle
                className={'pl-6 sm:pl-10 font-normal'}
                isExpandable={false}
                count={`${allMainRowsCount.paidEcis}`}
              >
                {t('paidEcis', { hour: PAID_ECIS_HOUR })}
              </RowTitle>
              <div className="flex flex-row bg-white w-full">
                {propertiesData.map((dateData) => {
                  const cellMainStyle = getCellMainStyle(dateData.date);
                  return (
                    <PopoverCell
                      className={cellMainStyle}
                      key={`${dateData.date}-lcoUntil`}
                      count={dateData.paidEcis.length}
                      itemsLength={dateData.paidEcis.length}
                      content={popoverPlainRooms(dateData.paidEcis.map((eci) => eci.unitNumber).filter(Boolean))}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </AnimateSectionExpand>
      </AnimatePresence>
    </div>
  );
};
