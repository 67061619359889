interface TableCellProps {
  value: number | string;
  className?: string;
}

export const TableCell = ({ className, value }: TableCellProps) => {
  return (
    <div
      className={`flex justify-center items-center h-full w-full border-b border-gray-100 text-th-dark-blue ${
        className ?? ''
      }`}
    >
      <div className={`w-full text-center font-semibold text-sm md:text-base`}>{value}</div>
    </div>
  );
};
