import { ChevronDownIcon } from '@heroicons/react/outline';
import { Popover } from '@headlessui/react';

export const RowTitle = ({
  isExpandable = true,
  isExpanded = false,
  onClick,
  className = '',
  count,
  children,
}: {
  isExpandable?: boolean;
  isExpanded?: boolean;
  className?: string;
  count?: string;
  onClick?: () => void;
  children: React.ReactNode;
}) => {
  return (
    <button
      onClick={onClick}
      className={`relative w-full min-w-[120px] max-w-[120px] md:min-w-[180px] lg:min-w-[240px] xl:min-w-[352px] md:max-w-[180px] lg:max-w-[240px] xl:max-w-[352px] 
        flex justify-between items-center h-[45px] md:h-[49px] px-[10px] border-b border-transparent text-black text-sm leading-[18px] font-semibold font-sans 
        ${className} cursor-pointer`}
    >
      <Popover className="w-full">
        <Popover.Button as="div" className="w-full text-left focus:outline-none" onClick={onClick}>
          <div className="flex items-center w-full">
            <div className="truncate">{children}</div>
            {count !== undefined && <span className="flex-shrink-0 ml-1">({count})</span>}
          </div>
        </Popover.Button>

        <Popover.Panel className="absolute z-10 w-auto min-w-[160px] max-w-xs transform -translate-y-full left-0 top-0">
          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="p-2 bg-white text-sm text-gray-900 whitespace-normal break-words">
              {children} {count && `(${count})`}
            </div>
          </div>
        </Popover.Panel>
      </Popover>

      {isExpandable && (
        <div className="ml-2">
          <div
            className={`w-4 h-4 sm:w-6 sm:h-6 transition-transform duration-300 ease-in-out origin-center ${
              isExpanded ? 'rotate-180' : 'rotate-0'
            }`}
          >
            <div className="flex-shrink-0">
              <ChevronDownIcon />
            </div>
          </div>
        </div>
      )}
    </button>
  );
};
