import Dialog from '@molecules/Dialog';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RadioButton from '@molecules/RadioButton';
import { useToggle } from '@utils/hooks/useToggle';
import cn from 'classnames';
import { Cleaner } from '@typings/types';
import { useGetCleaners } from '@api/api';
import Button, { ButtonType } from '@atoms/Button';

interface Props {
  propertyId: string;
  value?: string;
  totalUnits: number;
  onSelect: (item: Cleaner) => void;
  actionButton: (onClick: () => void) => React.ReactNode;
}

function SelectCleaners({ propertyId, value, totalUnits, onSelect, actionButton }: Props) {
  const { t } = useTranslation();
  const [dialogOpen, toggleDialogOpen] = useToggle(false);
  const { data = [], refetch } = useGetCleaners(propertyId);
  const [selectedCleanerId, setSelectedCleanerId] = useState<string | undefined>(value);

  useEffect(() => {
    if (dialogOpen) {
      refetch();
    }
  }, [dialogOpen]);

  const onClickSave = useCallback(() => {
    const item = data.find((d) => d.id === selectedCleanerId);
    if (item) {
      setSelectedCleanerId(undefined);
      onSelect(item);
      toggleDialogOpen();
    }
  }, [data, selectedCleanerId]);

  return (
    <>
      {actionButton(toggleDialogOpen)}
      <Dialog
        isOpen={dialogOpen}
        onClose={toggleDialogOpen}
        title={t('selectCleaner')}
        isMobileSheet
        actionButton={
          <Button disabled={!selectedCleanerId} type={ButtonType.UNSTYLED} onClick={onClickSave}>
            {t('save')}
          </Button>
        }
      >
        <div className={'md:w-96 flex flex-col max-h-[60vh] md:max-h-[70vh]'}>
          <div className={'pt-3 overflow-scroll'}>
            {data.map((item) => {
              const isChecked = selectedCleanerId === item.id;
              return (
                <div key={item.id} className={'flex flex-col'}>
                  <RadioButton
                    id={item.id}
                    name={'item'}
                    label={
                      <span>
                        <span className={cn(isChecked && 'font-semibold')}>{item.fullName}</span>{' '}
                        <span className={'pl-3 text-th-primary'}>{item.cleaningAssignments.length}</span>/{totalUnits}
                      </span>
                    }
                    onClick={() => {
                      if (isChecked) {
                        setSelectedCleanerId(undefined);
                      } else {
                        setSelectedCleanerId(item.id);
                      }
                    }}
                    checked={isChecked}
                    className={'pb-2 text-th-brown'}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default SelectCleaners;
