import useAppContext from '@context/appContext';

interface Props {
  navigation?: JSX.Element;
  content: JSX.Element;
}

export default function HomeLayout({ navigation, content }: Props) {
  const { isFullScreen } = useAppContext();

  return (
    <div className={`${!isFullScreen && 'max-w-4xl lg:max-w-[90vw]'} mx-auto flex flex-col flex-1 w-full`}>
      <div className={`flex flex-1 min-h-0 ${!isFullScreen && 'pb-20 md:pb-7'} relative`}>
        {content}
        {navigation}
      </div>
    </div>
  );
}
