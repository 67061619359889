import Notification from '@molecules/Notification';
import useNotifications from '@utils/hooks/useNotifications';
import { NotificationInfo } from '@context/NotificationContext';
import { useNavigate } from 'react-router-dom';
import { goToTaskDetails } from '@constants/routes';

export default function NotificationsRegion() {
  const { notifications, closeNotification } = useNotifications();
  const navigate = useNavigate();

  const goToTaskDetailsFn = goToTaskDetails(navigate);

  const onClick = (data: NotificationInfo) => {
    const taskId = data.metadata?.taskId;
    if (taskId) {
      goToTaskDetailsFn(taskId);
    }
  };

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-start px-4 py-2 pointer-events-none sm:p-3 sm:items-start"
      style={{ margin: 0 }}
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        {notifications.map((n, i) => (
          <Notification
            key={i}
            content={n?.content}
            type={n?.type}
            close={() => closeNotification(i)}
            onClick={() => onClick(n)}
          />
        ))}
      </div>
    </div>
  );
}
