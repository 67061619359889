import TextArea from '@molecules/TextArea';
import { ChangeEvent, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { FieldError } from 'react-hook-form';
import AutocompleteOptions from './AutocompleteOptions';
import { AutocompleteOption } from './types';

export interface AutocompleteProps<T> {
  value?: string;
  visible?: boolean;
  placeholder?: string;
  options?: AutocompleteOption<T>[];
  onChange: (value: T | string) => void;
  error?: FieldError | { message: string };
  forwardRef: Ref<HTMLTextAreaElement> | undefined;
}

function AutocompleteInput<T>(props: AutocompleteProps<T>) {
  const { value, options = [], forwardRef: outerRef, onChange, visible = true, ...inputProps } = props;

  const innerRef = useRef<HTMLTextAreaElement>(null);

  useImperativeHandle(outerRef, () => innerRef.current!, []);

  const [optionsVisible, setOptionsVisible] = useState(() => false);

  useEffect(() => {
    if (!optionsVisible) {
      setOptionsVisible(visible);
    }
  }, [visible]);

  const onSelectOption = useCallback(
    (option: AutocompleteOption<T>) => {
      onChange(option.value);
      setOptionsVisible(false);
      innerRef.current?.focus();
    },
    [onChange, setOptionsVisible, innerRef.current],
  );

  const onChangeText = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;
      onChange(newValue);
    },
    [onChange],
  );

  const onBlur = useCallback(() => {
    requestAnimationFrame(() => {
      if (optionsVisible) {
        setOptionsVisible(false);
      }
    });
  }, [optionsVisible]);

  return (
    <>
      <TextArea
        ref={innerRef}
        value={value}
        {...inputProps}
        onFocus={() => setOptionsVisible(visible)}
        onBlur={onBlur}
        onChange={onChangeText}
      />
      {optionsVisible && (
        <AutocompleteOptions visible={optionsVisible} value={value} data={options} onSelectOption={onSelectOption} />
      )}
    </>
  );
}

export default AutocompleteInput;
