import React from 'react';
import {
  DamageReportActionRequired,
  DamageReportArea,
  MaintenanceSlotStatusKey,
  ReservationStatusKey,
  RoomRackQueryParams,
  TaskType,
  Team,
  UnitStatus,
} from '@typings/enums';
import { Frequency, WeekdayStr as RRuleWeekday } from 'rrule';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export const ALL_WEEKDAYS: RRuleWeekday[] = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

type WithTimeZone<T> = T & {
  timeZone: string;
};

export interface PropertyType {
  id: string;
  name?: string;
  externalId: string;
  timeZone: string;
  memberBenefits: string[];
  kitchenImageAnalysis?: boolean;
  enablePaidEci?: boolean;
  enableGxTeam?: boolean;
}

export interface ThinUnit {
  id: string;
  number: string;
}

export interface ThinArea {
  id?: string;
  name: string;
}

export interface UnitsAndAreas {
  units: ThinUnit[];
  areas: Required<ThinArea>[];
}

export enum TaskSource {
  SHINE = 'SHINE',
  APALEO = 'APALEO',
}

export interface Task {
  id?: string;
  propertyId: string;
  title: string;
  description?: string;
  assignedTo?: Team;
  dueAt?: string;
  unitId?: string;
  areaId?: string;
  // apaleo reservation id
  reservationId?: string;
  recurrence?: string;
  type: TaskType;
  imageUrls: string[];
  isStandardized?: boolean;
  priority?: number;
  details?: {
    area: DamageReportArea;
    actionRequired: string;
  };
  source?: TaskSource;
  confirmedAt?: Date;
  confirmedBy?: string;
  actionId?: string;
  actionPath?: string;
}

export interface UnitInfoTypeDto {
  id: string;
  number: string;
  tasksCount: number;
  damageReportsCount: number;
  status: UnitStatus;
  arrival: string | null;
  departure: string | null;
  lateCheckOut?: boolean;
  earlyCheckIn?: boolean;
  isVip?: boolean;
  propertyId: string;
  reservationId?: string;
  currentCleaningId: string | null;
  currentCleaningByMe?: boolean;
  isMaintenance?: boolean;
  isNoShow?: boolean;
  noShowReported?: boolean;
  roomMoveFrom?: string;
  roomMoveTo?: string;
  hskDelayUntil: Date | null;
  isMember: boolean;
}

export type UnitInfoType = Override<
  UnitInfoTypeDto,
  {
    arrival: Date | null;
    departure: Date | null;
  }
> & {
  utcTime: WithTimeZone<Pick<UnitInfoTypeDto, 'arrival' | 'departure'>>;
};

export type FeatureToggle = {
  memberBenefits: boolean;
  memberBenefitsSnacks: boolean;
};

export interface UnitDetailsReservationDto {
  externalId: string;
  arrival: string;
  departure: string;
  guestName: string;
  additionalInfo: string | null;
  isVip: boolean;
  adults: number;
  children: number;
  infants: number;
  hskDelayUntil: string | null;
  propertyId: string;
  midCleanMovedTo?: string;
  isMember: boolean;
}

export type UnitDetailsReservation = Override<
  UnitDetailsReservationDto,
  {
    arrival: Date;
    departure: Date;
    hskDelayUntil: Date | null;
    midCleanMovedTo: Date | null;
  }
> & {
  utcTime: WithTimeZone<Pick<UnitDetailsReservationDto, 'arrival' | 'departure' | 'hskDelayUntil'>>;
};

export interface UnitDetailsInfoTypeDto {
  id: string;
  number: string;
  status: UnitStatus;
  propertyId: string;
  unitGroupName: string;
  property: { id: string; name: string; externalId: string };
  reservation?: UnitDetailsReservationDto;
  previousReservation?: UnitDetailsReservationDto;
  currentCleaningId?: string;
  currentCleaningByMe?: boolean;
  roomMoveTo?: string;
  roomMoveFrom?: string;
  tasks: TaskInfoTypeDto[];
  hskDelayUntil: Date | null;
}

export type UnitDetailsInfoType = Override<
  UnitDetailsInfoTypeDto,
  {
    reservation?: UnitDetailsReservation;
    previousReservation?: UnitDetailsReservation;
    tasks: TaskInfoType[];
  }
>;

export interface NavigationItemType {
  name: string;
  Icon: SVGIconType | HeroIcon;
  link: string;
  onClick?: () => void;
  active?: boolean;
  count?: number;
  items?: Omit<NavigationItemType, 'items' | 'count' | 'active'>[];
}

export interface TaskInfoTypeDto {
  id: string;
  dueAt: string | null;
  imageUrls: string[];
  title: string;
  actionId: string | null;
  actionPath: string | null;
  description: string | null;
  recurrence: string | null;
  createdAt: string;
  createdByGX: boolean;
  completedAt: Date | null;
  completedById: string | null;
  completedBy: {
    id: string | null;
    fullName: string | null;
  };
  confirmedAt: Date | null;
  confirmedBy: string | null;
  assignedTo: Team;
  type: TaskType;
  createdById: string;
  createdByName: string;
  isStandardized: boolean | null;
  propertyId: string;
  priority: number | null;
  isScheduled: boolean | null;
  workingTimeEntryId: string | null;
  property: {
    id: string;
    name: string;
    externalId: string;
    timeZone: string;
    memberBenefits: string[];
  };
  unit: {
    id: string;
    number: string;
    propertyId: string;
  } | null;
  area: {
    id: string;
    name: string;
    propertyId: string;
  } | null;
  details: {
    area: DamageReportArea;
    actionRequired: DamageReportActionRequired;
  } | null;

  reservation?: Pick<Reservation, 'id' | 'externalId'>;
}

export type TaskInfoType = Override<
  TaskInfoTypeDto,
  {
    dueAt: Date | null;
    createdAt: Date;
    completedAt: Date | null;
  }
> & {
  utcTime: WithTimeZone<Pick<TaskInfoType, 'dueAt' | 'createdAt' | 'completedAt'>>;
};

export type HeroIcon = (props: React.ComponentProps<'svg'>) => JSX.Element;

export type SVGIconType = React.FC<React.SVGProps<SVGSVGElement>>;

export type IconType = SVGIconType | HeroIcon;

export type RecurrencePattern = 'DAILY' | 'WEEKLY' | 'BIWEEKLY' | 'MONTHLY' | null;

export interface UnitDetailsType extends ThinUnit {
  status: UnitStatus;
  property: PropertyType;
}

export type WeekDay = RRuleWeekday;

export interface RepetitionOptions {
  frequency: Frequency;
  interval: number;
  weekDays: WeekDay[];
}

export enum ApplyOption {
  THIS_TASK = 'THIS_TASK',
  THIS_AND_FOLLOWING_TASKS = 'THIS_AND_FOLLOWING_TASKS',
  // ALL_TASKS='ALL_TASKS', // past ones too
}

export enum UserGlobalRole {
  OPERATIONS_ADMIN = 'operations-admin',
  MASTER_ICT = 'master-ict',
}

export enum UserRole {
  CLEANER = 'cleaner',
  ROOMCHECKER = 'roomchecker',
  OPERATIONS = 'operations',
  MAINTAINER = 'maintainer',
  GX = 'gx',
  ICT = 'ICT',
}

export interface UploadUrl {
  uploadUrl: string;
  uploadedUrl: string;
}

export interface WeeklyViewData {
  [propertyId: string]: WeeklyViewPropertyData;
}

export interface WeeklyViewPropertyData {
  name: string;
  data: WeeklyViewDateSummary[];
  settings: WeeklyViewSettings;
}

export type AssignedUnitDetailed = {
  unitId: string;
  unitNumber: string;
};

export type OutOfOrderSlot = {
  from: string;
  slotId: string;
  unitId: string;
  unitNumber: string;
};

export interface WeeklyViewDateSummaryUnitgroups {
  departuresUnitGroups: { id: string; name: string; value: number }[];
  arrivalsUnitGroups: { id: string; name: string; value: number }[];
  midstaysUnitGroups: { id: string; name: string; value: number }[];
}

export interface WeeklyViewDateSummary extends WeeklyViewDateSummaryUnitgroups {
  date: Date;
  arrivals: number;
  departures: number;
  memberArrivals: number;
  midstays: number;
  hskDelays: number;
  vacant: number;
  occupancy: number;
  approvedLco: Array<{
    unitNumber: string;
    reservationExternalId: string;
  }>;
  approvedLcoLimit: number | null;
  defaultApprovedLcoLimit: number | null;
  maxLcoTime: string;
  defaultMaxLcoTime: string;
  paidEcis: Array<{
    unitNumber: string;
    reservationExternalId: string;
  }>;
  outOfOrderSlots: Array<OutOfOrderSlot>;
}

export interface WeeklyViewSettings {
  defaultApprovedLcoLimit: number | null;
  defaultMaxLcoTime: string;
}

export interface AssignedRoom {
  id: string;
  number: string;
  floorNumber: string;
  status?: UnitStatus;
  cleaningAssignments: CleaningAssignmentData[];
}

export interface User {
  id: string;
  fullName: string;
}

export interface Cleaner extends User {
  cleaningAssignments: Pick<CleaningAssignment, 'id'>[];
}

export interface AssignedUnit {
  floorNumber: string;
  number: string;
}

export interface CleaningAssignment {
  id: string;
  user: User;
  unit: ThinUnit;
}

export interface AssignUnits {
  userId: string;
  unitIds: string[];
}

export interface CleaningAssignmentData {
  id: string;
  user: Pick<User, 'id' | 'fullName'>;
}

export interface AssignedUnitsByFloor {
  [key: string]: {
    [key: string]: AssignedRoom;
  };
}

export interface DailyCleaningReportType {
  departure: {
    number: string;
  }[];
  stayover: {
    number: string;
  }[];
}

export enum NotificationDtoType {
  DAMAGE_REPORT_DUE = 'DAMAGE_REPORT_DUE',
  DAMAGE_REPORT_REMINDER = 'DAMAGE_REPORT_REMINDER',
  TASK_DUE = 'TASK_DUE',
}

export interface NotificationDto {
  type: NotificationDtoType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  metadata: Record<string, any>;
}

export interface TaskNotification {
  type: NotificationDtoType;
  metadata: {
    task: TaskInfoType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
}

export interface OldMidClean {
  date?: string;
  type: MidCleanType;
  lastClean?: string;
  scheduledAt?: string;
  scheduledByUser?: boolean;
}

export interface MidClean extends Omit<OldMidClean, 'type'> {
  type: 'BIWEEKLY' | 'WEEKLY_OR_END' | 'DAILY';
  timeRange?: {
    from: string;
    to: string;
  };
}

export interface UpdateMidstayClean extends MidClean {
  propertyId: string;
  reservationId: string;
}

export enum MidCleanType {
  'Refresh' = 'refresh',
  'Clean' = 'clean',
  'Daily' = 'daily',
}

export interface DataWrapper<T> {
  data: T | undefined;
}

export interface NextMidCleanDto {
  reservationId: string;
  midClean: MidClean;
}

export interface NotificationSettingsType {
  disabledProperties: string[];
}

export interface ReservationDto {
  id: string;
  checkIn: string;
  requestedCheckOut: string;
  approvedCheckOut: string;
  checkedInAt: string | null;
  checkedOutAt: string | null;
  guestName: string;
  additionalInfo: string | null;
  isVip: boolean;
  adults: number;
  children: number;
  infants: number;
  propertyId: string;
  externalId: string;
  status: string;
  currentUnit: ThinUnit;
  // todo see where we use and if we get this...
  property: ReservationProperty;
  midClean?: MidClean;
}

export type Reservation = Override<
  ReservationDto,
  {
    checkIn: Date;
    requestedCheckOut: Date;
    approvedCheckOut: Date;
    checkedInAt: Date | null;
    checkedOutAt: Date | null;
    midClean?: Override<
      MidClean,
      {
        date?: Date;
        lastClean?: Date;
      }
    >;
  }
> & {
  utcTime: WithTimeZone<
    Pick<ReservationDto, 'checkIn' | 'requestedCheckOut' | 'approvedCheckOut' | 'checkedInAt' | 'checkedOutAt'>
  >;
};

export type ReservationProperty = PropertyType & {
  countryCode: string;
  city: string;
  cleaningInterval: CleaningInterval;
};

export enum CleaningInterval {
  BIWEEKLY = 'BIWEEKLY',
  WEEKLY_OR_END = 'WEEKLY_OR_END',
  EVERY_4_DAYS = 'EVERY_4_DAYS',
  DAILY = 'DAILY',
  NONE = 'NONE',
}

export interface DaySettings {
  id: string;
  propertyId: string;
  date: Date;
  approvedLateCheckouts: number | null;
  latestAllowedCheckout: string;
}

export interface UpdateReservationDto {
  checkInTime?: string;
  checkOutTime?: string;
  isVip?: boolean;
  additionalInfo?: string;
}

export type NotificationsDto = Array<{
  propertyId: string;
  propertyName: string;
  notifications: NotificationDto[];
}>;

export type Notifications = Array<{
  propertyId: string;
  propertyName: string;
  notifications: TaskNotification[];
}>;

export type RoomRackType = RoomRackItem[];

export type RoomRackItem = {
  unitId: string;
  unitNumber: string;
  maintenances: RoomRackMaintenanceSlot[];
  reservations: RoomRackReservation[];
};

export type RoomRackReservation = RoomRackReservationDto & {
  utcTime: WithTimeZone<Pick<RoomRackReservationDto, 'checkIn' | 'checkOut' | 'checkedInAt' | 'checkedOutAt'>>;
};

export type RoomRackMaintenanceSlot = RoomRackMaintenanceSlotDto & {
  utcTime: WithTimeZone<Pick<RoomRackMaintenanceSlotDto, 'from' | 'to'>>;
};

export type RoomRackDto = RoomRackItemDto[];

export type RoomRackItemDto = {
  unitId: string;
  unitNumber: string;
  maintenances: RoomRackMaintenanceSlotDto[];
  reservations: RoomRackReservationDto[];
};

export type ReservationStatus =
  | ReservationStatusKey.CONFIRMED
  | ReservationStatusKey.CHECKED_OUT
  | ReservationStatusKey.IN_HOUSE
  | ReservationStatusKey.NO_SHOW;

export type MaintenanceSlotStatus =
  | MaintenanceSlotStatusKey.OUT_OF_INVENTORY
  | MaintenanceSlotStatusKey.OUT_OF_ORDER
  | MaintenanceSlotStatusKey.OUT_OF_SERVICE;

export type RoomRackMaintenanceSlotDto = {
  id: string;
  externalId: string;
  unitId: string;
  from: Date;
  to: Date;
  type: MaintenanceSlotStatusKey;
  reason: string;
  description: string | null;
  endWithCleaning: boolean;
  createdBySystem: string;
  createdById: string | null;
  isDeleted: boolean;
  deletedAt: Date | null;
  deletedById: string | null;
  deletionReason: string | null;
  createdAt: Date;
};

export type RoomRackReservationDto = {
  id: string;
  checkIn: Date;
  checkOut: Date;
  checkedInAt: Date | null;
  checkedOutAt: Date | null;
  externalId: string;
  status: ReservationStatus;
  lateCheckOut: boolean;
};

export type UpdateSlotFormRequest = Partial<Omit<AddSlotFormRequest, 'type' | 'unitId' | 'system'>>;

export type AddSlotFormRequest = {
  type: string;
  reason: string;
  unitId: string;
  from: string;
  to: string;
  endWithCleaning: boolean;
  description?: string;
  system: 'system' | 'apaleo';
};

export type DeleteSlotFormRequest = {
  reason: string;
};

export type RoomRackQueryParamsValues = {
  [RoomRackQueryParams.SLOT_PANEL]: 'open' | 'closed';
  [RoomRackQueryParams.START]: string;
  [RoomRackQueryParams.ROOM_ID]: string;
  [RoomRackQueryParams.SLOT_TYPE]: string;
  [RoomRackQueryParams.SLOT_ID]: string;
};
