import { checkAvailabilityProps, useCheckAvailabilityOverbooking } from '@api/api';

function useCheckAvailabilityOverbookingSlot(props: checkAvailabilityProps) {
  const { data, ...queryResult } = useCheckAvailabilityOverbooking(props);

  return {
    ...queryResult,
    isSlotAvailable: data?.available ?? false,
  };
}

export default useCheckAvailabilityOverbookingSlot;
