import { useCallback } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

interface Props {
  minDate?: Date;
  maxDate?: Date;
  onChange: (start: Date | null, end: Date | null) => void;
  startDate?: Date | null;
  endDate?: Date | null;
}

function SelectDateRange(props: Props) {
  const { startDate, endDate, onChange } = props;

  const onChangeDate = useCallback(
    (dates: [Date | null, Date | null]) => {
      const [start, end] = dates;
      onChange(start, end);
    },
    [startDate, endDate],
  );

  return (
    <>
      <DatePicker
        autoComplete="off"
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={onChangeDate}
        disabledKeyboardNavigation
        inline
      />
    </>
  );
}

export default SelectDateRange;
